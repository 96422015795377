import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import RECAPTCHA from '../images/recaptcha.png';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Config from "../config/Config";
import Output from '../shared/editorjs-renderer';
import Toolbar from "@material-ui/core/Toolbar";
import {CircularProgress} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {useTheme} from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import {useCookies} from "react-cookie";
import {Alert, AlertTitle} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

const debug = Config.enableConsoleDebug;

const DisclaimerDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [cookies, setCookie] = useCookies();
    const [isWorking, setIsWorking] = React.useState(false);

    const [content, setContent] = React.useState(props.pubStore.getContent('DISCLAIMER_MAIN').data);
    const [recaptchaValue, setRecaptchaValue] = React.useState(undefined);
    const recaptchaRef = React.createRef();

    debug && console.log('DisclaimerDialog.props.sessionStore.isDisclaimed()',props.sessionStore.isDisclaimed());
    debug && console.log('DisclaimerDialog.props.pubStore.syncError: ' + props.pubStore.syncError + ' isWorking: ' + isWorking);
    return (
            <Dialog
                open={props.open}
                // open={false}
                onClose={props.handleClose}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                {/*<DialogTitle id="scroll-dialog-title">Review & Accept Disclaimer</DialogTitle>*/}
                <DialogTitle>
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <img src={Config.LOGO} style={{marginRight: '16px'}} />
                        </Grid>
                        <Grid item xs >
                            <Typography variant={'h3'}>
                                Review & Accept Disclaimer
                            </Typography>
                            <Typography variant={'body2'} color={'textSecondary'}>
                                {window.location.hostname} {Config.runMode} {Config.version}
                            </Typography>

                        </Grid>

                    </Grid>
                    {props.pubStore.alert.visible && (
                        <Alert severity={props.pubStore.alert.type} variant={'filled'} onClose={() => props.pubStore.alert.hide()}>
                            <AlertTitle>{props.pubStore.alert.title}</AlertTitle>
                            {props.pubStore.alert.message}
                        </Alert>
                    ) }

                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container direction={'column'} alignItems={'center'}>
                        {/*<Typography style={{backgroundColor: 'pink'}}>*/}
                        {/*    Pursuant to Tennessee Code Annotated Section 40-39-201, members of the public are not allowed to use information from the registry to inflict retribution or additional punishment to offenders. Harassment, stalking, or threats against offenders or members of their families are prohibited and doing so may violate both Tennessee criminal and civil laws. Though much of the information in the registry is of record, some of the information contained on the registry is obtained directly from offenders. The Tennessee Bureau of Investigation (TBI) does not guarantee its accuracy or completeness. The information contained in an offender's record does not imply listed individuals will commit a specific type of crime in the future, nor does it imply that if a future crime is committed by a listed individual what the nature of that crime may be. The TBI makes no representation as to any offender's likelihood of re-offending. If you believe that information concerning a specific offender is incorrect, please contact the Tennessee Bureau of Investigation, Sex Offender Registry at 1 (888) 837-4170.*/}
                        {/*</Typography>*/}
                        {props.pubStore &&
                        <Box style={{backgroundColor: Config.CONTENT_BACKGROUND, width: '95%', marginBottom: 12}}>
                            {!props.pubStore.loaded && !props.pubStore.syncError &&
                                (<CircularProgress/>)
                            }
                            {props.pubStore.syncError && (
                                <Typography variant={'body1'} color={'error'}>
                                    Error loading application content. Please refresh your browser to try again.
                                </Typography>
                            )}
                            {props.pubStore.loaded &&
                                (
                                    <Output data={props.pubStore.getContent('DISCLAIMER_MAIN').data} style={theme.output}/>
                                )}
                        </Box>
                        }

                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={Config.RECAPTCHA_SITE_KEY}
                            onChange={(value) => {
                                // console.log('Recaptcha value: ', value);
                                setRecaptchaValue(value);
                            }}
                            />
                    </Grid>

                                </DialogContent>
                <DialogActions>
                    {isWorking && <CircularProgress />}

                    {(isWorking || !recaptchaValue) &&
                        <Button disabled={true}>
                            Accept
                        </Button>
                    }
                    {(!isWorking && recaptchaValue) && <Button color="primary"
                             // disabled={isWorking || !recaptchaValue}
                             onClick={(event) => {
                                 if (event) {
                                     event.stopPropagation();
                                 }
                                 setIsWorking(true);
                                 props.pubStore.verify(recaptchaValue)
                                     .then(result => {
                                         console.log('recaptcha verified: ', result);
                                         props.sessionStore.setDisclaimed(true);
                                         props.sessionStore.save(setCookie);
                                         props.handleClose && props.handleClose(event);

                                     })
                                     .catch(error => {
                                         console.log('unable to verify recaptcha');
                                     })
                                     .finally(() => {
                                         setIsWorking(false);
                                     })
                             }}
                    >
                        Accept
                    </Button>
                    }
                </DialogActions>
            </Dialog>
    );
}

export default inject('sessionStore', 'pubStore')(observer(DisclaimerDialog));
