import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Config from '../config/Config';
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import TBI_TEST from '../images/TBI_TEST_60.png';
import TBI_PROD from '../images/TBI_PROD_60.png';
import Output from '../shared/editorjs-renderer';
import {inject, observer} from "mobx-react";
import {useCookies} from "react-cookie";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import {useTheme} from "@material-ui/core";
import {disclaimSTL} from "../shared/stores/SessionStore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const { detect } = require('detect-browser');
const browser = detect();

const Footer = (props) => {
    const [cookies, setCookie] = useCookies();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    let direction = sm ? 'row' : 'column';
    let size = sm ? 4 : 2;
    return (
        <Grid container direction={direction} spacing={4} style={{backgroundColor: '#eee', marginTop: 8}} >
            <Grid item container direction={'column'} sm={size} >
                <Typography variant={'h3'}>
                    Sex Offender Registry Contact
                </Typography>
                {props.pubStore.loaded &&
                    <Box style={{backgroundColor: Config.CONTENT_BACKGROUND}}>
                        <Output data={props.pubStore.getContent('TBI_CONTACT').data} style={theme.output}/>
                    </Box>
                }
            </Grid>

            <Grid item container direction={"column"} alignItems={"center"} sm={size}>
                <img src={Config.LOGO_LG} style={{width: 120, height: 120}} alt={"Tennessee State Seal"}/>
                <Typography variant={'subtitle1'} style={{textAlign: 'center', fontStyle: 'italic'}}>
                    "That Guilt Shall Not Escape {!md && 'Nor Innocence Suffer"'}
                </Typography>
                {md && <Typography variant={'subtitle1'} style={{textAlign: 'center', fontStyle: 'italic'}}>
                    Nor Innocence Suffer"
                </Typography>}
             </Grid>

            <Grid item container direction={'column'} sm={size}>
                    <Typography variant={'h3'}>
                        Tennessee Bureau of Investigation
                    </Typography>
                    {props.pubStore.loaded &&
                    <Box style={{backgroundColor: Config.CONTENT_BACKGROUND}}>
                        <Output data={props.pubStore.getContent('DIRECTOR').data} style={theme.output}/>
                    </Box>
                    }
            </Grid>
            <Grid>
                {props.pubStore.loaded &&
                <Box style={{backgroundColor: Config.CONTENT_BACKGROUND, paddingLeft: 16, paddingRight: 16}}>
                    <Output data={props.pubStore.getContent('GRANT_INFO').data} style={theme.output} />

                </Box>
                }

            </Grid>
            <Grid>

            </Grid>
            <Grid item container direction={"column"}>
                <Divider />
                <Typography variant="caption" color="textPrimary" align="center">
                    {Config.name + ' ' + Config.version}
                    {/*{` | loaded: ${' ' + props.pubStore.loaded} public(${props.pubStore.loaded && props.pubStore.allContents.PUBLIC.length || '-1'}) tcacode(${props.pubStore.loaded && props.pubStore.allContents.TCACODE.length || '-1'}) `}*/}
                    {/*{` | loaded: ${' ' + props.pubStore.loaded}  `}*/}
                    {/*{` | base: ${Config.PUBSOR_BASE}`}*/}

                </Typography>
                <Divider />
                {Config.enableJsonDebug && <pre style={{
                    textAlign: 'left',
                    backgroundColor: 'white',
                    width: '100%',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all'
                }}>
                    Cookies (Debug)
                    Disclaim expires in {disclaimSTL(props.sessionStore.disclaimExpiresAt)}s
                    {JSON.stringify(cookies, ' ', 2)}
                </pre>}

             </Grid>

        </Grid>

    );
}
export default inject('pubStore', 'sessionStore')(observer(Footer));
