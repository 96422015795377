import React from 'react';
import Config from "../../config/Config";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {inject, observer} from 'mobx-react';
import IconButton from "@material-ui/core/IconButton";
import EnhancedTable from "../components/EnhancedTable";
import GeoPinIcon from '@material-ui/icons/LocationOn';
import { useHistory } from 'react-router-dom';

import OffenderImageWrapper from '../components/OffenderImageWrapper';
import {CircularProgress} from "@material-ui/core";

const formats = require('../util/format-utils');

const debug = Config.enableConsoleDebug;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    // style={{backgroundColor: 'white', width: '100%', textAlign: 'left'}}
    code: {
        textAlign: 'left',
        backgroundColor: 'white',
        width: '100%',
        whiteSpace: 'pre-wrap',
        wordBreak: "break-all",
    },
    selected: {
        ".Mui-focusVisible": {backgroundColor: 'red'},
    }
}));

const MapPin = ({row: row, ...otherProps}) => {
    // console.log('MapPin row: ', row);
    // console.log('MapPin otherprops: ', otherProps);
    return (
        <IconButton variant={'contained'} color={'inherit'} aria-label={"Map Offender"}
                    onClick={(event) => {
                        debug && console.log('clicked ', row);
                        if (event) {
                            event.stopPropagation();
                        }
                        if(row.mapurl) {
                            window.location = row.mapurl;
                        }
                    }}
        >
            <GeoPinIcon />
        </IconButton>
    );
}


const SearchMatchesComponent = ({row: row, ...otherProps}) => {
    // console.log('SearchMatchesComponent row: ', row);
    if(!row || !row.offender || !row.offender.searchmatches) {
        return (<></>);
    }
    let first = true;
    return (
        <Typography variant={'caption'} display={'inline'} style={{paddingLeft: 40}}>
            {
                row.offender.searchmatches.map(m => {
                let parts = m.value.split('**');
                // console.log('parts.length: ', parts.length, parts);
                let comma = !first && '... ';
                first = false;
                if (parts.length == 3) {
                    return (
                        <Typography variant={'caption'} display={'inline'} >
                            {comma} {m.category}: {parts[0]}
                            <Typography variant={'caption'} display={'inline'}
                                        style={{fontWeight: 'bold', color: 'orangered', paddingInline: 2}}>
                                {parts[1]}
                            </Typography>
                            {parts.slice(2).join('')}
                        </Typography>
                    );
                } else if (parts.length >= 5) {
                    return (
                        <Typography variant={'caption'} display={'inline'}>
                            {comma} {m.category}: {parts[0]}
                            <Typography variant={'caption'} display={'inline'}
                                        style={{fontWeight: 'bold', paddingInline: 2}}>
                                {parts[1]}
                            </Typography>
                            {parts[2]}
                            <Typography variant={'caption'} display={'inline'}
                                        style={{fontWeight: 'bold', paddingInline: 2}}>
                                {parts[3]}
                            </Typography>
                            {parts.slice(4).join('')}
                        </Typography>
                    );
                } else {
                    return (<></>);
                }
            })
            }
        </Typography>
    );

}

const searchHeads = [
    // { id: 'id', numeric: true, disablePadding: true, sortable: true, label: 'ID'  },
    { id: 'photo', numeric: false, disablePadding: true, sortable: false, label: 'Photo', component: OffenderImageWrapper  },
    { id: 'offname', numeric: false, disablePadding: true, sortable: true, label: 'Name' },
    { id: 'soid', numeric: false, disablePadding: true, sortable: true, label: 'Offender ID' },
    { id: 'primaryaddress', numeric: false, disablePadding: true, sortable: false, label: 'Primary Address' },
    { id: 'secondaryaddress', numeric: false, disablePadding: true, sortable: false, label: 'Secondary Address' },
    { id: 'mapurl', numeric: false, disablePadding: true, sortable: false, label: 'Map', component: MapPin },
];

const QueryResults = (props) => {
    const classes = useStyles();
    // const [tableData, setTableData] = React.useState([]);
    const history = useHistory();
    let pubStore = props.pubStore;
    const onPageChange = (event, value) => {
        pubStore.search.setCurrentPage(value - 1);
    }

    debug && console.log('search results: ', pubStore.search.searchResults);
    let tableData = [];
    if(pubStore.search.searchResults && pubStore.search.searchResults.length > 0) {
        tableData = pubStore.search.searchResults.map(result => {
            let offender = result.offender;
            let row = {offender, soid: offender.soid, offname: offender.secta.offname, status: offender.secth.status,
                mapurl: `${Config.TNMAP_BASE}/offender/${offender.soid}`,
                primaryaddress: formats.formatAddress(offender.sectb.primary),
                secondaryaddress: formats.formatAddress(offender.sectb.secondary),

            };
            return row;
        });
        // setTableData(_tableData);
    }
    else {
        // setTableData([]);
    }
    // console.log('tableData: ', JSON.stringify(tableData, null, 4 ));

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline/>
            <Grid container direction={'column'} spacing={3} style={{marginTop: 24, width: '100%'}}>
                {(pubStore.search.hasSearched || pubStore.search.searchResults.length > 0) && (

                <Grid item  >
                    <EnhancedTable pubStore={pubStore} history={history} title={
                        ( <Grid container direction={'row'} justify={'space-between'} alignItems={'center'} style={{marginBottom: 8}}>
                                <Grid container item direction={'column'} xs={8}>
                                    <Typography variant={'h2'}>
                                    Search results
                                </Typography>
                                    <Typography variant={'caption'}>
                                        {pubStore.search.searchCriteria}
                                    </Typography>
                                    </Grid>
                                <Grid container item xs={4} justify={'flex-end'}>
                                    <Typography variant={'body1'} align={'right'}>
                                        Found {pubStore.search.searchHits} offender{pubStore.search.searchHits > 1 && 's'} in {pubStore.search.searchDuration}ms
                                    </Typography>

                                </Grid>
                            </Grid>
                        )

                    } head={searchHeads} data={tableData}
                                   spanrow={SearchMatchesComponent}
                                   rowCount={pubStore.search.searchHits}
                                   rowsPerPage={pubStore.search.resultsPerPage}
                                   page={pubStore.search.currentPage}
                                   onRowClick={(event, row) => {
                                       let offender = row.offender;
                                       pubStore.setDetailsOffender(offender);
                                       if (event) {
                                           event.stopPropagation();
                                       }
                                       if (history) {
                                           let path = `/details/${offender.soid}`;
                                           // history.push(path);
                                           // https://reactrouter.com/web/api/history
                                           history.push(path);
                                       }
                                   }
                                   }
                                   onChange={({page, rowsPerPage, orderBy, orderDirection}) => {
                                       if(rowsPerPage && rowsPerPage !== pubStore.search.resultsPerPage) {
                                           // pubStore.search.setResultsPerPage(rowsPerPage);
                                           pubStore.search.setCurrentPage(0, rowsPerPage);
                                       }
                                       else {
                                           pubStore.search.setCurrentPage(page );
                                       }
                                       if((orderBy && orderBy !== pubStore.search.orderBy) || (orderDirection && orderDirection !== pubStore.search.orderDirection)) {
                                           pubStore.search.setOrderBy(orderBy, orderDirection);
                                           pubStore.search.setCurrentPage(0);
                                       }
                                       pubStore.search.search()
                                           .then(() => {
                                               if(pubStore.search.searchResults && pubStore.search.searchResults.length > 0) {
                                                   tableData = pubStore.search.searchResults.map(result => {
                                                       let offender = result.offender;
                                                       let row = {offender, soid: offender.soid, offname: offender.secta.offname, status: offender.secth.status,
                                                           mapurl: `${Config.TNMAP_BASE}/offender/${offender.soid}`,
                                                           primaryaddress: formats.formatAddress(offender.sectb.primary),
                                                           secondaryaddress: formats.formatAddress(offender.sectb.secondary),

                                                       };
                                                       return row;
                                                   });
                                                   // setTableData(_tableData);
                                               }
                                           });
                                   }
                                   }
                    />
                    {pubStore.api.isWorking && (
                        <Grid container direction={'row'} justifyContent={'flex-end'} style={{width: '100%'}}>
                            <CircularProgress size={24} />
                            <Typography variant={'body2'} color={'textSecondary'} style={{marginLeft: 16}}>refreshing data...</Typography>
                        </Grid>
                    )}

                </Grid>
                )}
            </Grid>

        </Container>
    );
}
export default inject('pubStore')(observer(QueryResults));
