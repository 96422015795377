import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";

import Config from '../../config/Config';
import Grid from "@material-ui/core/Grid";
import GridListTile from "@material-ui/core/GridListTile";

// How to make Typography material-ui without <br>
// https://stackoverflow.com/questions/50211983/how-to-make-typography-material-ui-without-br
// Control number of lines of text (periods of ellipsis)
// https://reactjsexample.com/cross-browser-multiline-text-ellipsis-for-react/
import Dotdotdot from 'react-dotdotdot'
import OffenderImage from "./OffenderImage";

import {
    AliasComponent,
} from "../details/OffenderComponents";

const debug = false;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,

    },
    media: {
        height: 160,
        // paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    expand: {
        // transform: 'rotate(0deg)',
        marginLeft: 'auto',
        // transition: theme.transitions.create('transform', {
        //     duration: theme.transitions.duration.shortest,
        // }),
    },
    avatar: {
        backgroundColor: 'primary', //red[500],
    },
    grow: {
        '&:hover': {
            transform: 'scale(1.05)',
            transitionDuration: '0.2s',
        }
    },

}));

export default function MostWantedTile(props) {
    const classes = useStyles();

    let offender = props.item.data.offender;

    let offenses = '';

    if(!offender) {
        return (
            <Typography variant={'h6'} align={'center'}
                        style={{color: Config.TN_BLACK, marginBottom: 4}} noWrap>
                Loading offender...
            </Typography>

        )
    }
    offender.sectf && offender.sectf.offenses && offender.sectf.offenses.map(offense => {
        // console.log('listing offenses soid: ' + props.item.offender.soid);
        let o = offense.offense.substr(10).trim();
        if(offenses > '') {
            offenses += '; ';
        }
        offenses += o;
    });

    return (
        <GridListTile key={offender.soid}
                      style={{backgroundColor: Config.CARD_BACKGROUND,
                          margin: 8, height: 380, width: 240, maxWidth: 240,
                          borderRadius: 6,
                          border: `2px solid rgba(68, 92, 124, 0.35)`
                      }}
                      onClick={props.onClick}
            >
            <Grid container direction={'column'} justify={'center'} alignItems={'center'} alignContent={'center'} >
                <Typography style={{color: offender.isoac ? '#ff0000': 'transparent'}}>
                    {offender.isoac ? 'OFFENDER AGAINST CHILDREN' : '.'}
                </Typography>
                {/*<img className={classes.grow} src={sorImage} alt={props.item.offender.secta.offname} align={'center'}*/}
                {/*     style={{width: 120, height: 160, objectFit: 'cover', marginTop: '4px', border: isOffenderAgainstChildren ? '4px solid red' : '0px',*/}
                {/*         // borderBottom: `5px ${Config.TN_ORANGE} solid`,*/}
                {/*         boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',*/}
                {/*     }} />*/}
                <OffenderImage offender={offender} largeImage />
            </Grid>
            <Typography variant={'body1'} align={'center'}
                        style={{color: Config.TN_BLACK, marginBottom: 4}} noWrap>
                {offender.secta.offname}
            </Typography>
            <Paper style={{marginLeft: 4, marginRight: 4, backgroundColor: 'rgba(237,153,36, 0.25)', width: 240}} >
            <Typography variant={'subtitle2'} style={{color: Config.TN_BLACK, marginLeft: 4}}>
                LAST KNOWN WHEREABOUTS:
            </Typography>
            <Typography variant={'body2'} style={{color: Config.TN_BLACK, marginLeft: 14}}>
                {props.item.note > '' ? props.item.note : 'UNKNOWN'}
            </Typography>
            {AliasComponent(offender, true)}
                <Typography variant={'body2'} style={{color: Config.TN_BLACK, marginLeft: 4}}>
                    OFFENSES:
                </Typography>
                <Dotdotdot clamp={2} >
                    <p style={{color: Config.TN_BLACK, marginLeft: 14, marginTop: 0}}>
                        {offenses}
                    </p>
                </Dotdotdot>
            </Paper>
            {/*<GridListTileBar*/}
            {/*    style={{width: 234}}*/}
            {/*    // title={offender.name + "," + offender.lastKnown}*/}
            {/*    title={*/}
            {/*        <>*/}
            {/*            <Typography>{props.item.offender.secta.offname}</Typography>*/}
            {/*            <Typography variant={"caption"}>{props.item.note}</Typography>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*>*/}

            {/*</GridListTileBar>*/}
        </GridListTile>
    )

}
