import {types} from 'mobx-state-tree';

import {Api} from "./Api";
import Config from "../../config/Config";
// import axios from "axios";
import moment from "moment";
import {Alert} from "./Alert";

const delay = require('./Api').delay;

const debug = Config.enableConsoleDebug;

const PUBSOR_ACCESSTOKEN = 'PUBSOR_ACCESSTOKEN';
const PUBSOR_ACCESSEXPIRESAT = 'PUBSOR_ACCESSEXPIRESAT';
const PUBSOR_DISCLAIMED = 'PUBSOR_DISCLAIMED';
const PUBSOR_DISCLAIMEXPIRESAT = 'PUBSOR_DISCLAIMEXPIRESAT';

const enableLocalStorage = true;

// assumes cookie has been refreshed
// VALID if seconds > 0
// seconds may be negative for expired cert
export const disclaimSTL = (expiresAtDate) => {
    // console.log('expiresAtDate date? ' + expiresAtDate instanceof Date);
    if (!expiresAtDate) {
        return 0;
    }
    if (expiresAtDate instanceof Date) {
        let now = new Date().getTime();
        let expiresAt = expiresAtDate.getTime();
        let seconds = (expiresAt - now)/1000;
        return seconds;
    }
    return 0;
}

export const SessionStore = types
    .model('SessionStore', {
        id: 0,
        disclaimed: false,
        disclaimExpiresAt: types.optional(types.Date, new Date(1)),
        checkingToken: false,
        refreshToken: '',
        refreshExpiresAt: types.optional(types.Date, new Date(1)),

        expandedSearch: true,
        expandedEmail: true,

        pending: false,

        api: types.optional(Api, {}),
        //
        alert: types.optional(Alert, {}),

        // enableDev: false,

    })
    .views( self=> ({
    }))
    .actions( self => ({
        afterCreate() {
            self.alert.setDuration(0);
        },
        setDisclaimed(_disclaimed, _disclaimExpiresAt) {
            debug && console.log('setDisclaimed args: ', _disclaimed, _disclaimExpiresAt);
            // self.disclaimed = _disclaimed;
            if(!_disclaimExpiresAt) {
                self.disclaimExpiresAt = moment(Date.now()).add(Config.DISCLAIM_TTL, 'seconds').toDate();
            }
            else {
                self.disclaimExpiresAt = _disclaimExpiresAt;
            }
            debug && console.log('setDisclaimed self: ', self.disclaimExpiresAt);
            debug && self.dump('setDisclaimed');
            // self.alert.show('info', 'Disclaimer status', 'Expires at ' + moment(self.disclaimExpiresAt).format('L h:mm:ss a'),);
            // self.save();
        },
        isDisclaimed(value) {
            debug && self.dump('isDisclaimed');
            let _expired = moment(self.disclaimExpiresAt).isBefore(new Date());
            // let _disclaimed = self.disclaimed && !_expired;
            let _disclaimed = !_expired;
            // console.log('sessionStore.isDisclaimed: ' + _disclaimed);
            // if(_expired) {
            //     self.setDisclaimed(false);
            // }
            debug && console.log('*** sessionStore.isDisclaimed? ' + _disclaimed);
            return _disclaimed;
        },

        dump(prefix) {
            console.log((prefix || '') + ' now', new Date().toISOString());
            console.log((prefix || '') + ' disclaimed', self.disclaimed,'expiresAt', self.disclaimExpiresAt.toISOString());
        },
        hydrate(cookies) {
            debug && console.log('*** sessionStore.hydrate cookies: ', cookies);
            if(cookies) {
                let _disclaimExpiresAt = undefined;
                // @todo - this is not checking the path as part of matching the cookie.
                // 20210714, BV had 2 cookies named PUBSOR_DISCLAIMEXPIRESAT, one path /, one path /details
                // we got the 'wrong time' probably from the 'wrong path'
                if(cookies.PUBSOR_DISCLAIMEXPIRESAT) {
                     _disclaimExpiresAt = cookies.PUBSOR_DISCLAIMEXPIRESAT;
                    debug && console.log('*** cookies._disclaimExpiresAt', _disclaimExpiresAt);
                    }
                else {
                    debug && console.warn('*** cookies exist - but NO PUBSOR_DISCLAIMEXPIRESAT');

                }
                if(!_disclaimExpiresAt || _disclaimExpiresAt === null || _disclaimExpiresAt === 'null') {
                    _disclaimExpiresAt = 1;
                }
                let d = new Date();
                d.setTime(_disclaimExpiresAt);
                _disclaimExpiresAt = d;
                self.disclaimExpiresAt = d;
            }

        },
        save(setCookie) {
            // localStorage.setItem(PUBSOR_ACCESSTOKEN, self.accessToken);
            // localStorage.setItem(PUBSOR_ACCESSEXPIRESAT, moment(self.accessExpiresAt).toISOString());
            // localStorage.setItem(PUBSOR_DISCLAIMED, self.disclaimed);
            // console.log('save disclaimExpiresAt: ' + moment(self.disclaimExpiresAt).toISOString() + ' getTime: ' + self.disclaimExpiresAt.getTime());
            // localStorage.setItem(PUBSOR_DISCLAIMEXPIRESAT, self.disclaimExpiresAt.getTime());
            // let _disclaimExpiresAt = localStorage.getItem(PUBSOR_DISCLAIMEXPIRESAT);
            // console.log('localStorage._disclaimExpiresAt getItem verify: ', _disclaimExpiresAt);
            if(setCookie) {
                debug && console.log('sessionStore.setCookie available -- saving.');
                let options = {
                    path: '/',
                    expires: self.disclaimExpiresAt,
                    domain: Config.isLocal ? undefined : '.tn.gov',
                };
                debug && console.log('sessionStore.setCookie options:', options);
                setCookie(PUBSOR_DISCLAIMEXPIRESAT, self.disclaimExpiresAt.getTime(), options);
                debug && console.log('sessionStore.setCookie completed');
            }
            else {
                debug && console.warn('sessionStore.save -- no set cookie!');
            }
        },
        setExpandedSearch(value) {
            self.expandedSearch = value;
        },
        setExpandedEmail(value) {
            self.expandedEmail = value;
        }

    }));

