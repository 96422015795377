import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Img } from 'react-image'

import Typography from '@material-ui/core/Typography';

import Config from '../../config/Config';
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

import silhouette from '../../images/silhouette_black.png';

const debug = false;

const useStyles = makeStyles((theme) => ({
    grow: {
        '&:hover': {
            transform: 'scale(1.05)',
            transitionDuration: '0.2s',
        }
    },
    shadow: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    borderAgainstChildren: {
        border: '4px solid red',
    },
    tableImage: {
        width: 48,
        height: 48,
        objectFit: 'cover',
    },
    miniImage: {
        width: 64,
        height: 64,
        objectFit: 'cover',
    },
    largeImage: {
        width: 120,
        height: 160,
        objectFit: 'cover',
    },
    detailImage: {
        width: undefined,
        height: 200,
        objectFit: 'cover',
    },

}));

export const isAgainstChildren = (offender) => {
    let _isOffenderAgainstChildren = false;
    if(offender) {
        if (offender.secth) {
            if (offender.secth.classification > '') {
                if (offender.secth.classification.match(/AGAINST CHILDREN/i)) {
                    _isOffenderAgainstChildren = true;
                }
            }
        }
    }
    return _isOffenderAgainstChildren;

}
// props sent in -> searchStore, userStore
export default function OffenderImage(props) {
    const classes = useStyles();

    let offender = props.offender || { soid: '00000000', offname: 'UNKNOWN', isoac: false, sorimage: {}};
    // @todo - create safeGet
    // console.log('<OffenderImage ... offender.soid: ' + offender.soid);
    false && console.log('props', JSON.stringify(props));
    let url = props.offender.sorimage && props.offender.sorimage.url;
    debug && console.log('OffenderImage.url: ', url);
    // console.log('OffenderImage soid: ' + soid + ' openDetails:' + openDetails);

    return (
                    <Grid container align={'center'} direction={'column'}
                          onClick={props.onClick}
                    >
                        <Grid item>
                           <Img src={[url, silhouette]} alt={offender.secta.offname}
                                 className={clsx((props.tableImage || props.largeImage) && classes.grow,
                                classes.shadow,
                                props.tableImage && classes.tableImage,
                                props.detailImage && classes.detailImage,
                                props.miniImage && classes.miniImage,
                                props.largeImage && classes.largeImage,
                                offender.isoac ? classes.borderAgainstChildren : undefined)}
                                 loader={<CircularProgress size={20} />}
                            />

                        </Grid>

                        {offender.isoac && (props.tableImage || props.miniImage) &&
                        <Grid item>
                            <Typography variant={'caption'} display='block' style={{color: 'red', lineHeight: 1.0}}>
                            OFFENDER AGAINST CHILDREN
                        </Typography>
                        </Grid>
                        }
                    </Grid>
     );
}

