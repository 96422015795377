import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@material-ui/icons/CheckCircle';
import {Box, Divider, Grid, List, ListItem, Typography} from "@material-ui/core";
import OffenderImage from "../shared/components/OffenderImage";
import Output from '../shared/editorjs-renderer';
import Config from "../config/Config";
import {Alert, AlertTitle} from "@material-ui/lab";

const debug = Config.enableConsoleDebug;

const validEmail = require('../shared/util/validation').validEmail;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

export default function EmailDialog(props) {
    const classes = useStyles();
    const [email, setEmail] = React.useState(props.pubStore.subStore.email);
    const [error, setError] = React.useState('');
    const [isWorking, setIsWorking] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const reset = () => {
        setIsWorking(false);
        setIsSubmitted(false);
        setError('');
    }

    let offname = 'UNKNOWN';
    let soid = '00000000';
    let offender = props.offender;

    if(offender) {
        offname = offender.secta.offname;
        soid = offender.soid;
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                <Grid container alignItems={'center'}>
                    <Grid item>
                        <OffenderImage largeImage offender={offender} style={{marginTop: 4, marginRight: 8}}/>
                    </Grid>
                    <Grid item xs style={{marginLeft: 24}}>
                        <Typography variant={'h3'}>
                            Subscribe to Email Alerts on {offname} (OFFENDER ID: {soid})
                        </Typography>

                    </Grid>

                </Grid>
                {props.pubStore.alert.visible && (
                    <Alert severity={props.pubStore.alert.type} variant={'filled'} onClose={() => props.pubStore.alert.hide()}>
                        <AlertTitle>{props.pubStore.alert.title}</AlertTitle>
                        {props.pubStore.alert.message}
                    </Alert>
                ) }

            </DialogTitle>
            <DialogContent dividers={true}>
                {!isSubmitted && (
                    <>
                        <Typography variant={'body1'}>
                            To subscribe to email alerts on
                            offender {offname} (OFFENDER ID: {soid}), please
                            provide/confirm
                            your email address and tap 'Submit'.
                            You can manage your alerts from the Email Alerts page.
                        </Typography>
                        <TextField required id="tips-submitemail" label="Your Email"
                                   fullWidth
                                   variant={'outlined'}
                                   error={error > ''}
                                   helperText={error}
                                   margin="dense"
                                   autoComplete={'off'}
                                   value={email}
                                   onChange={(event, value) => {
                                       let em = event.target.value;
                                       setEmail(em);
                                       if (!validEmail(em)) {
                                           setError('Please enter valid email address');
                                       } else {
                                           setError('');
                                       }
                                   }}
                                   style={{marginTop: 24}}
                                    />
                        {props.pubStore.loaded &&
                        <Box style={{marginTop: 24, backgroundColor: Config.CONTENT_BACKGROUND}}>
                            <Output data={props.pubStore.getContent('DISCLAIMER_EMAIL').data}
                                style={{paragraph: {fontSize: "0.75rem"}, header: {fontWeight: '400', fontSize: "1.0rem"}}}
                            />
                        </Box>
                        }

                    </>
                )}
                {isSubmitted && (

                    <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <CheckIcon color={'inherit'} style={{color: 'green', marginRight: 8}} fontSize={'large'}/>
                        <Typography variant={'body1'} >
                            You have successfully signed up for alerts on {offname}.
                            TBI will occasionally notify you at {email} when updates are made to the offender's record.
                            Thank you.
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {isWorking && (
                    <CircularProgress variant={"indeterminate"}/>
                )}
                {!isSubmitted && (
                    <>
                        <Button color="primary" onClick={() => {
                            reset();
                            props.handleClose();
                        }}>
                            Cancel
                        </Button>
                        <Button color="primary"
                                disabled={!(email > '') || error > '' || isWorking}
                                onClick={() => {
                                    setIsWorking(true);
                                    props.pubStore.subStore.setEmail(email);
                                    props.pubStore.subStore.sub.set({
                                        id: 0,
                                        email: props.pubStore.subStore.email,
                                        mode: 'soid',
                                        key: soid,
                                        status: 'ACTIVE'
                                    });
                                    props.pubStore.subStore.sub.save()
                                        .then(() => {
                                            props.pubStore.subStore.setOffender({});
                                            return props.pubStore.subStore.load()
                                        })
                                        .then(() => {
                                            setIsSubmitted(true);
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        })
                                        .finally(() => {
                                            setIsWorking(false);
                                        })
                                    // item.save();

                                }}>
                            Submit
                        </Button>
                    </>
                )}
                {isSubmitted && (
                    <>
                        <Button color="primary" onClick={() => {

                            if (props.history) {
                                setTimeout(() => {
                                    props.history.push('/email');
                                }, 250);
                            }
                            reset();
                            props.handleClose();

                        }}>
                            Manage Email Alerts
                        </Button>
                        <Button color="primary" onClick={() => {
                            reset();
                            props.handleClose();
                        }}>
                            Close
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
