import LOGO_TEST from '../images/TBI_TEST_60.png';
import LOGO_PROD from '../images/TBI_PROD_60.png';

import LOGO_TEST_LG from '../images/TBI_TEST_180.png';
import LOGO_PROD_LG from '../images/TBI_PROD_180.png';
import LOGO_TEST_SM from '../images/TBI_TEST_40.png';
import LOGO_PROD_SM from '../images/TBI_PROD_40.png';
import TN_MARK from '../images/TN_mark.png';

const version = require('../../package.json').version;
const _name = require('../../package.json').name;
const _buildMode = require('../../package.json').buildMode;
const secrets = require('../secrets-pubsor.json');

const _devLinks = {

};
const _prodLinks = {
    // TBD
};
const debug = false;
var count = 0;
const MAX_BUILDMODE_LOG = 2;

class config {

    get version() {
        return version;
    }
    get name() {
        return _name;
    }

    get PORT_DEV() {
        return 5003;
    }
    get PORT_TEST() {
        return 5004;
    }
    get PORT_PROD() {
        return 5005;
    }

    port(env) {
        if(env.startsWith('p')) {
            return this.PORT_PROD;
        }
        if(env.startsWith('t')) {
            return this.PORT_TEST;
        }
        return this.PORT_DEV;
    }

    get TOOLTIP_TTL() {
        return 5000;
    }

    get CONTENT_BACKGROUND() {
        if(!this.isDev) {
            return 'transparent';
        }
        return 'rgba(68, 92, 124, 0.05)';
    }

    get HEADER_BACKGROUND() {
        // #445c7c'
        return 'rgba(68, 92, 124, 1.0)';
    }

    get CARD_BACKGROUND() {
        // #445c7c'
        // pre 3/16
        return 'rgba(238,238,238, 0.75)';
        // return 'rgba(68, 92, 124, 0.075)';
    }

    get CONFIRM_BACKGROUND() {
        return 'rgba(221,53,36, 0.1)';
    }

    get PRIMARY_TEXT() {
        return '#1b365d';
    }

    get BLACK_TEXT() {
        return '#000000';
    }

    get LIGHT_TEXT() {
        return '#bbbbbb';
    }

    get TN_BLACK() {
        return '#000000';
    }

    get TN_RED() {
        return '#ee3524';
        //BV Changed from dd3542 7/29/21 per Josh Devine
    }

    get TN_GREEN() {
        return '#659727';
    }

    get TN_ORANGE() {
        return '#ed9924';
    }

    get TN_BLUE() {
        return '#17417c';
    }

    get TN_DARKBLUE() {
        return '#1b365d';
    }

    get forceHamburger() {
        return false;
    }

    get LOGO() {
        if(this.isProd) {
            return LOGO_PROD;
        }
        else {
            return LOGO_TEST;
        }
    }
    get LOGO_LG() {
        if(this.isProd) {
            return LOGO_PROD_LG;
        }
        else {
            return LOGO_TEST_LG;
        }
    }

    get LOGO_SM() {
        if(this.isProd) {
            return LOGO_PROD_SM;
        }
        else {
            return LOGO_TEST_SM;
        }
    }

    get TN_MARK() {
        return TN_MARK;
    }

    get TNMAP_BASE() {
        //return 'https://test.tnmap.tn.gov/sor/#';
        // return 'https://tnmap.tn.gov/sor/beta/#';
        return 'https://tnmap.tn.gov/sor/#';

    }

    get buildMode() {
        return _buildMode;
    }

    get isProd() {
        let _prod = false;
        // if(base.toLowerCase().startsWith('https') || base.endsWith(':5005') || this.buildMode === 'prod') {
        //     _prod = true;
        // }
        if(this.buildMode === 'prod') {
            _prod = true;
        }
        debug && console.log('Config.isProd buildMode: ' + this.buildMode + ' isProd: ' + _prod);
        return _prod;
    }

    get isDev() {
        let _dev = this.buildMode === 'dev';
        (count < MAX_BUILDMODE_LOG)  && debug && console.log('Config.isDev buildMode: ' + this.buildMode + ' isDev: ' + _dev);
        return _dev;
    }

    get isTest() {
        let _test = this.buildMode === 'test';
        (count < MAX_BUILDMODE_LOG)  && debug && console.log('Config.isTest buildMode: ' + this.buildMode + ' isTest: ' + _test);
        return _test;
    }

    get isLocal() {
        let base = window.location.origin;
        if(base.match(/localhost/)) {
            return true;
        }
        return false;
    }

    get runMode() {
        if(this.isDev) {
            return "dev";
        }
        if(this.isTest) {
            return "test";
        }
        return "prod";

    }

    // DEV
// const SOLR_BASE = 'http://localhost:8983';
// MBP-204`->WIN
// const SOLR_BASE = 'http://10.211.55.6:8983';
// MBP-204`->TEST
//     const SOLR_BASE = 'http://10.125.19.139:8983';

    get PUBSOR_BASE() {
        // let base = `http://localhost:${this.PORT_DEV}`;
        let base = window.location.origin;
        (count < MAX_BUILDMODE_LOG) && debug && console.log('PUBSOR_BASE location.origin:', base);

        if(this.isDev) {
            // if(base.endsWith(':3000')) {
            //     base = base.replace(/:3000/, `:${this.PORT_DEV}`);
            // }
            if(base.match(/:300[01]/)) {
                base = base.replace(/:300[01]/, `:${this.PORT_DEV}`);
            }
            (count++ < MAX_BUILDMODE_LOG) && debug && console.log('PUBSOR_BASE isDev:', this.isDev, 'base', base);

        }
        else if(this.isProd) {
            return base;
        }
        else {
            if(this.isLocal) {
                // on visual studio, these are not the ports, so the localhost base will not change.
                base = base.replace(/:300[01]/, `:${this.PORT_DEV}`);
            }

            // base = `http://10.125.19.139:${this.PORT_TEST}`;
            (count++ < MAX_BUILDMODE_LOG) && debug && console.log('PUBSOR_BASE TEST:', 'base', base);
        }
        return base;
    }

    get API_DELAY() {
        return 10;
    }

    get enableJsonDebug() {
        return !this.isProd;
    }

    get enableConsoleDebug() {
        return !this.isProd;
    }

    get canLog() {
        return this.isDev;
    }

    get links() {
        console.log('Config.links.buildMode: ' + _buildMode);
        // build mode is set in package.json
        // __DEV__ versus release builds (non-debug) do not affect the buildMode
        if (this.isDev) {
            return _devLinks;
        }
        return _prodLinks;
    }

    get RECAPTCHA_SITE_KEY() {
        // logic for different environments (secrets)
        // Steeple Technologies, Inc. Permits localhost.
        //        if (_buildMode && _buildMode.match(/beta|dev/)) {
        //             return secrets.dev.id;
        //         }
        //         return secrets.prod.id;
        if(this.isDev) {
            return secrets.dev.recaptcha_site_key;
        }
        if (this.isTest) {
            return secrets.test.recaptcha_site_key;
        }
        if (this.isProd) {
            return secrets.prod.recaptcha_site_key;
        }
        return "";
    }

    // 24h * 60 min/h * 60 sec/min = 86400;
    get DISCLAIM_TTL() {
        return 86400;  // seconds
    }
}

const singelton = new config();
Object.freeze(singelton);

export default singelton;
