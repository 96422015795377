import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckIcon from "@material-ui/icons/CheckCircle";
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import OffenderImage from "../shared/components/OffenderImage";
import { Alert, AlertTitle } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
const validEmail = require('../shared/util/validation').validEmail;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

const NOTE_ERROR = 'The tip/bad information is required. Please supply any details about this offender.';

export default function TipDialog(props) {
    const classes = useStyles();
    const [submitname, setSubmitname] = React.useState(props.pubStore.tips.submitname);
    const [submitemail, setSubmitemail] = React.useState(props.pubStore.tips.submitemail);
    const [note, setNote] = React.useState('');
    const [error, setError] = React.useState('');
    const [noteError, setNoteError] = React.useState(NOTE_ERROR);
    const [isWorking, setIsWorking] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const reset = () => {
        setIsWorking(false);
        setIsSubmitted(false);
        setError('');
        setNoteError(NOTE_ERROR);

    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" >
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <OffenderImage largeImage offender={props.offender} style={{ marginTop: 4, marginRight: 8 }} />
                    <Typography variant={'h3'}>
                        Submit Tip / Bad Info on {props.offender.secta.offname} (OFFENDER ID: {props.offender.soid})
                    </Typography>
                </Box>
                {props.pubStore.alert.visible && (
                    <Alert severity={props.pubStore.alert.type} variant={'filled'} onClose={() => props.pubStore.alert.hide()}>
                        <AlertTitle>{props.pubStore.alert.title}</AlertTitle>
                        {props.pubStore.alert.message}
                    </Alert>
                )}
            </DialogTitle>
            <DialogContent dividers={true}>
                {!isSubmitted && (
                    <>
                        <DialogContentText>
                            To submit a tip or bad information related to offender {props.offender.secta.offname}, please provide your name, email address, and any information here.
                            Thank you. (2000 characters maximum)
                        </DialogContentText>
                        <TextField id="tips-submitname" label="Submitter Name"
                            fullWidth
                            variant={'outlined'}
                            margin="dense"
                            value={submitname}
                            onChange={(event, value) => {
                                setSubmitname(event.target.value);

                            }} />
                        <TextField id="tips-submitemail" label="Submitter Email"
                            fullWidth
                            variant={'outlined'}
                            error={error > ''}
                            helperText={error}
                            margin="dense"
                            value={submitemail}
                            onChange={(event, value) => {
                                let em = event.target.value;
                                setSubmitemail(event.target.value);
                                if (!validEmail(em, true)) {
                                    setError('Please enter valid email address');
                                } else {
                                    setError('');
                                }


                            }} />
                        <TextField required id="tips-note" label="Information"
                            fullWidth
                            multiline
                            variant={'outlined'}
                            margin="dense"
                            value={note}
                            error={noteError > ''}
                            helperText={noteError}
                            inputProps={{ maxLength: 2000 }}
                            onChange={(event, value) => {
                                setNote(event.target.value);
                                if (event.target.value > '') {
                                    setNoteError('');
                                }
                                else {
                                    setNoteError(NOTE_ERROR);
                                }
                            }} />
                    </>
                )}
                {isSubmitted && (
                    <DialogContentText style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <CheckIcon color={'inherit'} style={{ color: 'green', marginRight: 8 }} fontSize={'large'} />
                        <Typography variant={'body1'} >
                            TBI has received your request regarding offender {props.offender.secta.offname} and is working on it. Thank you for the information.
                        </Typography>
                    </DialogContentText>
                )}

            </DialogContent>
            <DialogActions>
                {isWorking && (
                    <CircularProgress variant={"indeterminate"} />
                )}
                {!isSubmitted && (
                    <>
                        <Button color="primary" onClick={() => {
                            props.handleClose();
                            setNote('');
                        }} >
                            Cancel
                        </Button>
                        <Button color="primary"
                            disabled={(error > '' || noteError > '')}
                            onClick={() => {
                                setIsWorking(true);
                                props.pubStore.tips.setSubmitname(submitname);
                                props.pubStore.tips.setSubmitemail(submitemail);
                                props.pubStore.tips.setNote(note);
                                props.pubStore.tips.save(props.offender.soid, props.pubStore.tips.submitname, props.pubStore.tips.submitemail, props.pubStore.tips.note, props.pubStore.tips.status, props.pubStore.tips.changedby)
                                    .then(() => {
                                        props.pubStore.tips.setShowAcknowledgement(true);
                                        props.pubStore.tips.clearSelected();
                                        setNote('');
                                        // thank you
                                        // props.handleClose();
                                    })
                                    .then(() => {
                                        setIsSubmitted(true);
                                    })

                                    .catch(error => {
                                        console.log(error);
                                    })
                                    .finally(() => {
                                        setIsWorking(false);
                                    })

                            }} >
                            Submit
                        </Button>
                    </>
                )}
                {isSubmitted && (
                    <>
                        <Button color="primary" onClick={() => {
                            reset();
                            props.handleClose();
                        }}>
                            Close
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
