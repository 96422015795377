import {getParent, types} from 'mobx-state-tree';

import {Api} from "../shared/stores/Api";
import Config from "../config/Config";
import axios from "axios";
import moment from "moment";
import {Alert} from "../shared/stores/Alert";

const delay = require('../shared/stores/Api').delay;

const debug = Config.enableConsoleDebug;

export const TipsStore = types
    .model('TipsStore', {
        id: 0,
        tips: types.frozen([]),
        selected: types.frozen({}),
        // selected offender.
        offender: types.frozen({}),

        submitname: "",
        submitemail: "",
        status: "",
        note: "",
        changedby: "default",
        data: types.frozen({}),

        showAcknowledgement: false,

        _api: types.optional(Api, {}),

        _alert: types.optional(Alert, {}),

    })
    .views( self => ({
        get api() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 1);
            // console.log('Subscription. got parent', parent);
            return parent.api;
        },
        get alert() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 1);
            // console.log('Subscription. got parent', parent);
            return parent.alert;
        },
    }))
.actions(self => ({
    setId(value) {
        self.id = value;
    },
    setShowAcknowledgement(value) {
        self.showAcknowledgement = value;
    },
    setTips(value) {
        self.tips = value;
    },
    setOffender(value) {
        self.offender = value;
    },
    setSelected(tip, submitname, submitemail, status, note, changedby, data) {
        self.selected = tip;
        self.setNote(note);
        self.setChangedby(changedby);

    },
    clearSelected() {
        self.selected = {};
        self.offender = {};
        self.setNote('');
    },
    setNote(value) {
        if(value) {
            self.note = value;
        }
        else {
            self.note = '';
        }
    },
    setStatus(value) {
        if(value) {
            self.status = value;
        }
        else {
            self.status = '';
        }
    },
    setChangedby(value) {
        if(value) {
            self.changedby = value;
        }
        else {
            self.changedby = '';
        }
    },
    setSubmitname(value) {
        if(value) {
            self.submitname = value;
        }
        else {
            self.submitname = '';
        }
    },
    setSubmitemail(value) {
        if(value) {
            self.submitemail = value;
        }
        else {
            self.submitemail = '';
        }
    },
    setData(value) {
        if(value) {
            self.data = value;
        }
        else {
            self.data = '';
        }
    },
    // do not include load in pubsor
    save(soid, submitname, submitemail, note, status, changedby) {
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        let tip = {
            soid: soid,
            submitname: submitname,
            submitemail: submitemail,
            note: note,
            status: 'NEW',
            changedby: changedby,
            history:  // must be array
                [{
                    status: 'NEW',
                    at: new Date(),
                    by: 'pubsor',
                    description: 'Submitted via PUBSOR Website. SOID: ' + soid + ' Submit name: ' + submitname + ' email: ' + submitemail + ' note: ' + note,
                }],
        }
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/tip`;
// 20210806 - need to send the attributes of the MTip (EntityFramework object) -- not sub-objects
            let body = {
                soid: soid,
                submitemail: tip.submitemail,
                submitname: tip.submitname,
                note: tip.note,
                changedby: tip.changedby,
                status: tip.status,
                //history: tip.history,
            }
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .post(url, body, {
                            headers,
                        })
                })
                .then(result => {
                    let data = result.data;
                    let code = result.data.code || 200;
                    if(code >= 400) {
                        let supplemental = JSON.parse(result.data.supplemental);
                        let error = new Error(supplemental.message);
                        error.code = code;
                        self.api.error(error);
                        self.alert.show('error', error, error.message);
                        reject(error);
                        return;
                    }
                    debug && console.log('pubStore.tip. success return data: ' + JSON.stringify(data, null, 4));
                    self.api.success('200', 'tip complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('tip.save/tip.error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },


}));
