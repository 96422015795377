import {types} from 'mobx-state-tree';

const debug = true;

// type - error, warn?, info?
//
export const Alert = types
    .model('Alert', {
        title: '',
        message: '',
        type: '',
        duration: 10000,
        visible: false,

    })
    .views( self=> ({

    }))
    .actions( self => ({
        setDuration(value) {
            self.duration = value;
        },
        show(type, title, message) {
            self.visible = true;
            self.title = ''+title;
            self.message = ''+message;
            self.type = type;
            if(self.duration > 0) {
                setTimeout(() => {self.hide()}, self.duration);
            }
        },
        hide() {
            if(self.visible) {
                self.visible = false;
                self.title = '';
                self.message = '';
            }
        },
    }));
