import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Config from '../config/Config';

import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Box, Typography} from "@material-ui/core";

const TN_RED = '#dd3524';
const TN_GREEN = '#659727';
const TN_ORANGE = '#ed9924';
const TN_BLUE     = '#17417c';
const TN_DARKBLUE = '#1b365d';
// require does not work ?
// const TBI_PROD = require('../images/TBI_PROD_60.png');
// const TBI_TEST = require('../TBI_TEST_76.png');


const drawerWidth = 280;
const debug = Config.enableConsoleDebug;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#445c7c',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
      marginLeft: drawerWidth,
    },
  //   // zIndex: theme.zIndex.drawer + 1,
  //   // transition: theme.transitions.create(['width', 'margin'], {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100%)`
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    // position: 'relative',
    // whiteSpace: 'nowrap',
    width: drawerWidth,

    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    // drawerPaperClose: {
  //   overflowX: 'hidden',
  //   // transition: theme.transitions.create('width', {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  //   width: theme.spacing(7),
  //   [theme.breakpoints.up('sm')]: {
  //     width: theme.spacing(9),
  //   },
  // },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export const navItems = [
  {
    name: "Sex Offender Registry",
    action: "Home",
    // action: "Go to Sex Offender Registry Home Page",
    //BV Removed per Josh Devine request 7/29/21
    path: "/home"
  },
  {
    name: "Search",
    action: "Search For Offenders",
    //BV Uppercased first letter of each word per email from Josh Devine request 8/4/21
    path: "/search"
  },
  {
    name: "Email Alerts",
    action: "Manage My Email Alerts",
     //BV Uppercased first letter of each word per email from Josh Devine request  8/4/21
    path: "/email"
  },
  {
    name: "Map Offenders",
    action: "Map Offenders",
     //BV Uppercased first letter of each word per email from Josh Devine request  8/4/21
    path: Config.TNMAP_BASE,
    target: "self",
  },
  {
    name: "SOR Information",
    action: "Review SOR Information, FAQS, & Laws",
     //BV Uppercased first letter of each word per email from Josh Devine request  8/4/21
    path: "/info"
  },
  {
    name: "Safety & Education",
    action: "Learn About SOR Safety & Education",
     //BV Uppercased first letter of each word per email from Josh Devine request  7/29/21
    path: "https://www.nsopw.gov/en/SafetyAndEducation",
    target: "_blank",
  },
  {
    name: "TBI Home",
    action: "Visit The TBI Home Page",
     //BV Uppercased first letter of each word per email from Josh Devine request  8/4/21
    path: "https://tn.gov/tbi",
    target: "self"
  },
  {
    name: "TN.gov",
    action: "Visit The TN.GOV Home Page",
     //BV Uppercased first letter of each word per email from Josh Devine request  8/4/21
    path: "https://tn.gov",
    target: "_blank",
  },

];

export default function NavigationDrawer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [signinEl, setSigninEl] = React.useState(null);
  const scrollRef = React.useRef(null);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  let cols = 2.5;
  cols = sm ? 3.5 : cols;
  cols = md ? 5.5 : cols;

  return (

        <Drawer
            // container={container}
            variant="temporary"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={props.open}
            onClose={props.onClose}
            ModalProps={{keepMounted: true,}}
        >
          <div className={classes.toolbar}>
          </div>
          <Divider />
          <List>
            {navItems.map(item => {
              // console.log('navItem', item);
                return (
                    <ListItem key={item.path} button
                              onClick={() => {
                                debug && console.log('NavDrawer name: ' + item.name + ' path: ' + item.path);
                                if(item.path.toLowerCase().startsWith('http')) {
                                  if(item.target === "self") {
                                    window.location = item.path;
                                  }
                                  else {
                                    window.open(item.path, item.target);
                                  }
                                }
                                else {
                                  props.history.push(item.path);
                                }
                                props.onClose();
                              }}
                              >
                      <ListItemText primary={item.action}>
                        {/*BV 7/29/21 CHANGE FROM NAME TO GET UPPERCASE PER JOSH DEVINE*/}

                      </ListItemText>
                    </ListItem>
                )
            })}
          </List>
          <Divider />
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} style={{marginTop: 16}}>
          <img src={Config.LOGO_LG} style={{width: 160, height: 160}} />
          <Typography variant={'subtitle1'} style={{marginLeft: 16, marginRight: 16, textAlign: 'center', fontStyle: 'italic'}}>
            "That Guilt Shall Not Escape Nor Innocence Suffer"
          </Typography>
          </Box>
        </Drawer>

  )
}
