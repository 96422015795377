import {types, getParent, hasParent} from 'mobx-state-tree';

import {Api} from "../shared/stores/Api";
import Config from "../config/Config";
import axios from "axios";
import moment from "moment";
import {Alert} from "../shared/stores/Alert";

const delay = require('../shared/stores/Api').delay;

const debug = Config.enableConsoleDebug;

export const Subscription = types
    .model('Subscription', {

        id: 0,
        email: types.optional(types.string, ''),
        key: types.optional(types.string, ''),
        mode: types.optional(types.string, ''),
        status: types.optional(types.string, ''),
        updatedAt: types.optional(types.Date, new Date(1)),
        createdAt: types.optional(types.Date, new Date(1)),
        offender: types.frozen({}),

        dirty: false,

        _api: types.optional(Api, {}),

        _alert: types.optional(Alert, {}),

    })
    .views( self => ({
        get api() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 2);
            // console.log('Subscription. got parent', parent);
            return parent.api;
        },
        get alert() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 2);
            // console.log('Subscription. got parent', parent);
            return parent.alert;
        },
    }))
.actions(self => ({
    // setApi(value) {
    //     self._api = value;
    // },
    setId(value) {
        self.id = value;
    },
    setEmail(value) {
        self.email = value;
    },
    setMode(value) {
        self.mode = value;
    },
    setKey(value) {
        if(value && value !== null) {
            self.key = value;
        }
        else {
            self.key = '';
        }
    },
    setStatus(value) {
        if(value && value !== null) {
            self.status = value;
        }
        else {
            self.status = '';
        }
    },
    setUpdatedAt(value) {
        self.updatedAt = Date.parse(value);
    },
    setOffender(value) {
        if(value && value !== null) {
            self.offender = value;
        }
        else {
            self.offender = {};
        }
    },
    setCreatedAt(value) {
        self.createdAt = Date.parse(value);
    },
    setDirty(value) {
        self.dirty = value;
    },
    clear() {
        self.set({id: 0});
    },
    set(data) {
        self.setId(data.id);
        self.setEmail(data.email);
        self.setKey(data.key);
        self.setMode(data.mode);
        self.setStatus(data.status);
        self.setOffender(data.offender);
        self.setUpdatedAt(data.updatedAt);
        self.setCreatedAt(data.createdAt);
        self.setDirty(false);
    },
    save() {
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/subscribe`;
            // promote the body to be the properties of a subscription
            // C# controller works better without unused fields.
            let body = {
                    // id: self.id,  // ignored

                    email: self.email,
                    key: self.key,
                    mode: self.mode,
                    // status: self.status,  // ignored
                    // updatedAt: self.updatedAt,
                    // createdAt: self.createdAt,
            }
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .post(url, body, {headers})
                })
                .then(result => {
                    let data = result.data;
                    let code = result.data.code || 200;
                    if(code >= 400) {
                        let supplemental = JSON.parse(result.data.supplemental);
                        let error = new Error(supplemental.message);
                        error.code = code;
                           self.api.error(error);
                            self.alert.show('error', error, error.message);
                            reject(error);
                            return;
                        }
                        debug && console.log('pubStore.subscribe. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    self.set(data);
                    self.api.success('200', 'subscribe complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('pubStore.subscribe.error: ', error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
    remove() {
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/subscribe/${self.email}/${self.id}`;
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .delete(url, {
                            headers,
                        })
                })
                .then(({data}) => {
                    debug && console.log('subStore.remove. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    // application needs to call *load* after save to refresh the list.
                    self.api.success('200', 'remove complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('subStore.remove error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
}));
