export const validEmail = (s, allowBlank = false) => {
    if (!s) {
        return allowBlank;
    }
    if(allowBlank && s.trim() === '') {
        return true;
    }
    let valid = s.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return valid;
}

export const validZip = (s, allowBlank) => {
    if(!s) {
        return allowBlank;
    }
    if(allowBlank && s.trim() === '') {
        return true;
    }

    let valid = s.match(/^[0-9]{5}$/);
    console.log('validZip: ' + s + ' valid? ' + valid);
    return valid;

}
