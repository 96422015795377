const _get = (p, o) =>
    p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o)

module.exports = {

     get: (path, o, defaultValue) => {
        if(!path) {
            return o;
        }
        let p = Array.isArray(path) ? path : path.split('.');
        return _get(p, o) || defaultValue;
    }
};


