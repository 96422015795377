import {red} from '@material-ui/core/colors';
import {createMuiTheme} from '@material-ui/core/styles';

// const TN_HEADER_FONT_FAMILY = 'permianslabseriftypefaceRg';
const TN_HEADER_FONT_FAMILY = 'Open Sans';

// A custom theme for this app
let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1b365d',
            // main: '#556cd6',
        },
        secondary: {
            main: '#ee3524',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    overrides: {
        MuiAlert: {
            filledError: {
                backgroundColor: '#ee3524',
            }
        },
        // https://material-ui.com/api/typography/
        MuiTypography: {
            h1: {
                // color: 'red',
                fontSize: '1.5rem',
                fontFamily: TN_HEADER_FONT_FAMILY,
                // fontFamily: 'open sans',

            },
            h2: {
                // color: 'red',
                fontSize: '2.0rem',
                // fontWeight: 700,
                fontFamily: TN_HEADER_FONT_FAMILY ,
                // fontFamily: 'open sans',
            },
            h3: {
                // color: 'red',
                fontSize: '1.25rem',
                fontFamily: TN_HEADER_FONT_FAMILY,
                // fontFamily: 'open sans',

            },
            h4: {
                // color: 'red',
                fontSize: '1.0rem',
                fontFamily: TN_HEADER_FONT_FAMILY,
                // fontFamily: 'open sans',

            },
        h5: {
            // color: 'red',
            fontSize: '.875rem',
            fontFamily: TN_HEADER_FONT_FAMILY,
            // fontFamily: 'open sans',

        },
            body1: {
                fontFamily: 'Open Sans',
            },
            body2: {
                fontFamily: 'Open Sans',
                fontSize: '.875rem', //added BV 7/20/21
            },
            subtitle1: {
                // color: 'red',
                fontWeight: 'bold',
                fontSize: '1.25rem',
                fontFamily: 'Open Sans',
            },
            subtitle2: {
                // color: 'red',
                // fontWeight: 'regular',
                fontSize: '0.9rem',
                fontFamily: 'Open Sans',
            },
            caption: {
                fontFamily: 'Open Sans',
            }
        },
    },

});

// theme.toolbar = {height: 108, heightMessage: 32};
theme.toolbar = {height: 32, heightMessage: 32, heightMenuBar: 40};

export const appBarSpace = (sm, hasMessage) => {
    let height = theme.toolbar.height;
    if(sm) {
        height += theme.toolbar.heightMenuBar;
    }
    if(hasMessage) {
        height += theme.toolbar.heightMessage;
    }
    return height;
}


// centralized styling for the Output component.
// https://github.com/BomdiZane/EditorJS-React-Renderer
// https://github.com/BomdiZane/EditorJS-React-Renderer#style
// must use the style={theme.output} property in the <Output /> component

// DEFAULT FONT HEADER SIZES (deliberately not compliant)
// https://stackoverflow.com/questions/5410066/what-are-the-default-font-sizes-in-pixels-for-the-html-heading-tags-h1-h2

theme.output =
    {
        header: {
            textAlign: 'left',
            // color: 'red',
            // fontFamily: 'permianslabseriftypefaceRg',
            fontFamily: TN_HEADER_FONT_FAMILY ,

        },
        image: {
            img: {
                maxHeight: '400px',
            },
            figure: {
                backgroundColor: 'aliceblue',
                border: '1px solid #eee'
            },
            figcaption: {
                borderRadius: '5px',
            }
        },
        video: {
            video: {
                maxHeight: '400px',
            },
            figure: {
                backgroundColor: 'aliceblue',
                border: '1px solid #eee'
            },
            figcaption: {
                borderRadius: '5px',
            }
        },
        embed: {
            video: {
                maxHeight: '400px',
            },
            figure: {
                justifyContent: 'center',
            },
            figcaption: {
                borderRadius: '5px',
                fontFamily: 'Open Sans',
                fontSize: '.875rem',
            }
        },
        paragraph: {
            textAlign: 'left',
            cursor: 'default',

            fontSize: '.875rem',
            // fontSize: '1.0rem',
            // color: 'red',   // BG20210314 - illustrate style override for EditorJS output
            fontFamily: 'Open Sans',
        },
        list: {
            textAlign: 'left',
        },
        checklist: {
            container: {},
            item: {},
            checkbox: {},
            label: {},
        },
        table: {
            table: {},
            tr: {},
            th: {},
            td: {},
        },
        quote: {
            container: {},
            content: {},
            author: {
                fontWeight: 'bold',
            },
            message: {
                textAlign: 'left',
            }
        },
        codeBox: {
            container: {
                width: '100%',
            },
            code: {
                boxSizing: 'border-box',
            },
        },
        warning: {
            container: {
                width: '100%',
            },
            icon: {
                width: '30px',
            },
            title: {
                fontWeight: 500
            },
            message: {
                fontSize: '14px'
            },
        },
        delimiter: {
            container: {},
            svg: {},
            path: {
                fill: '#231F20'
            }
        }
    };

// duplicate the EditorJS renderer theme for a special center-all override.
// parse(stringify) is a quick deep copy
theme.center_output = JSON.parse(JSON.stringify(theme.output));

theme.center_output.header.textAlign = 'center';
theme.center_output.paragraph.textAlign = 'center';

export default theme;
