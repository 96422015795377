import {types} from 'mobx-state-tree';

import {Api} from "../shared/stores/Api";
import Config from "../config/Config";
import axios from "axios";
import moment from "moment";
import {Alert} from "../shared/stores/Alert";

const delay = require('../shared/stores/Api').delay;

const debug = false;

export const Content = types
    .model('Content', {
        id: 0,
        name: types.optional(types.string, ''),
        module: types.optional(types.string, ''),
        version: types.optional(types.integer, 0),
        changedby: types.optional(types.string, ''),
        status: types.optional(types.string, ''),
        note: types.optional(types.string, ''),
        data: types.frozen({blocks: []}),
        updatedAt: types.optional(types.Date, new Date(1)),
        createdAt: types.optional(types.Date, new Date(1)),
        startdate: types.optional(types.Date, new Date(1)),
        enddate: types.optional(types.Date, new Date(1)),

        api: types.optional(Api, {}),

        alert: types.optional(Alert, {}),

    })
    .views( self => ({
    }))
.actions(self => ({
    setId(value) {
        self.id = value;
    },
    setName(value) {
        console.log('content.setName old: ' + self.name + ' new: ' + value);
        self.name = value;
    },
    setModule(value) {
        self.module = value;
    },
    setVersion(value) {
        if(value && value !== null) {
            self.version = value;
        }
        else {
            self.version = 0;
        }
    },
    setChangedby(value) {
        if(value && value !== null) {
            self.changedby = value;
        }
        else {
            self.changedby = '';
        }
    },
    setNote(value) {
        if(value && value !== null) {
            self.note = value;
        }
        else {
            self.note = '';
        }
    },
    setData(value) {
        self.data = value;
    },
    setUpdatedAt(value) {
        self.updatedAt = Date.parse(value);
    },
    setCreatedAt(value) {
        self.createdAt = Date.parse(value);
    },
    set(data) {
        // console.log('content.set assigning values for name: '+ self.name, data);
        self.setId(data.id);
        self.setName(data.name);
        self.setModule(data.mode);
        self.setVersion(data.version);
        self.setNote(data.note);
        self.setData(data.data);
        self.setUpdatedAt(data.updatedAt);
        self.setCreatedAt(data.createdAt);
    },
    load() {
        console.log('load content name: ' + self.name);
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/content/${self.name}`;
            console.log('contents url: ', url);
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .get(url,
                            {
                                headers,
                            })
                })
                .then(({data}) => {
                    debug && console.log('pubStore.contents. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    let result = data[0];
                    self.set(result);
                    self.api.success('200', 'contents complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('pubStore.contents.error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
    save() {
        console.log('save contents');
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/content/${self.name}`;
            console.log('contents url: ', url);
            let body = {
                content: {
                    id: self.id,
                    name: self.name,
                    mode: self.mode,
                    version: self.version,
                    changedby: self.changedby,
                    note: self.note,
                    data: self.data,
                    updatedAt: self.updatedAt,
                    createdAt: self.createdAt,
                },
            }
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .post(url, body, {headers})
                })
                .then(({data}) => {
                    debug && console.log('pubStore.contents. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    self.set(data);
                    self.api.success('200', 'contents complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('pubStore.contents.error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
}));
