import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import {inject, observer} from "mobx-react";
import Config from '../../config/Config';
import Footer from '../../pages/Footer';
import Header from "../../pages/Header";
import {Button, CircularProgress, Paper, TextField, useTheme} from "@material-ui/core";
import OffenderListItem from "./OffenderListItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {appBarSpace} from "../theme/Theme";


const drawerWidth = 240;
const debug = Config.enableConsoleDebug;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#445c7c',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
      marginLeft: drawerWidth,
    },
  //   // zIndex: theme.zIndex.drawer + 1,
  //   // transition: theme.transitions.create(['width', 'margin'], {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100%)`
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    // position: 'relative',
    // whiteSpace: 'nowrap',
    width: drawerWidth,

    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    // drawerPaperClose: {
  //   overflowX: 'hidden',
  //   // transition: theme.transitions.create('width', {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  //   width: theme.spacing(7),
  //   [theme.breakpoints.up('sm')]: {
  //     width: theme.spacing(9),
  //   },
  // },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingTop: theme.toolbar.height,
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  code: {
    textAlign: 'left',
    backgroundColor: 'white',
    width: '100%',
    whiteSpace: 'pre-wrap',
    wordBreak: "break-all",
  },
  printNever: {
    '@media print' : {
      // display: 'block',
      display: 'none',
    },
  },

}));

var counter = 0;

// export default function Dashboard() {
const OffenderDetails = ({pubStore, history, match}) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = React.useState(false);

  // console.log('OffenderDetails.pubStore: ', pubStore);
  let soid = match.params.soid;
  let isValidSoid = pubStore.isValidSoid(soid);
  debug && console.log('offenderDetails soid: ' + soid + ' isValidSoid: ' + isValidSoid + ' loadingOffender: ' + pubStore.loadingOffender + ' errorStatus: ' + pubStore.api.isErrorStatus);

  return (
    <div className={classes.root} style={{marginTop: appBarSpace(sm, pubStore.hasTBIAlert)}}>
      <CssBaseline />
      <Header history={history} title={'Offender ' + pubStore.detailsOffender.soid} />
        <main className={classes.content}>

        {/*<div className={classes.toolbar} />*/}

        <Container maxWidth="lg" className={classes.container}>
          {!pubStore.loadingOffender && !pubStore.detailsError && (
          <Grid container direction={'column'}>
            <Grid item>
              <OffenderListItem offender={pubStore.detailsOffender} pubStore={pubStore} history={history} enableActionButtons={true} key={'offenderdetails'+pubStore.detailsOffender.soid} />
            </Grid>
          </Grid>
          )}
          {pubStore.loadingOffender && <CircularProgress />}
          {pubStore.detailsError && (
              <>
              <Typography variant={'body1'} style={{color: Config.TN_RED, display: 'inline-block'}}>
                Invalid Sex Offender ID: {soid}
                <br />
                The offender cannot be loaded. Please tap the back button to select a different offender.
              </Typography>
              </>
            )}
            <Box pt={4} className={classes.printNever}>
            <Footer pubStore={pubStore} history={history}/>
          </Box>

        </Container>
      </main>
    </div>
  )
}
export default inject('pubStore')(observer(OffenderDetails));
