import {getParent, types} from 'mobx-state-tree';

import {Api} from "./Api";
import Config from "../../config/Config";
import axios from "axios";
import moment from "moment";
import {Alert} from "./Alert";
import qs from 'qs';
import {localizeOffender} from "../components/offenderutil";

const delay = require('./Api').delay;

const debug = Config.enableConsoleDebug;

const PUBSOR_SEARCHEDAT = 'PUBSOR_SEARCHEDAT';
const PUBSOR_SEARCH_PARAMS = 'PUBSOR_SEARCH_PARAMS';
const SEARCH_TTL = 60; // seconds before search is stale.

export const SearchStore = types
    .model('Search', {
        id: 0,
        _api: types.optional(Api, {}),

        _alert: types.optional(Alert, {}),
        enableRawChips: false,

        chips: types.array(types.string, []),

        hasSearched: false,
        searchedAt: types.optional(types.Date, new Date(1)),
        searchResults: types.frozen([]),
        searchCriteria: '',
        currentPage: 0,
        resultsPerPage: 10,
        searchHits: 0,
        searchDuration: 0, // ms
        excludeIncarcerated: true,  // false is same as Include Prisons/Incarcerated TRUE; // true is same as Include Prisons/Incarcerated FALSE
        orderBy: 'lastname',
        orderDirection: 'asc',
        byName: false,
        byAddress: false,
        county: '',
        classification: '',
        offenderstatus: '',

        dirty: false,

        // @todo - add dirty to show search has changed, and need to reset page #

        viewMode: 'table',

    })
    .views( self => ({
        get q() {
            let _terms = self.chips.join('|');
            return _terms;
        },
        get resultsStart() {
            // assumes currentPage set before resultsStart
            return (self.currentPage) * self.resultsPerPage;
        },
        get pages() {
            if(self.searchHits > 0) {
                return Math.ceil(self.searchHits /self.resultsPerPage);
            }
            else {
                return 0;
            }
        },
        get params() {
            const _params = {
                q: self.q,
                resultsStart: self.resultsStart, // calculated.
                currentPage: self.currentPage,  // used for localStorage save/restore
                resultsPerPage: self.resultsPerPage,
                excludeIncarcerated: self.excludeIncarcerated,
                orderBy: self.orderBy,
                orderDirection: self.orderDirection,
                byname: self.byName,
                byaddress: self.byAddress,
                county: self.county > '' ? self.county : undefined,
                classification: self.classification > '' ? self.classification : undefined,
                offenderstatus: self.offenderstatus > '' ? self.offenderstatus : undefined,
            }
            return _params;
        },
        get api() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 1);
            // console.log('Subscription. got parent', parent);
            return parent.api;
        },
        get alert() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 1);
            // console.log('Subscription. got parent', parent);
            return parent.alert;
        },
        get criteriaError() {
            if(self.chips.length > 5) {
                return 'Maximum number of search terms is 5';
            }
            else {
                return '';
            }
        },

    }))
.actions(self => ({
    setHasSearched(value) {
        self.hasSearched = value;
    },
    setSearchedAt(value) {
        self.searchedAt = value;
    },
    setSearchHits(value) {
        self.searchHits = value;
    },
    setSearchDuration(value) {
        self.searchDuration = value;
    },
    setSearchCriteria(value) {
        self.searchCriteria = value;
    },
    setDirty(value) {
        self.dirty = value;
    },
    setExcludeIncarcerated(value) {
        self.excludeIncarcerated = value;
    },
    setByName(value) {
        self.byName = value;
    },
    setByAddress(value) {
        self.byAddress = value;
    },
    setCounty(value) {
        self.county = value;
    },
    setClassification(value) {
        self.classification = value;
    },
    setOffenderStatus(value) {
        self.offenderstatus = value;
    },
    setResultsPerPage(value) {
        if(value >= 4) {
            self.resultsPerPage = value;
        }
    },
    searchUrl() {
        // @todo
        // q=terms
        // c=county
        // k=classification
        // s=status
        // x=exclude incarcerated
        // n= by name
        // a= by address
        // r=results per page
        // p=page
        // o=orderby
        // d=descending true|false

        const params = {
            q: self.q,
            resultsStart: self.resultsStart,
            resultsPerPage: self.resultsPerPage,
            searchMode: self.searchMode,
            excludeIncarcerated: self.excludeIncarcerated,
            orderBy: self.orderBy,
            orderDirection: self.orderDirection,
            byname: self.byName,
            byaddress: self.byAddress,
            county: self.county > '' ? self.county : undefined,
            classification: self.classification > '' ? self.classification : undefined,
            offenderstatus: self.offenderstatus > '' ? self.offenderstatus : undefined,
        }
        debug && console.log('params-serialized: ', qs.stringify(params, {arrayFormat: 'repeat'}));
        let url = `/search/${self.resultsStart}/${self.resultsPerPage}?${qs.stringify(params, {arrayFormat: 'repeat'})}`;
        debug && console.log('search-url: ', url);
        return url;
    },
    search() {
        if(self.dirty) {
            self.setCurrentPage(0);
        }
        self.setHasSearched(true);
        self.setSearchedAt(new Date());
        self.save();
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        // @todo
        // q=terms
        // c=county
        // k=classification
        // s=status
        // x=exclude incarcerated
        // n= by name
        // a= by address
        // r=results per page
        // p=page
        // o=orderby
        // d=descending true|false
        // const params = {
        //     q: self.q,
        //     resultsStart: self.resultsStart,
        //     resultsPerPage: self.resultsPerPage,
        //     excludeIncarcerated: self.excludeIncarcerated,
        //     orderBy: self.orderBy,
        //     orderDirection: self.orderDirection,
        //     byname: self.byName,
        //     byaddress: self.byAddress,
        //     county: self.county > '' ? self.county : undefined,
        //     classification: self.classification > '' ? self.classification : undefined,
        //     offenderstatus: self.offenderstatus > '' ? self.offenderstatus : undefined,
        // }
        const params = self.params;
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/search/${self.resultsStart}/${self.resultsPerPage}`;
            debug && console.log('search url: ', url, ' params: ', params);
            delay(250)
                .then(() => {
                    return axios
                        .get(url,
                            {headers,
                                params: {
                                    ...params
                                },
                                paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
                            })
                })
                .then(({data}) => {
                    debug && console.log('searchStore.search. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    // search results are returned from uspSearch as string
                    // so, we parse to JSON to operate
                    let searchresults = JSON.parse(data.searchresults);
                    // console.log('search results (parseJSON): ', searchresults);
                    if(!searchresults) {
                        searchresults = [];
                    }
                    searchresults.map(r => {
                        // allcontents in pubsor, contents in pubsoradmin
                        localizeOffender(r.offender, getParent(self, 1).allContents);
                    })
                    self.setSearchHits(data.hits);
                    self.setSearchDuration(data.duration);
                    // promote the offender element.
                    self.setSearchResults(searchresults);
                    self.setDirty(false);
                    self.api.success('200', 'search complete');
                    resolve(data);
                })
                .catch(error => {
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
    save() {
        localStorage.setItem(PUBSOR_SEARCHEDAT, self.searchedAt.toISOString());
        localStorage.setItem(PUBSOR_SEARCH_PARAMS, JSON.stringify(self.params));
        self.getSearchedAtFromLocalStorage();
    },
    restore() {
        // only restores params. does not restore searchedAt
        try {
            let _params = JSON.parse(localStorage.getItem(PUBSOR_SEARCH_PARAMS));
            debug && console.log('search. restore localStorage params: ', _params);
            if(_params) {
                self.clearChips();
                self.addChips(_params.q); // auto-magically splits on |
                self.setCounty(_params.county);
                self.setClassification(_params.classification);
                self.setOffenderStatus(_params.offenderstatus);
                self.setByName(_params.byname);  // note: case is important with params
                self.setByAddress(_params.byaddress);
                self.setExcludeIncarcerated(_params.excludeIncarcerated);
                self.setCurrentPage(_params.currentPage, _params.resultsPerPage); // use currentPage, resultsStart is computed
                self.setOrderBy(_params.orderBy, _params.orderDirection);
                // override dirty, which will reset the current page if search criteria changes.
                self.setDirty(false);
            }
        }
        catch {

        }
    },
    getSearchedAtFromLocalStorage() {
        try {
            let _searchedAt = localStorage.getItem(PUBSOR_SEARCHEDAT);
            debug && console.log('getSearchedAtFromLocalStorage _searchedAt: ', _searchedAt);
            let d = Date.parse(_searchedAt);
            if(!isNaN(d)) {
                return d;
            }
        }
        catch {
        }

        return new Date(1);
    },
    isSearchedAtFromLocalStorageStale() {
        let _searchedAt = self.getSearchedAtFromLocalStorage();
        let staleAt = moment(_searchedAt).add(SEARCH_TTL, 'seconds');
        let now = new Date();
        let stale = moment(new Date()).isAfter(staleAt);
        debug && console.log('isSearchedAtFromLocalStorageStale stale: ' + stale);
        return stale;
    },
    searchIfNotStale() {
        if(!self.isSearchedAtFromLocalStorageStale()) {
            self.restore();
            return self.search();
        }
        else {
            return Promise.resolve('ok');
        }
    },
    setSearchResults(value) {
        self.searchResults = value;
    },
    setCurrentPage(page, rowsPerPage) {
        self.currentPage = page < 0 ? 0 : page;
        if(rowsPerPage) {
            self.resultsPerPage = rowsPerPage;
        }
    },
    setOrderBy(orderBy, orderDirection) {
        self.orderBy = orderBy && orderBy.match(/name/i) ? 'lastname' : 'soid';
        self.orderDirection = orderDirection === 'asc' ? 'asc' : 'desc';

    },
    clearSearch() {
        self.clearChips();
        self.setCounty('');
        self.setClassification('');
        self.setOffenderStatus('');
        self.setByName(false);
        self.setByAddress(false);
        self.setExcludeIncarcerated(false);
        self.setCurrentPage(0);
        self.setSearchHits(0);
        self.setSearchDuration(0);
        self.setSearchResults([]);
        self.setSearchCriteria('');
        self.setDirty(false);
        self.save();
    },
    addChip(chip) {
        self.chips.push(chip);
        self.setDirty(true);
    },
    addChips(chip) {
        let _chip = chip.trim();
        if(self.enableRawChips) {
            self.addChip(_chip);
        }
        else {
            // issue R59-59F holds hyphen, but is license plate; should not
            // 411-47, 411-47-6928 should hold hyphen (numbers only)
            let _chips = _chip.replace(/([ ,":\.!$?%^&\*_+=}{\[\]\\><`)(/;@|])+/g, ' ').split(' ');
            _chips.map((_chip) => {
                if (_chip !== '') {
                    self.addChip(_chip);
                }
            })
        }
    },
    deleteChip(chip, index) {
        if(index > -1) {
            self.chips.splice(index,1);
            self.setDirty(true);
            // 20210814BG - do not automatically re-perform the search as terms are removed.
            // self.search();
        }
    },
    clearChips() {
        self.chips = [];
        self.setDirty(true);
    },


}));
