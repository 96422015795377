import Config from "../../config/Config";
const moment = require('moment');

const debug = false;

const default_content = {
    data: {
        blocks: [
            {
                "data": {
                    "text": "Definition not available."
                },
                "type": "paragraph"
            },

        ]
    }
}
/**
 * Utility routine to attach information to the offender object.
 * 1. Adapt the offender image url so that it can be accessed
 * 2. Attach TCA code definitions to the offender offenses. These are not saved in the DB to avoid repeated transfer, and to be sensitive to different publishing cycles.
 * 3. @todo - set mostwanted flags (check TOP10)
 * 4. @todo - set map URLs
 * @param offender - standard offender JSON from server
 * @param allContents - from pubStore. allContents.PUBLIC has content and rendering blocks for site,
 * allContents.TCACODE has all the defined codes.
 * allContents should be 'as-is' from the server.
 * @returns {{sorimage}|*}
 */
export const localizeOffender = (offender, allContents) => {

    debug && console.log('@@@@@@@@@ localizing offender: ', offender);
    if(offender) {
        if (offender.sorimage && offender.sorimage.url) {
            if (!offender.sorimage.url.startsWith('http')) {
                offender.sorimage.url = `${Config.PUBSOR_BASE}${offender.sorimage.url}`;
            }
        }

        if(allContents && Array.isArray(allContents.TOP10)) {
            debug && console.log('@@@@@@@@@ localizing offender CHECKING TOP10. soid: ', offender.soid);
            let content = allContents.TOP10.find(c => c.name === offender.soid);
            if(content) {
                debug && console.log('@@@@@@@@@ localizing offender setting top10.', content);
                offender.istop10 = true;
                offender.top10note = content.note;

            }
        }

        if(allContents && Array.isArray(allContents.CLASSIFICATION)) {
            debug && console.log('@@@@@@@@@ localizing offender adding classification defintion.');
            if(offender.secth && offender.secth.classification) {
                let definition = allContents.CLASSIFICATION.find(c => c.name === offender.secth.classification);
                if(definition) {
                    debug && console.log('@@@@@@@@@ localizing offender adding classification MATCH.', definition);
                    offender.secth.definition = definition;
                }
            }
        }
        if(allContents && Array.isArray(allContents.TCACODE)) {
            if(offender.sectf && offender.sectf.offenses) {
                offender.sectf.offenses.map(offense => {
                    let tcacode = offense.tcacode;
                    debug && console.log('@@@@@@@@@ localizing offender tcacode: ', tcacode);
                    if(tcacode === 'NOT AVAILABLE') {
                        offense.definition = default_content;
                    }
                    else {
                        let definitions = allContents.TCACODE.filter(c => c.name === tcacode);
                        debug && console.log('@@@@@@@@@ localizing offender tcacode: ', tcacode, ' found definitions: ', definitions);
                        if(definitions.length === 0) {
                            offense.definition = default_content;
                        }
                        else if(definitions.length === 1) {
                            offense.definition = definitions[0];
                        }
                        else {
                            if(offense.offensedate) {
                                definitions.map(definition => {
                                    debug && console.log('localizeOffender. matching offense to definition offense: ', offense, 'definition: ', definition);
                                    debug && console.log('localizeOffender. matching offense. startdate: ', moment(definition.startdate).utc());
                                    let startdate = definition.startdate ? moment(definition.startdate).utc(false) : undefined;
                                    debug && console.log('startdate: ', startdate && startdate.format('L'));
                                    let enddate = definition.enddate ? moment(definition.enddate).utc(false) : undefined;
                                    debug && console.log('enddate: ', enddate && enddate.format('L'));
                                    let offensedate = moment(offense.offensedate).utc(false);
                                    debug && console.log('offensedate: ', offensedate.format('L'));
                                    let isBetween = moment(offensedate).isBetween(startdate, enddate, 'day', '[]');
                                    debug && console.log('isBetween?' + isBetween, 'is ', offensedate, ' between ', startdate, ' and ', enddate);
                                    if(isBetween) {
                                        offense.definition = definition;
                                    }
                                })
                            }
                            // if no match, then we merge all the definitions.
                            if(!offense.definition) {
                                offense.definition = {data: {blocks: []}};
                                definitions.map(definition => {
                                    debug && console.log('appending definitions');
                                    offense.definition.data.blocks = offense.definition.data.blocks.concat(definition.data.blocks);
                                })
                            }

                        }
                    }

                })
            }
        }
    }
    return offender;

}
























































































































































































































































































