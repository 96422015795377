/*
20200503BG - #202 - Clean up unused AsyncStorage imports
 */

import {types} from 'mobx-state-tree';
// import moment from 'moment';

const debug = false;

export const delay = t => new Promise(resolve => setTimeout(resolve, (t < 100 ? 1000 : t)));

/*
 Universal API-STATUS indicator. Used (@todo) for Promo, UserStore(tbd), etc.
 */
/*
 take an error string, parse for know error codes (400, 401, etc)
 return the error with the .code attribute set.
 */
function errorParser(error) {
    debug && console.log('errorParser error: ' + error);
    if (!error) {
        let _error = new Error('Unknown');
        _error.code = '000';
        return _error;
    }

    let message = error.message ? error.message : error;
    let n = message.match(/network/i);
    if (n) {
        debug && console.log('errorParser error match network: ' + n[0]);
        error.code = '503';
    }
    let c = message.match(/code (4..)/);
    if (c) {
        debug && console.log('errorParser error match: ' + c[0] + ' code: ' + c[1]);
        if (c[1]) {
            error.code = '' + c[1];
        } else {
            error.code = '401';
        }
    }
    return error;
}

export const Api = types
    .model ('Api', {
        // @observable
        statusCode: types.optional(types.string, ''),
        status: types.optional(types.string, ''),
        // timestamp when status updated.
        statusAt: types.optional(types.Date, new Date(1)),
        // universal - means logging in or logging out.
        isWorking: false,
        t0: 0,
        t1: 0,
    })
    .views(self => ({
        get isErrorStatus() {
             return self.statusCode >= '400';
        },
        get duration() {
            let d = self.t1 - self.t0;
            if(d < 0) {
                return 0;
            }
            return d;
        }

    }))
    .actions( self => ({

        // StatusCodes
        // 000 == ok | unknown
        // 200 == success
        // 4** == some kind of error
        // 400 == bad request
        // 401 == unauthorized
        // 403 == forbidden (invalid credentials)
        // 404 == not found (has network connection, but cannot reach server)
        // 406 -- not acceptable. (invalid data / failed validation on server)
        // 408 == request timeout
        // 409 == conflict (ie. duplicate account for sign-up)
        // 412 == PW-Expired -- PRECONDIITION FAILED (HTTP)
        // 423 == LOCKED
        // 503 == service unavailable/ network error
        // setError standardizes the message for various codes
        setError(error) {
            error = errorParser(error);
            let _code = error.code | '400';
            console.log('Api.setError ERROR error: ' + error + ' code: ' + error.code + ' CODE: ' + _code);
            if(_code === '401') {
                self.setStatus('401', 'Failed token check.');
            }
            else if(_code === '404') {
                self.setStatus('404', 'Unable to reach server. Please try again later.');
            }
            else if(_code === '403') {
                self.setStatus('403', 'Invalid credentials. The username/password combination that you have entered does not exist. Please try again.');
            }
            else if(_code === '409') {
                self.setStatus('409', 'Username not available. The username you have requested is either in use or invalid. Please try a different username.')
            }
            else if(_code === '503') {
                self.setStatus('503', 'Network error. Please check your Internet connection and try again.');
            }
            else {
                self.setStatus(_code, error.message);
            }
            self.statusAt = new Date();
        },
        setStatus(statusCode, status) {
            self.statusCode = ''+statusCode;
            self.status = status;
            self.statusAt = new Date();
        },
        resetStatus() {
            self.statusCode = '000';
            self.status = 'Ok';
            self.statusAt = new Date(1);
        },
        /*
            set working indicator (to be used to display activity indicator for
            any log-in action.
            hides any alerts that may be lingering from prior error
         */
        setIsWorking(value) {
            self.isWorking = value;
            if(self.isWorking) {
                self.t0 = new Date().getTime();
                // RenderUtil.hideAlert();
            }
            else {
                self.t1 = new Date().getTime();
            }
        },
        start() {
            // used for start of transaction
            self.resetStatus();
            self.setIsWorking(true);
        },
        success(statusCode, status) {
            // used for successful completion of transaction
            self.setStatus(statusCode, status);
            self.setIsWorking(false);
        },
        error(error) {
            // used to mark completion of unsuccessful transaction
            self.setError(error);
            self.setIsWorking(false);
        },
        // formatStatusAt(now) {
        //     debug && console.log('api.formatStatusAt now: ' + now.format('LL LTS'));
        //     debug && console.log('api.formatStatusAt: ' + self.statusAt);
        //     let seconds = now.diff(self.statusAt, 'seconds');
        //     let years = now.diff(self.statusAt, 'years');
        //     debug && console.log('api.diff years: ' + years + ' seconds: ' + seconds);
        //     if(seconds < 9) {
        //         return 'just now';
        //     }
        //     if(years > 2) {
        //         return 'never';
        //     }
        //     return moment(self.statusAt).from(now);
        // },

    }));


