const moment = require("moment");

// const offenderlabels = require('../data/offender-labels.json').offenderlabels;

module.exports = {

    formatAddress: (obj) => {
        if (!obj) {
            return '';
        }
        let s = '';
        if (obj.street) {
            s += obj.street;
        }
        if (obj.city || obj.citylist) {
            if (s > '') {
                s += ', ';
            }
            s += obj.city || obj.citylist;
        }
        if (obj.state) {
            if (s > '') {
                s += ', ';
            }
            s += obj.state;
        }
        if (obj.zip) {
            if (s > '') {
                s += ' ';
            }
            s += obj.zip;
        }
        if (obj.zipcode) {
            if (s > '') {
                s += ' ';
            }
            s += obj.zipcode;
        }
        if (obj.county) {
            if (s > '') {
                s += ' ';
            }
            s += `(${obj.county} County)`;
        }
        return s;
    },

    formatBirthplace: (o) => {
        if (!o || !o.secta) {
            return '';
        }
        let s = '';
        if (o.secta.birthcity) {
            s += o.secta.birthcity;
        }
        if (o.secta.birthstate) {
            if (s > '') {
                s += ', ';
            }
            s += o.secta.birthstate;
        }
        if (o.secta.birthplace) {
            if (s > '') {
                s += ', ';
            }
            s += o.secta.birthplace;
        }
        return s;
    },

    formatDL: (o) => {
        if (!o || !o.secta) {
            return '';
        }
        let s = '';
        if (o.secta.dl) {
            s += o.secta.dl;
        }
        if (o.secta.state) {
            if (s > '') {
                s += ', ';
            }
            s += o.secta.state;
        }
        if (o.secta.expyear) {
            if (s > '') {
                s += ', ';
            }
            s += 'expires: ' + o.secta.expyear;
        }
        return s;
    },

    format: (field, value) => {
        // console.log('format', field, value);
        if (!field) {
            return value;
        }
        if (value === '?') {
            return value;
        }
        if (field.endsWith('date') || field.endsWith('dob') || field.match(/date/)) {
            if (moment(value).isValid()) {
                return moment(value).utc().format('L');
            }
        }
        return value;
    },

    // label: (field) => {
    //     let l = offenderlabels[field];
    //     if(l) {
    //         return l;
    //     }
    //     let _field = field.replace(/\.[0-9]+\./, '.0.').replace(/\.[0-9]+$/, '.0');
    //
    //     console.log('label fix', _field);
    //     l = offenderlabels[_field];
    //     return l || field;
    // }
};
