import {types, getParent} from 'mobx-state-tree';

import {Api} from "../shared/stores/Api";
import Config from "../config/Config";
import axios from "axios";
import moment from "moment";
import {Alert} from "../shared/stores/Alert";
import {Subscription} from "./Subscription";

const localizeOffender = require('../shared/components/offenderutil').localizeOffender;

const delay = require('../shared/stores/Api').delay;

const debug = Config.enableConsoleDebug;

const MAX_SOIDSUBS = 10;
const MAX_ZIPSUBS = 5;

export const SubStore = types
    .model('SubStore', {

        soidsubs: types.array(Subscription, []),
        zipsubs: types.array(Subscription, []),
        // selected Offender (ie, from a search)
        offender: types.frozen({}),

        id: 0,
        email: types.optional(types.string, ''),
        zipcode: '',
        soid: '',

        // working sub
        sub: types.optional(Subscription, {}),
        // api: types.optional(Api, {}),

        _alert: types.optional(Alert, {}),

    })
    .views( self => ({
        get isValidEmail() {
            return self.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        get soiddata() {
            return self.soidsubs.map(s => {
                let d = s;
                d.soid = s.offender.soid;
                d.offname = s.offender.secta.offname;
                return d;
            })
        },
        get zipdata() {
            return self.zipsubs.map(s => {
                let d = s;
                return d;
            })
        },
        get api() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 1);
            // console.log('Subscription. got parent', parent);
            return parent.api;
        },
        get alert() {
            // 'trick' to use UserStore api for reporting isWorking, status flags
            let parent = getParent(self, 1);
            // console.log('Subscription. got parent', parent);
            return parent.alert;
        },

    }))
.actions(self => ({
    newSubscription(api, sub) {
        let subscription = Subscription.create({});
        subscription.setApi(api);
        subscription.set(sub);
        return subscription;
    },
    setId(value) {
        self.id = value;
    },
    setEmail(value) {
        self.email = value;
    },
    setOffender(value) {
        self.offender = value;
    },
    setZipcode(value) {
        self.zipcode = value;
    },
    setSoid(value) {
        self.soid = value;
    },
    isSubscribed(soid) {
        let match = self.soidsubs.find(s => s.key === soid);
        let isMatch = match ? true : false;
        return isMatch;
    },
    clear() {
        self.soidsubs = [];
        self.zipsubs = [];
    },
    add(sub) {
        debug && console.log('content.add '+ sub.id + ' mode: ' + sub.mode);
        if(sub.mode === 'zip') {
            self.zipsubs.push(sub);
        }
        else {  // soid
            self.soidsubs.push(sub);
        }
        debug && console.log('content.add new subs length: ', self.soidsubs.length + ' zips length: ' + self.zipsubs.length);
    },
    load() {
        self.clear();
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/subscribes/${self.email}`;
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .get(url,
                            {
                                headers,
                            })
                })
                .then(({data}) => {
                    debug && console.log('pubStore.sub. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    let searchresults = JSON.parse(data.searchresults);

                    if(searchresults.length > 0) {
                        searchresults.map(s => {
                           if(s.offender) {
                               // move actual offender up
                               let _offender = s.offender.offender;
                               s.offender = localizeOffender(_offender, getParent(self, 1).allContents);
                               debug && console.log('substore -> s.offender.sorimage: ', s.offender.sorimage);
                           }
                           let sub = Subscription.create({});
                           sub.set(s);
                           self.add(sub);
                       });
                   }
                   else {
                       self.clear();
                   }
                    self.api.success('200', 'load subs complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('pubStore.substore.load.error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
    save() {
        // not used for list/save
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/sub`;
            let body = {
                content: {
                    id: self.id,
                    email: self.email,
                    key: self.key,
                    mode: self.mode,
                    status: self.status,
                    updatedAt: self.updatedAt,
                    createdAt: self.createdAt,
                },
            }
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .post(url, body, {headers})
                })
                .then(({data}) => {
                    debug && console.log('pubStore.subscribe. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    self.set(data);
                    self.api.success('200', 'subscribe complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('pubStore.subscribe.error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
    removeAll() {
        // not used - see Subscription

        debug && console.log('remove All sub ', self.email);
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/subscribe/${self.email}/0`;
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .delete(url, {
                            headers,
                        })
                })
                .then(({data}) => {
                    debug && console.log('subStore.removeAll. success return data: ' + JSON.stringify(data, null, 4));
                    // console.log(data);
                    // application needs to call *load* after save to refresh the list.
                    self.api.success('200', 'removeAll complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('subStore.removeAll error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
}));
