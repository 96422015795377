import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {get} from '../util/json-util';
import CardContent from "@material-ui/core/CardContent";
import {Collapse, Tooltip} from "@material-ui/core";
import {v4 as uuidv4} from 'uuid';
import Box from "@material-ui/core/Box";
import Config from "../../config/Config";
import Output from '../editorjs-renderer';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const formats = require('../util/format-utils');

const debug = false;

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 400,
        marginBottom: '12px',
    },
    media: {
        height: 160,
        // paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    heading: {
        marginTop: 8,
    },
    printNever: {
        '@media print' : {
            // display: 'block',
            display: 'none',
        },
    },
    offenseRow: {
        '& > *': {
            borderBottom: 'unset',
        },
    },

}));


const ClassificationTip = (props) => {
    let offender = props.offender;

    if(offender.secth && offender.secth.definition && offender.secth.definition.data && offender.secth.definition.data.blocks.length > 0) {
        return (
            <Box style={{backgroundColor: Config.CONTENT_BACKGROUND, paddingLeft: 16, paddingRight: 16}}>
                <Output data={offender.secth.definition.data} style={props.theme.output} />
            </Box>
        );
    }
    return (<Typography>{'Classification definition not available.'}</Typography>)
}

// @todo - move to localizeOffender.
// offense.tcacodedefinition [data blocks]
const TCACodeTip = (props) => {
    let offense = props.offense;
    let tcacode = offense.tcacode;
    // tcacode = '39-13-506';

    if(offense.definition && offense.definition.data && offense.definition.data.blocks.length > 0) {
        return (
            <Box style={{backgroundColor: Config.CONTENT_BACKGROUND, paddingLeft: 16, paddingRight: 16}}>
                <Output data={offense.definition.data} style={props.theme.output} />
            </Box>
        );
    }
    return (<Typography>{'Definition for ' + props.offense.tcacode} is not available.</Typography>)
}




export const identityComponent = (offender) => {
    let birth = formats.formatBirthplace(offender);
    let dl = formats.formatDL(offender);

    return (
        <Paper variant={'outlined'} style={{margin: '6px'}}>
            {offender.secta &&
            <>
                {birth > '' &&
                <Typography variant={'body2'} color={'textSecondary'}>
                    {'birthplace: ' + birth}
                </Typography>
                }
                {dl > '' &&
                <Typography variant={'body2'} color={'textSecondary'}>
                    {'driver license: ' + dl}
                </Typography>
                }
            </>
            }
        </Paper>
    );
};


export const OffenseComponent = (offense) => {
    let offenseDescription = offense.offense;
    if (offense.offense && offense.offense.length > 10) {
        offenseDescription = offense.offense.substr(10).trim();
    }
    // missing victims
    return (
        <Paper variant={'outlined'} style={{margin: '6px'}} key={uuidv4()}>
            <Tooltip title={`Definition of ${offenseDescription}`}>
                <Typography variant={'body2'} color={'textSecondary'}>
                    {offense.offense}
                </Typography>
            </Tooltip>
        </Paper>


    );
};

export const HeadCell = withStyles((theme) => ({
    head: {
        backgroundColor: Config.TN_BLUE,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const OffenseRow = (props) => {
    let classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [tooltip, setTooltip] = React.useState(false);
    let offense = props.offense;
    return (
        <>
            <TableRow key={uuidv4()} className={classes.offenseRow}>
                <TableCell>
                    {offense.offensedatestr || '--'}
                </TableCell>
                <TableCell>
                    {offense.description || '--'}
                </TableCell>
                <TableCell>
                    <Grid container direction={'row'} alignItems={'center'}>
                        <Typography style={{marginLeft: '6px'}}>
                            {offense.tcacode}
                        </Typography>
                        {offense.tcacode !== 'NOT AVAILABLE' && <Tooltip open={tooltip} title={
                            <TCACodeTip offense={offense} pubStore={props.pubStore} theme={props.theme}/>
                        }
                        >
                            <HelpOutline className={classes.printNever} style={{marginLeft: 8, fontSize: 18}}
                                         onClick={(event) => {
                                             setOpen(!open);
                                             setTooltip(false);
                                         }}
                                         onMouseEnter={(event) => {
                                             setTooltip(!open);
                                             if (!open) {
                                                 setTimeout(() => setTooltip(false), Config.TOOLTIP_TTL);
                                             }
                                         }}
                                         onMouseLeave={(event) => {
                                             setTooltip(false);
                                         }}
                            />
                        </Tooltip>}
                    </Grid>

                </TableCell>
            </TableRow>
            <TableRow key={uuidv4()} >
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={3}>
                    <Collapse in={open}>
                        <TCACodeTip offense={offense} pubStore={props.pubStore} theme={props.theme}/>

                    </Collapse>
                </TableCell>

            </TableRow>
        </>
    );
}
export const OffensesComponent = (props) => {
    let classes = useStyles();
    let offender = props.offender;

    return (
        <>
            {offender.sectf && Array.isArray(offender.sectf.offenses) ?
                (
                    <TableContainer style={{pageBreakInside: 'avoid'}}>
                        <Typography variant={'h3'} style={{marginTop: 8}}>
                            OFFENSES
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Paper variant={'outlined'} style={{margin: 6}}>
                            <Table size={'small'}>
                                <TableHead >
                                    {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                                    <TableRow >
                                        <HeadCell >
                                            DATE OF OFFENSE
                                        </HeadCell>
                                        <HeadCell >
                                            DESCRIPTION
                                        </HeadCell>
                                        <HeadCell >
                                            TCA CODE
                                        </HeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {offender.sectf.offenses.map(offense => {
                                        return (
                                            <OffenseRow offense={offense} pubStore={props.pubStore} theme={props.theme} key={uuidv4()} />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </TableContainer>
                ) :
                (
                    <Typography variant={'body2'} style={{color: 'red'}}>
                        OFFENSES NOT AVAILABLE
                    </Typography>

                )
            }
        </>
    );
};

export const Employer = (employer) => {
    return (
        <Paper variant={'outlined'} style={{margin: '6px'}} key={uuidv4()}>
            <Typography variant={'body2'} color={'textSecondary'}>
                EMPLOYER
            </Typography>
            {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
            <Typography variant={'body2'} color={'inherit'}>
                {' ' + formats.formatAddress(employer)}
            </Typography>
        </Paper>
    );
};

export const SchoolComponent = (school) => {
    if (!school) {
        return (<></>);
    }
    return (
        <Paper variant={'outlined'} style={{margin: '6px'}}>
            <Typography variant="caption" color="textSecondary">
                {school.type && school.type.toLowerCase()}
            </Typography>
            <Typography variant={'body2'} color={'textSecondary'}>
                {school.universitylist}
            </Typography>
            <Typography variant={'body2'} color={'inherit'}>
                {' ' + formats.formatAddress(school)}
            </Typography>
            <Typography variant={'body2'} color={'inherit'}>
                {school.startdate && ' Start date: '} {school.startdate && new moment(school.startdate).utc().format('L')}
            </Typography>
            {school.agency &&
            <Typography variant={'body2'} color={'inherit'}>
                {school.agency && ' Agency: '} {school.agency}
            </Typography>
            }
        </Paper>
    );
};

export const ParoleComponent = (parole) => {
    if(parole && parole.name) {
        return (
            <Paper variant={'outlined'} style={{margin: '6px'}}>
                <Typography variant={'body2'} color={'textSecondary'}>
                    PAROLE OFFICER
                </Typography>
                {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                <Typography variant={'body2'} color={'inherit'}>
                    {parole.name}
                </Typography>
            </Paper>
        );
    }
    else {
        return (<></>);
    }
}

export const RegisteringAgencyComponent = (offender) => {
    if(offender.sectb && offender.sectb.primary && offender.sectb.primary.agency) {
        return (
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            REGISTERING AGENCY
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Typography variant={'body2'} color={'inherit'}>
                            {offender.sectb.primary.agency}
                        </Typography>
                    </Paper>

        );
    }
    else {
        return (<></>);
    }
};

export const VehicleComponent = (vehicle) => {
    return (
        <Paper variant={'outlined'} style={{margin: '6px', pageBreakInside: 'avoid'}} key={uuidv4()}>
            <Typography variant={'body2'} color={'textSecondary'}>
                VEHICLE
            </Typography>
            {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
            <Typography variant={'body2'} color={'inherit'}>
                {'' + vehicle.vehyear + ' ' + vehicle.color + ' ' + vehicle.make + ' ' + vehicle.model + ' (' + vehicle.style + ') '}

            </Typography>
            <Typography variant={'body2'} color={'inherit'}>
                {vehicle.lic > '' ? ' TAG: ' + vehicle.lic : ''}
                {vehicle.state > '' ? ', ' + vehicle.state : ''}
            </Typography>
        </Paper>
    );
};

export const AddressComponent = (type, address) => {
    return (
        <Paper variant={'outlined'} style={{margin: '6px'}}>
            <Typography variant={'body2'} color={type === 'primary' ? 'textPrimary' : 'textSecondary'} style={{textTransform:'uppercase'}}>
                {type}
                {address.name > '' ? ' ' + address.name : ''}
                {address.relationship > '' ? ' (' + address.relationship + ')' : ''}
            </Typography>
            <Typography variant={'body2'} color={'inherit'}>
                {formats.formatAddress(address)}
            </Typography>

            {address.phone && (
                <Typography variant="body2" color="textPrimary">
                    PHONE: {address.phone}
                </Typography>
            )}
            {address.agency && (
                <Typography variant="body2" color="textPrimary">
                    Agency: {address.agency}
                </Typography>
            )}
        </Paper>
    );
};

export const Demographics = (offender) => {
    let birth = formats.formatBirthplace(offender);
    let dl = formats.formatDL(offender);

    return (
        <div style={{pageBreakInside: 'avoid'}}>
            <Typography variant={'h3'}>
            DEMOGRAPHICS
            </Typography>
            {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
            <Grid container direction={'row'} spacing={0}>

                {offender.secta.hair && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        HAIR
                    </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                    <Typography variant={'body2'} color={'inherit'}>
                        {offender.secta.hair}
                    </Typography>
                    </Paper>
                </Grid>
                }
                {offender.secta.eye && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        EYES
                    </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                    <Typography variant={'body2'} color={'inherit'}>
                        {offender.secta.eye}
                    </Typography>
                    </Paper>
                </Grid>
                }
                {offender.secta.sex && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            GENDER
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Typography variant={'body2'} color={'inherit'}>
                            {offender.secta.sex}
                        </Typography>
                    </Paper>
                </Grid>
                }
                {offender.secta.race && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            RACE
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Typography variant={'body2'} color={'inherit'}>
                            {offender.secta.race}
                        </Typography>
                    </Paper>
                </Grid>
                }
                {offender.secta.height && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            HEIGHT
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Typography variant={'body2'} color={'inherit'}>
                            {offender.secta.height}
                        </Typography>
                    </Paper>
                </Grid>
                }
                {offender.secta.weight && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            WEIGHT
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Typography variant={'body2'} color={'inherit'}>
                            {offender.secta.weight}
                        </Typography>
                    </Paper>
                </Grid>
                }
                {offender.secta.dob && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            DOB
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Typography variant={'body2'} color={'inherit'}>
                            {offender.secta.dob}
                        </Typography>
                    </Paper>
                </Grid>
                }
                {dl && <Grid item xs={6}>
                    <Paper variant={'outlined'} style={{margin: '6px'}}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            DRIVER'S LICENSE
                        </Typography>
                        {/*BV UPPERCASE PER JOSH DEVINE 7/29/21*/}
                        <Typography variant={'body2'} color={'inherit'}>
                            {dl}
                        </Typography>
                    </Paper>
                </Grid>
                }

            </Grid>
        </div>

    );
};
export const ClassificationComponent = (props) => {
    let classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [tooltip, setTooltip] = React.useState(false);
    let offender = props.offender;

    return (
                <Grid container direction={'column'} style={{marginTop: 8}}>
                    <Typography variant={'h3'}>
                        STATUS
                    </Typography>
                    {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                    {/*<Paper variant={'outlined'} style={{margin: '6px'}} >*/}
                    {/*<Typography variant={'body2'} color={'textSecondary'}>*/}
                    {/*    CLASSIFICATION*/}
                    {/*</Typography>*/}
                    {/*    */}{/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                    {/*<Grid container direction={'row'} alignItems={'center'}>*/}
                    {/*    <Typography style={{marginLeft: '6px'}}>*/}
                    {/*        {offender.secth && offender.secth.classification}*/}
                    {/*    </Typography>*/}
                    {/*    <Tooltip open={tooltip} title={*/}
                    {/*        <ClassificationTip offender={offender} pubStore={props.pubStore} theme={props.theme}/>*/}
                    {/*    }*/}
                    {/*    >*/}
                    {/*    <HelpOutline className={classes.printNever} style={{ marginLeft: 8, fontSize: 18 }}*/}
                    {/*                 onClick={(event) => {*/}
                    {/*                     setOpen(!open);*/}
                    {/*                     setTooltip(false);*/}
                    {/*                 }}*/}
                    {/*                 onMouseEnter={(event) => {*/}
                    {/*                     setTooltip(!open);*/}
                    {/*                     if(!open) {*/}
                    {/*                         setTimeout(() => setTooltip(false), Config.TOOLTIP_TTL);*/}
                    {/*                     }*/}
                    {/*                 }}*/}
                    {/*                 onMouseLeave={(event) => {*/}
                    {/*                     setTooltip(false);*/}
                    {/*                 }}*/}

                    {/*    />*/}
                    {/*    </Tooltip>*/}
                    {/*</Grid>*/}
                    {/*    <Collapse in={open}>*/}
                    {/*        <ClassificationTip offender={offender} pubStore={props.pubStore} theme={props.theme}/>*/}
                    {/*    </Collapse>*/}
                    {/*</Paper>*/}
                    <Paper variant={'outlined'} style={{marginLeft: '6px', marginRight: '6px'}} >
                        <Typography variant={'body2'} color={'textSecondary'}>
                            STATUS
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Typography style={{marginLeft: '6px'}}>
                    {offender.secth && offender.secth.status.split(':')[1]}
                </Typography>
                    </Paper>
                </Grid>
    );
};
export const smtComponent = (offender) => {
    return (
        <>
            {Array.isArray(offender.secta.tattoos) && offender.secta.tattoos.length > 0 && (
                <Paper variant={'outlined'} style={{margin: '6px'}}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        TATTOOS
                    </Typography>
                    {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                    <Typography variant="body2" color="inherit">
                        {offender.secta.tattoos.join('; ')}
                    </Typography>
                </Paper>
            )}
            {Array.isArray(offender.secta.smts) && offender.secta.smts.length > 0 && (
                <Paper variant={'outlined'} style={{margin: '6px'}}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        SCARS-MARKS
                    </Typography>
                    {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                    <Typography variant="body2" color="textSecondary">
                        {offender.secta.smts.join('; ')}
                    </Typography>
                </Paper>
            )}
        </>
    );
};

export const AliasComponent = (offender, noWrap) => {
    return (
        <>
            {Array.isArray(offender.secta.aliasnames) && offender.secta.aliasnames.length > 0 && (
                <>
                    {noWrap ? (
                        <>
                            <Typography variant={'subtitle2'} color={'textPrimary'} style={{marginLeft: 4}}>
                                ALIASES
                            </Typography>
                            <Typography variant="caption" color="textPrimary" style={{marginLeft: 14}} noWrap>
                                {offender.secta.aliasnames.join('; ')}
                            </Typography>
                        </>
                    ) : (
                        <Paper variant={'outlined'} style={{margin: '6px'}}>
                            <Typography variant={'body2'} color={'textSecondary'}>
                                ALIASES
                            </Typography>
                            {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                            <Typography variant="body2" color="textSecondary">
                                {offender.secta.aliasnames.join('; ')}
                            </Typography>
                        </Paper>

                    )}
                </>
            )}

        </>
    );
};

export const ReportDates = (offender) => {
    return (
        <>
            <Paper variant={'outlined'} style={{margin: 6}}>

                <Typography variant={'body2'} color={'textSecondary'}>
                    LAST REGISTRATION/REPORT DATE
                </Typography>
                {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                {offender.offenderstatus.lastreportdate ? (
                        <Typography variant={'body2'}>
                            {new moment(offender.offenderstatus.lastreportdate).utc().format('L')} ({moment(offender.offenderstatus.lastreportdate).from(new Date())})
                        </Typography>
                    ) :
                    (
                        <Typography variant={'body2'}>
                            Last Registration/Report Date Not Available.
                        </Typography>
                    )
                }
            </Paper>

            <Paper variant={'outlined'} style={{margin: 6}}>

                <Typography variant={'body2'} color={'textSecondary'}>
                    LAST DATE INFORMATION UPDATED
                </Typography>
                {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                {offender.offenderstatus.lastupdatedate ? (
                        <Typography variant={'body2'}>
                            {new moment(offender.offenderstatus.lastupdatedate).utc().format('L')} ({moment(offender.offenderstatus.lastupdatedate).from(new Date())})
                        </Typography>
                    ) :
                    (
                        <Typography variant={'body2'} color={'error'}>
                            Last Updated Date Not Available.
                        </Typography>
                    )
                }
            </Paper>
        </>
    );
}

export const CriminalHistory = (offender) => {
    return (
        <>
                {offender.sectk && Array.isArray(offender.sectk.cch) ?
                    (
                        <TableContainer style={{pageBreakInside: 'avoid'}}>
                            <Typography variant={'h3'} style={{marginTop: 8}}>
                                CRIMINAL HISTORY
                        </Typography>
                        {/*<Typography variant={'body2'} style={{ color: 'red' }}>*/}
                        {/*    CRIMINAL HISTORY TEMPORARILY UNAVAILABLE*/}
                        {/*</Typography>*/}
                            {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                            <Paper variant={'outlined'} style={{margin: 6}}>
                        <Table size={'small'}>
                            <TableHead  >
                            <TableRow >
                                <HeadCell>
                                    DATE
                                </HeadCell>
                                <HeadCell >
                                    CHARGE
                                </HeadCell>
                                <HeadCell >
                                    DISPOSITION
                                </HeadCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {offender.sectk.cch.map(arrest => {
                                return (
                                    <TableRow key={uuidv4()}>
                                        <TableCell >
                                            {arrest.arrestdate || '--'}
                                        </TableCell>
                                        <TableCell>
                                            {arrest.charge || '--'}
                                        </TableCell>
                                        <TableCell>
                                            {arrest.disposition}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                            </Paper>
                        </TableContainer>
                    ) :
                    (
                        <Typography variant={'body2'} style={{color: 'red'}}>
                            CRIMINAL HISTORY NOT AVAILABLE
                        </Typography>

                    )
                }
        </>
    );
};


