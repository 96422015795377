import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {inject, observer} from "mobx-react";
import Footer from './Footer';
import Header from "./Header";
import {Button, useTheme} from "@material-ui/core";
// https://www.uuidgenerator.net/dev-corner/javascript

import {useLocation} from 'react-router-dom';

// require does not work ?
// const TBI_PROD = require('../images/TBI_PROD_60.png');
// const TBI_TEST = require('../TBI_TEST_76.png');


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#445c7c',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
      marginLeft: drawerWidth,
    },
  //   // zIndex: theme.zIndex.drawer + 1,
  //   // transition: theme.transitions.create(['width', 'margin'], {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100%)`
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    // position: 'relative',
    // whiteSpace: 'nowrap',
    width: drawerWidth,

    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    // drawerPaperClose: {
  //   overflowX: 'hidden',
  //   // transition: theme.transitions.create('width', {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  //   width: theme.spacing(7),
  //   [theme.breakpoints.up('sm')]: {
  //     width: theme.spacing(9),
  //   },
  // },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingTop: theme.toolbar.height,
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

// this will skip a double-header (header, header). it only recognizes the 'second' header.
// @todo - look at header level as a way to determine what goes together.
// https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript

function getJsonFromUrl(url) {
  console.log('getJsonFromUrl: url: ', url);
  url = url.replace('#', '%23');
  console.log('getJsonFromUrl: encoded url: ', url);
  // @todo - replace # with %23
  if(!url) {
    return {};
  }
  var question = url.indexOf("?");
  var hash = url.indexOf("#");
  if(hash==-1 && question==-1) return {};
  if(hash==-1) hash = url.length;
  var query = question==-1 || hash==question+1 ? url.substring(hash) :
      url.substring(question+1,hash);
  var result = {};
  query.split("&").forEach(function(part) {
    if(!part) return;
    part = part.split("+").join(" "); // replace every + with space, regexp-free version
    var eq = part.indexOf("=");
    var key = eq>-1 ? part.substr(0,eq) : part;
    var val = eq>-1 ? decodeURIComponent(part.substr(eq+1)) : "";
    var from = key.indexOf("[");
    if(from==-1) result[decodeURIComponent(key)] = val;
    else {
      var to = key.indexOf("]",from);
      var index = decodeURIComponent(key.substring(from+1,to));
      key = decodeURIComponent(key.substring(0,from));
      if(!result[key]) result[key] = [];
      if(!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
}

function useQuery() {
  let location = useLocation();
  console.log('useLocation: ', location);
  let q = location.search + location.hash;
  console.log('useLocation.search: ', q);
  let queryParams = getJsonFromUrl(q);
  console.log('queryParams: ', JSON.stringify(queryParams, null, 2));
  return queryParams;
}

// export default function Dashboard() {
const Disclaim = ({sessionStore, pubStore, history, match}) => {
  const classes = useStyles();
  const theme = useTheme();
  let query = useQuery();

  const scrollRef = React.useRef(null);

  console.log('Disclaim match: ', JSON.stringify(match, null, 2));
  console.log('Disclaim query: ', JSON.stringify(query, null, 2));


  return (
    <div className={classes.root} style={{paddingTop: pubStore.hasTbiAlert ? theme.toolbar.heightMessage : 0}}>
      <CssBaseline />
      <Header history={history} title={'Disclaimer'} disclaimUrl={query.url} />

      <main ref={scrollRef} className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>

          <Box pt={4}>
            <Footer pubStore={pubStore}/>
          </Box>

        </Container>
      </main>
    </div>
  )
}
export default inject('sessionStore', 'pubStore')(observer(Disclaim));
