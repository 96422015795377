import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import DetailsIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/MailOutline';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import TipsIcon from '@material-ui/icons/Report';
import GeoPinIcon from '@material-ui/icons/LocationOn';
import Config from '../config/Config';
import Badge from "@material-ui/core/Badge";
import {Tooltip} from "@material-ui/core";
import TipDialog from "./TipDialog";
import EmailDialog from "./EmailDialog";
import Box from "@material-ui/core/Box";
import {Button} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const {detect} = require('detect-browser');
const browser = detect();

const debug = Config.enableConsoleDebug;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 160,
        // paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    expand: {
        // transform: 'rotate(0deg)',
        marginLeft: 'auto',
        // transition: theme.transitions.create('transform', {
        //     duration: theme.transitions.duration.shortest,
        // }),
    },
    avatar: {
        backgroundColor: 'primary', //red[500],
    },
    printStyle: {
            '@media print' : {
                display: 'none'
            },
            '@media only screen': {
                display: 'flex',
                flexDirection: 'row'
            }
    },
    printNever: {
        '@media print': {
            // display: 'block',
            display: 'none',
        },
    },

}));

export default function ActionButtons(props) {
    const classes = useStyles();
    const [openPrint, setOpenPrint] = React.useState(false);
    const [openTip, setOpenTip] = React.useState(false);
    const [openEmail, setOpenEmail] = React.useState(false);
    // const [openSearchDetails, setOpenSearchDetails] = React.useState(false);
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    if(!props.showActionButtons) {
        return (<></>);
    }

    return (
            <Box className={classes.printStyle} >
                {props.enableDetails &&
                    <Box style={{display: 'flex', flexDirection: 'column', marginRight: 4}}>
                <IconButton aria-label="offender details" onClick={(event) => {
                    if (event) {
                        event.stopPropagation();
                    }
                    // setOpenDetails(true);
                    props.pubStore.setDetailsOffender(props.offender);
                    if(props.history) {
                        props.history.push(`/details/${props.offender.soid}`);
                    }

                }}>
                    <DetailsIcon/>
                </IconButton>
                    <Typography variant={'caption'} style={{textAlign: 'center'}}>
                        DETAILS
                    </Typography>
                    </Box>
                }
                <Box style={{display: 'flex', flexDirection: 'column', marginRight: 4}}>
                <Tooltip title={'Email Alerts'}>
                <IconButton aria-label="subscribe email" color={'primary'} onClick={(event) => {
                    debug && console.log('isSubscribed: ' + props.pubStore.subStore.isSubscribed(props.offender.soid));
                    if(props.pubStore.subStore.isSubscribed(props.offender.soid)) {
                        if(props.history) {
                            props.history.push('/email');
                        }
                        } else {
                        props.pubStore.subStore.setOffender(props.offender);
                        setOpenEmail(true);
                    }
                    }}>
                        <Badge variant={'dot'} color={'secondary'}
                               invisible={!props.pubStore.subStore.isSubscribed(props.offender.soid)}>
                            <EmailIcon/>
                        </Badge>
                    </IconButton>
                </Tooltip>
                    <Typography variant={'caption'} style={{textAlign: 'center'}}>
                        ALERTS
                    </Typography>
                </Box>
                <EmailDialog offender={props.offender} pubStore={props.pubStore}
                             history={props.history}
                           open={openEmail}
                           handleClose={(event) => {
                               if (event) {
                                   // important to STOP PROPAGATION ! or you get double-duty on events
                                   event.stopPropagation();
                               }
                               setOpenEmail(false);
                           }}
                />
                                                     <Box style={{
                                                         display: 'flex',
                                                         flexDirection: 'column',
                                                         marginRight: 4
                                                     }}
                                                     >
                                                         <IconButton aria-label="offender details" color={'primary'}
                                                                     disabled={!props.pubStore.loaded}
                            onClick={(event) => {
                                debug && console.log('print ' + navigator.userAgent);
                                if (browser && browser.name && browser.name.match(/safari|ios/i)) {
                                    setOpenPrint(true);
                                } else {
                                    window.print();
                                }
                            }}>
                                                             <PDFIcon/>
                                                         </IconButton>
                                                         <Typography variant={'caption'} style={{textAlign: 'center'}}>
                                                             PRINT
                                                         </Typography>
                                                     </Box>
                <Box style={{display: 'flex', flexDirection: 'column', marginRight: 4}}>

                <IconButton aria-label="offender details"
                            color={'primary'}
                            onClick={(event) => {
                    props.pubStore.tips.setOffender(props.offender);
                    setOpenTip(true);
                    // if(props.history) {
                    //     props.history.push('/tips');
                    // }

                }}>
                    <TipsIcon/>
                </IconButton>
                    <Typography variant={'caption'} style={{textAlign: 'center'}}>
                        TIPS/BAD INFO
                    </Typography>
                </Box>
                <TipDialog offender={props.offender} pubStore={props.pubStore}
                           open={openTip}
                           handleClose={(event) => {
                               if (event) {
                                   event.stopPropagation();
                               }
                               setOpenTip(false);
                           }}
                />
                <Box style={{display: 'flex', flexDirection: 'column', marginRight: 4}}>

                <IconButton aria-label="offender details"
                            color={'primary'}
                            onClick={(event) => {
                   // example: https://test.tnmap.tn.gov/sor/beta/#/offender/00152818
                    let url = `${Config.TNMAP_BASE}/offender/${props.offender.soid}`;
                    window.location = url;

                }}>
                    <GeoPinIcon/>
                </IconButton>
                    <Typography variant={'caption'} style={{textAlign: 'center'}}>
                        MAP
                    </Typography>
                </Box>
            {openPrint &&
            <Dialog
                open={openPrint}
            >
                <DialogTitle style={{backgroundColor: Config.CONFIRM_BACKGROUND}}>
                    Print Offender Details
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body1'}>
                        For best results printing offender details, use the browser Print option (File Menu->Print,
                        Command-P, or the Print action).
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenPrint(false);
                    }}
                    >
                        Ok
                    </Button>

                </DialogActions>
            </Dialog>

            }

            </Box>
    );
}
