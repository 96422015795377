import React from 'react';
import {useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import {inject, observer} from "mobx-react";
import Config from '../../config/Config';
import Footer from '../../pages/Footer';
import ChipInput from "material-ui-chip-input";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from "@material-ui/icons/FilterList";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
// BV20210319
import Checkbox from "@material-ui/core/Checkbox";

import QueryResults from "./QueryResults";
import Header from "../../pages/Header";
import Output from '../editorjs-renderer';
import {Collapse, Paper, useTheme} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import {v4 as uuidv4} from 'uuid';
import {appBarSpace} from "../theme/Theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const counties = require('../data/counties.json');
//const classifications = require('../data/classifications.json');
const statuses = require('../data/statuses.json');

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        backgroundColor: '#445c7c',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100%)`,
            marginLeft: drawerWidth,
        },
        //   // zIndex: theme.zIndex.drawer + 1,
        //   // transition: theme.transitions.create(['width', 'margin'], {
        //   //   easing: theme.transitions.easing.sharp,
        //   //   duration: theme.transitions.duration.leavingScreen,
        //   // }),
    },
    appBarShift: {
        // marginLeft: drawerWidth,
        width: `calc(100%)`
        // width: `calc(100% - ${drawerWidth}px)`,
        // transition: theme.transitions.create(['width', 'margin'], {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            // width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        // position: 'relative',
        // whiteSpace: 'nowrap',
        width: drawerWidth,

        // transition: theme.transitions.create('width', {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

    // drawerPaperClose: {
    //   overflowX: 'hidden',
    //   // transition: theme.transitions.create('width', {
    //   //   easing: theme.transitions.easing.sharp,
    //   //   duration: theme.transitions.duration.leavingScreen,
    //   // }),
    //   width: theme.spacing(7),
    //   [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9),
    //   },
    // },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        paddingTop: theme.toolbar.height,
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    grow: {
        '&:hover': {
            transform: 'scale(1.1)',
            transitionDuration: '0.2s',
        }
    },
}));


function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// q=terms
// c=county
// k=classification
// s=status
// x=exclude incarcerated
// n= by name
// a= by address
// r=results per page
// p=page
// o=orderby
// d=descending true|false


// export default function Dashboard() {
const Search = ({sessionStore, pubStore, history}) => {
    const classes = useStyles();
    const theme = useTheme();

    // let queryString = useLocation().search;
    // console.log('Search.queryString: ', queryString);
    // console.log('Search.query param q: ', '|' + getParameterByName('q', queryString) + '|');

    const scrollRef = React.useRef(null);
    const [error, setError] = React.useState('');
    const [expanded, setExpanded] = React.useState(sessionStore.expandedSearch);
    const [county, setCounty] = React.useState(pubStore.search.county);
    //const [classification, setClassification] = React.useState(pubStore.search.classification);
    const [status, setStatus] = React.useState(pubStore.search.offenderstatus);
    const [showFilters, setShowFilters] = React.useState(false);
    const [byName, setByName] = React.useState(false);
    const [byAddress, setByAddress] = React.useState(false);
    const [includeIncarcerated, setIncludeIncarcerated] = React.useState(true);
    const chipInputRef = React.useRef();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const searchCriteria = (resultsMode) => {
        if(pubStore.search.chips.length === 0
            && !byName
            && !byAddress
            && !(county > '')
/*            && !(classification > '')*/
            && !(status > '')
            && !includeIncarcerated
            && !resultsMode
        )
        {
            return 'Enter search terms (name, alias, city, county, zip, offender ID, status, offense)';
        }
        return (resultsMode ? '' : 'Search for ')
            + `${pubStore.search.chips.length > 0 ? 'offenders matching ' +  pubStore.search.chips.join(', and ') : ' any offender'}`
            + `${byName ? ' by searching name or alias only' : ''}`
            + `${byAddress ? ' by searching primary, secondary, employer address(es) (street, city, state, zip code) only' : ''}`
            + `${county > '' ? ' having a primary, secondary, or employer county: '+county : ''}`
/*            + `${classification > '' ? ' having the classification: '+classification : ''}`*/
            + `${status > '' ? ' with status: '+status : ''}`
            + `. incarcerated/prison offenders are ${includeIncarcerated ? '' : ' not '} included in the results.`
        ;
    }
   return (
        <div className={classes.root} style={{marginTop: appBarSpace(sm, pubStore.hasTBIAlert)}}>
            <CssBaseline/>
            <Header history={history} title={'Search'}/>
            <main ref={scrollRef} className={classes.content}>

                <Container maxWidth="lg" className={classes.container}>
                    <Accordion key={"FAQs"} expanded={expanded}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => {
                                sessionStore.setExpandedSearch(!expanded);
                                setExpanded(!expanded);
                            }}
                        >
                            <Typography style={{fontWeight:700}} variant={'h3'}>
                                Search Disclaimer & Instructions
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid container direction={'column'} spacing={0}>
                                {pubStore &&
                                <Box style={{backgroundColor: Config.CONTENT_BACKGROUND}} key={'DISCLAIMER_SEARCH'}>
                                    <Output data={pubStore.getContent('DISCLAIMER_SEARCH').data} style={theme.output}/>
                                </Box>
                                }

                                {pubStore &&
                                <Box style={{backgroundColor: Config.CONTENT_BACKGROUND}} key={'INSTRUCT_SEARCH'}>
                                    <Output data={pubStore.getContent('INSTRUCT_SEARCH').data} style={theme.output}/>

                                </Box>
                                }
                                <Divider style={{
                                    backgroundColor: Config.TN_RED,
                                    width: '85%',
                                    margin: "auto",
                                    marginTop: 16,
                                    marginBottom: 4,
                                    height: '4px'
                                }} variant={"middle"}/>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <form>
                        <Grid container direction={"column"} alignItems={'center'} style={{marginTop: 24}} key={uuidv4()}>
                            <Grid item container style={{marginTop: '8px'}} spacing={0}>
                                <Grid item xs={10}>
                                    <ChipInput
                                        ref={chipInputRef}
                                        classes={{
                                            chip: classes.chip,
                                            input: classes.input,
                                            chipContainer: classes.chipContainer,
                                        }}
                                        variant={'outlined'}

                                        fullWidth
                                        autoFocus
                                        value={pubStore.search.chips.map(chip => chip)}
                                        onAdd={(chip) => {
                                            // console.log('******************** onAdd chip: ', chip);
                                            pubStore.search.addChips(chip);
                                            setError(pubStore.search.criteriaError);
                                            setTimeout(() => {chipInputRef.current.focus();}, 50);
                                            // let _chips = chip.trim().replace(/( )+/g, ' ').split(' ');
                                            // _chips.map((_chip) => {
                                            //     searchStore.addChip(_chip);
                                            // })
                                        }}
                                        onDelete={(chip, index) => {
                                            // console.log('chips delete', chip, index);
                                            pubStore.search.deleteChip(chip, index);
                                            setError(pubStore.search.criteriaError);
                                            setTimeout(() => {chipInputRef.current.focus();}, 50);
                                            pubStore.search.setSearchCriteria(searchCriteria(true));
                                        }}
                                        blurBehavior={'add'}
                                        // placeholder={'name, alias, city, county, zip, offender id, classification, status, offense'}
                                        // helperText={'enter search terms (name, alias, city, county, zip, offender id, classification, status, offense)'}
                                        error={error > ''}
                                        helperText={error > '' ? error : searchCriteria()}
                                        label={'Search'}
                                        // label={<Typography variant={'caption'} style={{paddingBottom: 4}}>enter search terms (name, alias, city, county, zip, offender id, classification, status, offense)</Typography>}
                                        // onUpdateInput={(input) => {
                                        //     console.log('******************** onUpdateInput.input: ', input);
                                        // }}
                                        // onChange={(value) => {
                                        //     console.log('******************** onChange.value: ', value);
                                        //
                                        // }}
                                        InputLabelProps={{shrink: true}}
                                        // chipRenderer={genieChipRenderer}
                                    />


                                </Grid>
                                <Grid item container direction={'row'} spacing={1} xs={2} justify={'space-evenly'}
                                      alignItems={'center'}>
                                    <Box className={classes.grow}
                                         style={{display: 'flex', flexDirection: 'column', marginRight: 4}}
                                         onClick={() => {

                                        pubStore.search.clearSearch();
                                        // also reset states on this page (redundant)
                                        setError('');
                                        setCounty('');
/*                                        setClassification('');*/
                                        setStatus('');
                                        setByName(false);
                                        setByAddress(false);
                                        setIncludeIncarcerated(true);

                                        // sessionStore.clearSearch();
                                    }}>
                                    <IconButton style={{padding: 4}} aria-label={"Clear Search Input"}  >
                                        <ClearIcon aria-label={"Clear Search Input"}
                                                   style={{alignSelf: 'flex-end', justifySelf: 'center'}}/>
                                    </IconButton>
                                        <Typography variant={'caption'} style={{textAlign: 'center'}}>
                                            Clear
                                        </Typography>
                                        <Typography variant={'caption'} style={{textAlign: 'center'}}>
                                            &nbsp;
                                        </Typography>
                                    </Box>
                                    <Box className={classes.grow}
                                         style={{display: 'flex', flexDirection: 'column', marginRight: 4}}
                                                onClick={() => {
                                                    setShowFilters(!showFilters);
                                                }}>
                                        <IconButton style={{padding: 4}} color={showFilters ? 'primary' : 'default'}
                                               >
                                        {!showFilters ?
                                            (
                                                <ExpandMoreIcon aria-label={"Show Advanced Search Filters"}
                                                            style={{alignSelf: 'flex-end'}}/>
                                            )
                                            :
                                            (
                                            <ExpandLessIcon aria-label={"Hide Advanced Search Filters"}
                                            style={{alignSelf: 'flex-end'}}/>
                                            )}
                                    </IconButton>
                                        <Typography variant={'caption'} style={{textAlign: 'center'}}>
                                            Advanced
                                        </Typography>
                                        <Typography variant={'caption'} style={{textAlign: 'center'}}>
                                            Search
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Collapse in={showFilters}>
                            <Paper>
                                {true && <Grid container direction={'row'} spacing={2} style={{marginTop: 24, marginLeft: 12}}>
                                    <Grid item container direction={'column'} sm={6} spacing={1}
                                          style={{marginTop: 12}}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={includeIncarcerated}
                                                        onChange={(event) => {
                                                            console.log('includeIncarcerated event: ' + event.target.checked);
                                                            setIncludeIncarcerated(event.target.checked);
                                                        }
                                                        }
                                                        name="checkedExcludeIncarcerated"
                                                        color="primary"
                                                    />
                                                }
                                                label="Include Prisons/Incarcerated"
                                            />
                                        </Grid>
                                        <Grid item >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={byName}
                                                        onChange={(event) => {
                                                            setByName(event.target.checked);
                                                            if (event.target.checked) {
                                                                setByAddress(false);
                                                            }
                                                        }}
                                                        name="checkedExcludeIncarcerated"
                                                        color="primary"
                                                    />
                                                }
                                                label="Search By Name Only"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={byAddress}
                                                        onChange={(event) => {
                                                            setByAddress(event.target.checked)
                                                            if (event.target.checked) {
                                                                setByName(false);
                                                            }
                                                        }
                                                        }
                                                        name="checkedExcludeIncarcerated"
                                                        color="primary"
                                                    />
                                                }
                                                label="Search By Address Only"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction={'column'} spacing={1} sm={6} >
                                        <Grid item>
                                            <FormControl>
                                                <InputLabel id="select-county-label">County</InputLabel>
                                                <Select style={{minWidth: 200}} labelId="select-county-label" aria-label={"Select County"}
                                                        id="select-county" value={county} onChange={(event) => {
                                                    setCounty(event.target.value);
                                                    // pubStore.search.addChip(event.target.value);
                                                }}>
                                                    <MenuItem value={""} key={uuidv4()}><em>None</em></MenuItem>
                                                    {counties.map(c => (
                                                        <MenuItem value={c} key={uuidv4()}>{c}</MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </Grid>
                                        {/*<Grid item>*/}
                                        {/*    <FormControl>*/}
                                        {/*        <InputLabel id="select-classification-label">Classification</InputLabel>*/}
                                        {/*        <Select style={{minWidth: 200}} labelId="select-classification-label"*/}
                                        {/*                id="select-classification" value={classification}*/}
                                        {/*                onChange={(event) => {*/}
                                        {/*                    setClassification(event.target.value);*/}
                                        {/*                    // pubStore.search.addChip(event.target.value);*/}
                                        {/*                }}>*/}
                                        {/*            <MenuItem value={""} key={uuidv4()}><em>None</em></MenuItem>*/}
                                        {/*            {classifications.map(c => (*/}
                                        {/*                <MenuItem value={c} key={uuidv4()}>{c}</MenuItem>*/}
                                        {/*            ))}*/}
                                        {/*        </Select>*/}
                                        {/*    </FormControl>*/}
                                        {/*</Grid>*/}
                                        <Grid item>

                                            <FormControl>
                                                <InputLabel id="select-status-label">Offender Status</InputLabel>
                                                <Select style={{minWidth: 200}} labelId="select-status-label"
                                                        id="select-status" value={status} onChange={(event) => {
                                                    setStatus(event.target.value);
                                                    // pubStore.search.addChip(event.target.value);
                                                }}>
                                                    <MenuItem value={""} key={uuidv4()}><em>None</em></MenuItem>
                                                    {statuses.map(c => (
                                                        <MenuItem value={c} key={uuidv4()}>{c}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            </Paper>
                        </Collapse>
                        {pubStore.search.api.isWorking ? (<CircularProgress size={40}/>) : (<Button
                                type="submit"
                                disabled={error > ''}
                                fullWidth
                                m="2rem"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => {
                                    pubStore.search.setExcludeIncarcerated(!includeIncarcerated);
                                    pubStore.search.setCounty(county);
/*                                    pubStore.search.setClassification(classification);*/
                                    pubStore.search.setOffenderStatus(status);
                                    pubStore.search.setByName(byName);
                                    pubStore.search.setByAddress(byAddress);
                                    pubStore.search.setSearchCriteria(searchCriteria(true));
                                    pubStore.search.search();
                                }}
                            >
                                Search
                            </Button>
                        )}
                  </form>

                    <QueryResults scrollRef={scrollRef} />

                    <Box pt={4}>
                        <Footer pubStore={pubStore} history={history} loaded={pubStore.loaded}/>
                    </Box>

                </Container>
            </main>
        </div>
    )
}
export default inject('sessionStore', 'pubStore')(observer(Search));
