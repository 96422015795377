
import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {inject, observer, Provider} from "mobx-react";
import Config from "../config/Config";
import DisclaimerDialog from "./DisclaimerDialog";
import Container from '@material-ui/core/Container';
import {Alert, AlertTitle} from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import Header from "./Header";
import Footer from './Footer';
import Divider from '@material-ui/core/Divider';

import SEARCH from '../images/search_image.png';
import EMAIL from '../images/email_button.png';
import MAP from '../images/map_image.png';
import INFO from '../images/flag_image.png';
import SAFETY from '../images/doj_image_150.png';
import MostWantedTile from "../shared/components/MostWantedTile";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useCookies} from "react-cookie";
import Output from "../shared/editorjs-renderer";
import {appBarSpace} from "../shared/theme/Theme";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        height: theme.toolbar.height,
    },
    // paddingRight: 24, // keep right padding when drawer closed
    nottoolbar:
        theme.mixins.toolbar,

    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        backgroundColor: '#445c7c',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100%)`,
            marginLeft: drawerWidth,
        },
        //   // zIndex: theme.zIndex.drawer + 1,
        //   // transition: theme.transitions.create(['width', 'margin'], {
        //   //   easing: theme.transitions.easing.sharp,
        //   //   duration: theme.transitions.duration.leavingScreen,
        //   // }),
    },
    appBarShift: {
        // marginLeft: drawerWidth,
        width: `calc(100%)`
        // width: `calc(100% - ${drawerWidth}px)`,
        // transition: theme.transitions.create(['width', 'margin'], {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            // width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        // position: 'relative',
        // whiteSpace: 'nowrap',
        width: drawerWidth,

        // transition: theme.transitions.create('width', {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    appBarSpacer: theme.mixins.toolbar,
    content: {
        paddingTop: theme.toolbar.height,
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    // Style transform Property
    // https://www.w3schools.com/jsref/prop_style_transform.asp
    grow: {
        marginTop: 10,
        '&:hover': {
            transform: 'scale(1.05)',
            transitionDuration: '0.2s',
        }
    },
}));


const Home = ({sessionStore, pubStore, history, location}) => {
    const classes = useStyles();
    const [cookies, setCookie] = useCookies(['name']);

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    let cols = 2.5;
    cols = sm ? 3.5 : cols;
    cols = md ? 5.5 : cols;
    const HOME_IMAGE_WIDTH = 150;


    let home_right_data = pubStore.getContent('HOME_SUPPLEMENTAL_RIGHT').data;
    let hasHomeRight = (Array.isArray(home_right_data.blocks) && home_right_data.blocks.length > 0 ? true : false);

  return (
    <div className={classes.root}  style={{marginTop: appBarSpace(sm, pubStore.hasTBIAlert)}}>
      {/*ie11*/}
      <CssBaseline />

      <Header history={history} home />

      <main className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>
            <Grid container direction={'row'} justify={'center'} spacing={2}>
                <Grid className={classes.grow}
                    style={{marginRight: 16,
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                    onClick={() => {
                        setCookie('name', 'search', { path: '/'});

                        // pubStore.search.clearSearch();
                        history.push('/search');
                    }
                    }>
                    {/*Changed colored bar under all home screen tile images from Orange to Dk Blue per Josh Devine BV 7.29.21*/}
                    <img src={SEARCH} alt={"Search Offenders"}  style={{marginRight: 0, width: HOME_IMAGE_WIDTH, borderBottom: `5px ${Config.TN_DARKBLUE} solid`}} />
                    <Typography variant={'h4'} align={'center'} style={{marginRight: 0}}>
                        Search
                    </Typography>
                </Grid>
                <Grid className={classes.grow}
                      style={{marginRight: 16,
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                      onClick={() => {
                          setCookie('name', 'email', { path: '/'});
                          history.push('/email');
                      }}>
                    <img src={EMAIL}  alt={"Request email notice of offender update"} style={{marginRight: 0, width: HOME_IMAGE_WIDTH, borderBottom: `5px ${Config.TN_DARKBLUE} solid`}} />
                    <Typography variant={'h4'} align={'center'} style={{marginRight: 0}}>
                        Email Alerts
                    </Typography>
                </Grid>
                <Grid className={classes.grow}
                      style={{marginRight: 16,
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                      onClick={() => {
                          setCookie('name', 'map', { path: '/'});
                          window.location = Config.TNMAP_BASE;
                      }}>
                    <img src={MAP} alt={"Map Offender Location"} style={{marginRight: 0, width: HOME_IMAGE_WIDTH, borderBottom: `5px ${Config.TN_DARKBLUE} solid`}} />
                    <Typography variant={'h4'} align={'center'} style={{marginRight: 0}}>
                        Map Offenders
                    </Typography>
                </Grid>
                <Grid className={classes.grow}
                      style={{marginRight: 16,
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                      onClick={() => {
                          setCookie('name', 'info', { path: '/'});
                          history.push('/info');
                      }}>
                    <img src={INFO} alt={"SOR Information"} style={{marginRight: 0, width: HOME_IMAGE_WIDTH, borderBottom: `5px ${Config.TN_DARKBLUE} solid`}} />
                    <Typography variant={'h4'} align={'center'} style={{marginRight: 0}}>
                        SOR Information
                    </Typography>
                </Grid>
                {/* https://reactrouter.com/web/api/HashRouter/getuserconfirmation-func */}
                <Grid className={classes.grow}
                      style={{marginRight: 16,
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                      onClick={() => {
                          // window.location = 'https://www.nsopw.gov/en/SafetyAndEducation';
                          window.open('https://www.nsopw.gov/en/SafetyAndEducation', '_blank');
                      }}>
                    <img src={SAFETY} alt={"Safety and Education"} style={{marginRight: 0, width: HOME_IMAGE_WIDTH, borderBottom: `5px ${Config.TN_DARKBLUE} solid`}} />
                    <Typography variant={'h4'} align={'center'} style={{marginRight: 0, paddingBottom:4}}>
                        Safety & Education
                    </Typography>
                </Grid>

            </Grid>
          <Divider style={{backgroundColor: Config.TN_RED, width: '85%', margin:"auto", marginTop: 48, marginBottom: 24, height: '4px'}}  variant={"middle"}/>

            <Grid container direction={'column'} alignItems={'center'}>
                {pubStore.loaded &&
            <Box style={{backgroundColor: Config.CONTENT_BACKGROUND,  width: '75%'}}>
                <Output data={pubStore.getContent('TOP10_HEADER').data} style={theme.center_output}/>
            </Box>
            }
            </Grid>

            <GridList cellHeight={250} className={classes.gridList} spacing={2} style={{height: 420,  marginRight: 20}}>
                {pubStore.top10Contents.map((item) => {
                    return (
                        <MostWantedTile item={item}
                                        key={item.id}
                                        onClick={(event) => {
                                            if (event) {
                                            event.stopPropagation();
                                        }
                                            // setOpenDetails(true);
                                            pubStore.setDetailsOffender(item.data.offender);
                                            if(history) {
                                            history.push(`/details/${item.data.offender.soid}`);
                                        }

                                        }
                                        }
                        />
                    )

                })
                }
            </GridList>
          <Divider style={{backgroundColor: Config.TN_RED, width: '85%', margin:"auto", marginTop: 24, marginBottom: 16, height: '4px'}}  variant={"middle"}/>

            <Grid container direction={md ? 'row' : 'column'} spacing={2} style={{alignItems:"center"}}>
                {pubStore.loaded &&
                <Grid item md={hasHomeRight ? 6 : 12} style={{backgroundColor: Config.CONTENT_BACKGROUND, }}>
                    <Output data={pubStore.getContent('HOME_SUPPLEMENTAL_LEFT').data} style={theme.output}/>
                </Grid>
                }
                {hasHomeRight && pubStore.loaded &&
                <Grid item md={6} spacing={2} style={{backgroundColor: Config.CONTENT_BACKGROUND, }}>
                    <Output data={home_right_data} style={theme.output}/>
                </Grid>
                }
            </Grid>
          <Box pt={4}>
            <Footer loaded={pubStore.loaded}/>
          </Box>

        </Container>
      </main>
    </div>
  )
}
export default inject('sessionStore', 'pubStore')(observer(Home));
