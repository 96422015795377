import React from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { lighten} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import {v4 as uuidv4} from 'uuid';

// require does not work ?
// const TBI_PROD = require('../images/TBI_PROD_60.png');
// const TBI_TEST = require('../TBI_TEST_76.png');


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        backgroundColor: '#445c7c',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100%)`,
            marginLeft: drawerWidth,
        },
        //   // zIndex: theme.zIndex.drawer + 1,
        //   // transition: theme.transitions.create(['width', 'margin'], {
        //   //   easing: theme.transitions.easing.sharp,
        //   //   duration: theme.transitions.duration.leavingScreen,
        //   // }),
    },
    appBarShift: {
        // marginLeft: drawerWidth,
        width: `calc(100%)`
        // width: `calc(100% - ${drawerWidth}px)`,
        // transition: theme.transitions.create(['width', 'margin'], {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            // width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        // position: 'relative',
        // whiteSpace: 'nowrap',
        width: drawerWidth,

        // transition: theme.transitions.create('width', {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    // drawerPaperClose: {
    //   overflowX: 'hidden',
    //   // transition: theme.transitions.create('width', {
    //   //   easing: theme.transitions.easing.sharp,
    //   //   duration: theme.transitions.duration.leavingScreen,
    //   // }),
    //   width: theme.spacing(7),
    //   [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9),
    //   },
    // },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
        width: '100%',
          },
    fixedHeight: {
        height: 240,
    },
    code: {
        textAlign: 'left',
        backgroundColor: 'white',
        width: '100%',
        whiteSpace: 'pre-wrap',
        wordBreak: "break-all",
    },

    // root: {
    //     width: '100%',
    // },
    // paper: {
    //     width: '100%',
    //     marginBottom: theme.spacing(2),
    // },
    table: {
        // minWidth: 750,
        // minWidth: 300,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    row: {
        border: '5x red',
    }
}));


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(enable, array, comparator) {
    if(!enable) {
        return array;
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const _headCells = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'soid', numeric: false, disablePadding: true, label: 'SOID' },
    { id: 'submitname', numeric: false, disablePadding: true, label: 'Submit Name' },
    { id: 'submitemail', numeric: false, disablePadding: true, label: 'Submit Email' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'note', numeric: false, disablePadding: true, label: 'Note' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {props.head.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        align={headCell.numeric ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        key={uuidv4()}
                    >
                        <TableSortLabel style={{fontFamily: 'Open Sans'}}
                            active={orderBy === headCell.id}
                            hideSortIcon={!headCell.sortable}
                            disabled={!headCell.sortable}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root)}
        >
            {props.title > '' && <Typography className={classes.title} variant="h3" id="tableTitle" component="div">
                {props.title}
    </Typography>}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
// https://stackoverflow.com/questions/57325232/how-to-remove-lines-between-cells-in-table-in-material-ui
const StyledTableCell = withStyles(theme => ({
    root: {
            // backgroundColor: theme.palette.action.hover,
        borderBottom: 'none',
        paddingBottom: 0,
    }
}))(TableCell);

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('soid');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(props.page || 0);
    const [dense, setDense] = React.useState(false);
    // const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);

    const handleRequestSort = (event, property) => {
        // console.log('handleRequestSort property: ' + property + ' before: ' + order);
        const isAsc = orderBy === property && order === 'asc';
        let direction = isAsc ? 'desc' : 'asc';
        // console.log('handleRequestSort property: ' + property + ' before: ' + order + ' isAsc: ' + isAsc + ' direction: ' + direction);
        setOrder(direction);
        setOrderBy(property);
        props.onChange && props.onChange({orderBy: property, orderDirection: direction});

    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.data.map((t) => t.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.onChange && props.onChange({page: newPage});
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.onChange && props.onChange({page: 0, rowsPerPage: parseInt(event.target.value, 10)});
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.data.length - page * props.rowsPerPage);

    let head = [];
    head = head.concat(props.head);
    // head.shift();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} {...props} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            // rowCount={props.data.length}
                            rowCount={props.rowCount}
                            {...props}
                        />
                        <TableBody>
                            {
                                stableSort(props.enableLocalSort, props.data, getComparator(order, orderBy))
                                    .slice((props.enableLocalSort ? page : 0) * props.rowsPerPage, (props.enableLocalSort ? page : 0) * props.rowsPerPage + props.rowsPerPage)
                                    .map((row, index) => {
                                // props.data.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <>
                                        <TableRow
                                            hover
                                            onClick={props.onRowClick ? (event) => props.onRowClick(event, row) : undefined}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}

                                        >
                                            {/*<TableCell padding="checkbox">*/}
                                            {/*    <Checkbox*/}
                                            {/*        checked={isItemSelected}*/}
                                            {/*        inputProps={{ 'aria-labelledby': labelId }}*/}
                                            {/*    />*/}
                                            {/*</TableCell>*/}
                                            {/*{props.spanrow ? (*/}
                                            {/*    <StyledTableCell component="th" id={labelId} scope="row" padding="none" key={uuidv4()}>*/}
                                            {/*        {row.id}*/}
                                            {/*    </StyledTableCell>*/}

                                            {/*) : (*/}
                                            {/*    <TableCell component="th" id={labelId} scope="row" padding="none" key={uuidv4()}>*/}
                                            {/*        {row.id}*/}
                                            {/*    </TableCell>*/}

                                            {/*)}*/}
                                            {head.map(h => {
                                                // console.log('head map h.id: ', h.id);
                                                if(!h.component) {
                                                    if(props.spanrow) {
                                                        return (<StyledTableCell key={uuidv4()}
                                                            align="left">{row[h.id]}
                                                        </StyledTableCell>
                                                        );
                                                    }
                                                    else {
                                                        return (<TableCell key={uuidv4()}
                                                            align="left">{row[h.id]}
                                                        </TableCell>
                                                        );

                                                    }
                                                }
                                                else {
                                                    if(props.spanrow) {
                                                        return (
                                                            <StyledTableCell align="left" key={uuidv4()}>
                                                                {/*{h.component({row, pubStore, history})}*/}
                                                                {h.component({row, ...props})}
                                                            </StyledTableCell>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <TableCell align="center" key={uuidv4()}>
                                                                {/*{h.component({row, pubStore, history})}*/}
                                                                {h.component({row, ...props})}
                                                            </TableCell>
                                                        );
                                                    }

                                                }
                                            })}
                                        </TableRow>
                                            {props.spanrow && (
                                                <TableRow key={uuidv4()}>
                                                    <TableCell colSpan={head.length + 1} key={uuidv4()}>
                                                        {props.spanrow({row,...props})}
                                                    </TableCell>
                                                </TableRow>

                                            )}
                                    </>
                                    );
                                })}
                            {false && emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }} key={uuidv4()}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    // count={props.data.length}
                    count={props.rowCount}
                    rowsPerPage={props.rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
