import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@material-ui/icons/CheckCircle';
import {Box, Divider, Grid, List, ListItem, Typography} from "@material-ui/core";
import Config from "../config/Config";

const validEmail = require('../shared/util/validation').validEmail;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

// 2-stage dialog (
// props
// open (true|false)
// handleClose callback
// pubStore
// item (subscription can be zip or soid
// action - promise (e.g. remove + load)
// title
// prompt 'Are you want to remove email notifcation on <NAME>.... etc.
// success 'You have succesfully...'

export default function EmailActionDialog(props) {
    const classes = useStyles();
    const [error, setError] = React.useState('');
    const [isWorking, setIsWorking] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const reset = () => {
        setIsWorking(false);
        setIsSubmitted(false);
        setError('');
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" style={{backgroundColor: Config.CONFIRM_BACKGROUND}}>
                <Grid container>
                    <Grid item xs style={{marginLeft: 0, }}>
                        <Typography variant={'h5'}>
                            {props.title}
                        </Typography>
                    </Grid>
                </Grid>

            </DialogTitle>
            <DialogContent dividers={true}>
                {!isSubmitted && (
                    <>
                        <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            {props.icon}
                            <Typography variant={'body1'} >
                                {props.prompt}
                            </Typography>
                        </Box>
                    </>
                )}
                {isSubmitted && (

                    <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <CheckIcon color={'inherit'} style={{color: 'green', marginRight: 8}} fontSize={'large'}/>
                        <Typography variant={'body1'} >
                            {props.success}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {isWorking && (
                    <CircularProgress variant={"indeterminate"}/>
                )}
                {!isSubmitted && (
                    <>
                        <Button color="primary" onClick={() => {
                            reset();
                            props.handleClose();
                        }}>
                            Cancel
                        </Button>
                        <Button color="primary"
                                disabled={error > '' || isWorking}
                                onClick={() => {
                                    setIsWorking(true);
                                    props.action()
                                        .then(() => {
                                            // setIsSubmitted(true);
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        })
                                        .finally(() => {
                                            setIsWorking(false);
                                            reset();
                                            props.handleClose();
                                        })
                                    // item.save();

                                }}>
                            Ok
                        </Button>
                    </>
                )}
                {isSubmitted && (
                    <>
                        <Button color="primary" onClick={() => {
                            reset();
                            props.handleClose();
                        }}>
                            Close
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
