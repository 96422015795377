import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import MenuIcon from './GenieIcon';
import MenuIcon from '@material-ui/icons/Menu';
import {inject, observer} from "mobx-react";
import {Alert, AlertTitle} from '@material-ui/lab';
import NavigationDrawer from "./NavigationDrawer";
import Config from '../config/Config';
import {Button, Icon, Tooltip} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';
import DisclaimerDialog from "./DisclaimerDialog";
import {useCookies} from "react-cookie";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Output from '../shared/editorjs-renderer';
import RestoreIcon from '@material-ui/icons/Restore';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

const navItems = require('./NavigationDrawer').navItems;

const drawerWidth = 240;

const debug = Config.enableConsoleDebug;

function HideOnScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!props.trigger}>
            {children}
        </Slide>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbarx:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        backgroundColor: '#445c7c',
        '@media print' : {
            // display: 'block',
            display: 'none',
        },
        // [theme.breakpoints.up('sm')]: {
        //   width: `calc(100%)`,
        //   marginLeft: drawerWidth,
        // },
        //   // zIndex: theme.zIndex.drawer + 1,
        //   // transition: theme.transitions.create(['width', 'margin'], {
        //   //   easing: theme.transitions.easing.sharp,
        //   //   duration: theme.transitions.duration.leavingScreen,
        //   // }),
    },
    appBarShift: {
        // marginLeft: drawerWidth,
        width: `calc(100%)`
        // width: `calc(100% - ${drawerWidth}px)`,
        // transition: theme.transitions.create(['width', 'margin'], {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    hamburgerButton: {
        marginRight: 0,
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        '&:hover': {
            borderTop: '4px solid red',
        }

        // [theme.breakpoints.up('sm')]: {
        //   display: 'none',
        // },
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            // width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        // position: 'relative',
        // whiteSpace: 'nowrap',
        width: drawerWidth,

        // transition: theme.transitions.create('width', {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    // drawerPaperClose: {
    //   overflowX: 'hidden',
    //   // transition: theme.transitions.create('width', {
    //   //   easing: theme.transitions.easing.sharp,
    //   //   duration: theme.transitions.duration.leavingScreen,
    //   // }),
    //   width: theme.spacing(7),
    //   [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9),
    //   },
    // },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    tnHome: {
        marginRight: theme.spacing(2),
        '&:hover': {
            borderTop: '4px solid red',
        }

    },
    printOnly: {
        '@media print': {
            // display: 'block',
            width: '100%'
        },
        '@media only screen': {
            display: 'none',
        }
    },
    printNever: {
        '@media print' : {
            // display: 'block',
            display: 'none',
        },
    },
}));

const Header = (props) => {
    const classes = useStyles();
    const [cookies, setCookie] = useCookies();

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const trigger = useScrollTrigger();

    props.sessionStore.hydrate(cookies);

    const [open, setOpen] = React.useState(false);
    const [signinEl, setSigninEl] = React.useState(null);
    const [menuEl, setMenuEl] = React.useState(null);
    const scrollRef = React.useRef(null);
    const [isDisclaimed, setIsDisclaimed] = React.useState(props.sessionStore.isDisclaimed());

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    if(props.disclaimUrl && isDisclaimed) {
        window.location = props.disclaimUrl;
        debug && console.log('Header.changed window.location. returning fragment');
        return (<></>);
    }

    let pubStore = props.pubStore;

    let tbi_alert_data = pubStore.getContent('TBI_ALERT', 'ALERT').data;

    return (
        <>
            <AppBar
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar className={classes.toolbar} >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={  classes.hamburgerButton }
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={sm ? Config.LOGO : Config.LOGO_SM} alt={"TBI Seal"} style={{marginRight: '16px'}}
                         onClick={() => {
                             window.open("https://www.tn.gov/tbi", "_blank");
                         }} />
                    <Grid container direction={'column'}>
                        {true && <Typography
                            variant="h1"
                            color="inherit"
                            noWrap={sm}
                            className={classes.title}
                            style={{fontWeight: '700', paddingBottom: 2, fontSize: sm ? undefined: 16}}
                        >
                            Tennessee Sex Offender Registry
                                {sm &&
                                <span style={{
                                    color: Config.LIGHT_TEXT,
                                    fontWeight: 'lighter'
                                }}>&nbsp;&nbsp;&nbsp;{props.title}</span>
                                }
                        </Typography>
                        }
                    </Grid>
                    {!Config.isProd && <Tooltip title={'DEV-ONLY. Reset Disclaimer to appear on next page change'}>
                    <IconButton color="inherit"
                                onClick={(event) => {
                                    props.sessionStore.setDisclaimed(true, new Date());
                                    props.sessionStore.save(setCookie);
                                }}>
                        <RestoreIcon style={{color: 'orange'}}/>
                    </IconButton>
                    </Tooltip>
                    }


                </Toolbar>
                {sm && <Grid style={{
                    display: 'flex', alignItems: 'center',
                    height: 36, backgroundColor: "white", width: '100%'
                }}>
                    <Button className={classes.menuButton} style={{color: 'gray'}}
                            onClick={() => {
                                if (props.history) {
                                    props.history.push('/home');
                                }
                            }}
                    >
                        <HomeIcon/>
                    </Button>
                    <Button variant={'text'} endIcon={<ArrowDropDownIcon/>}
                            className={classes.menuButton}
                            style={{textTransform: 'none'}}
                            onClick={(event) => {
                                setMenuEl(event.currentTarget);
                            }}>
                        <Typography>I'd Like To...</Typography>
                    </Button>
                    <Menu
                        id={'menu-menu'}
                        anchorEl={menuEl}
                        keepMounted
                        open={Boolean(menuEl)}
                        onClose={() => setMenuEl(null)}
                    >
                        {navItems.map(item => {
                            // console.log('navItem', item);
                            return (
                                <MenuItem key={item.path}
                                          style={{borderBottom: '0.25px solid #dddddd'}}
                                          onClick={() => {
                                              if (item.path.toLowerCase().startsWith('http')) {
                                                  if (item.target === "self") {
                                                      window.location = item.path;
                                                  } else {
                                                      window.open(item.path, item.target);
                                                  }
                                              } else {
                                                  props.history.push(item.path);
                                              }
                                              setMenuEl(null);
                                          }}
                                >
                                    {item.action}

                                </MenuItem>
                            )
                        })}
                    </Menu>
                    <Typography style={{flexGrow: 1}}></Typography>
                    <Button variant={'text'} className={classes.tnHome}
                            startIcon={<img src={Config.TN_MARK} alt={'TN.gov'}/>} aria-label={"Go To TN.GOV"}
                            style={{
                                color: 'gray', textTransform: 'none',
                                '&:hover': {
                                    borderTop: '5px solid red',
                                }
                            }}
                            onClick={(event) => {
                                window.open("https://www.tn.gov", "_blank");
                            }}
                    >
                        <Typography>Go To TN.gov</Typography>
                    </Button>

                </Grid>
                }
                {pubStore.loaded && !pubStore.alert.visible && pubStore.hasTBIAlert && (
                <Grid container alignContent={'center'} justify={'center'} style={{height:theme.toolbar.heightMessage, backgroundColor: Config.TN_RED, width: '100%'}}>
                    <Grid item >
                        <Output data={tbi_alert_data} style={theme.output}/>
                    </Grid>
                </Grid>
                )}

                {pubStore.alert.visible && (
                    <Alert severity={pubStore.alert.type} variant={'filled'} onClose={() => pubStore.alert.hide()}>
                        <AlertTitle>{pubStore.alert.title}</AlertTitle>
                        {pubStore.alert.message}
                    </Alert>
                ) }

                <DisclaimerDialog
                    open={!isDisclaimed}
                    handleClose={(event) => {
                        if (event) {
                            event.stopPropagation();
                        }
                        setIsDisclaimed(true);
                        if (props.disclaimUrl) {
                            window.location = props.disclaimUrl;
                        }
                    }}
                />


            </AppBar>
            <nav className={classes.drawer} aria-label={'Find and Explore'}>
                <Hidden smUp implementation={'css'}>
                    <NavigationDrawer
                        history={props.history}
                        open={open}
                        onClose={handleDrawerClose}
                    >

                    </NavigationDrawer>
                </Hidden>
            </nav>
        </>

    );
}

export default inject('sessionStore', 'pubStore')(observer(Header));
