import React from "react";
import OffenderImage from "./OffenderImage";

export default function OffenderImageWrapper({row: row, ...otherProps}) {
        // console.log('OffenderImageComponent row.soid: ', row.soid);
        // console.log('OffenderImageComponent otherprops: ', otherProps);
        return (
            <OffenderImage miniImage offender={row.offender}
                           onClick={
                               (event) => {
                                   if (event) {
                                       // CRITICAL TO STOP PROPAGATION SO YOU DO NOT GET IMAGE & ROW CLICK EVENTS DOUBLED
                                       event.stopPropagation();
                                   }
                                   // console.log('OffenderImage onClick');
                                   let offender = row.offender;
                                   otherProps.pubStore.setDetailsOffender(offender);
                                   if (otherProps.history) {
                                       otherProps.history.push(`/details/${offender.soid}`);
                                   }
                               }
                           }
            />
        );
    }

