import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';


import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import {inject, observer} from "mobx-react";
import Config from '../config/Config';
import Footer from './Footer';
import EnhancedTable from "../shared/components/EnhancedTable";

import EMAIL from '../images/email_image.png';
import Header from "./Header";
import Output from '../shared/editorjs-renderer';
import {Button, CircularProgress, Paper, TextField, useTheme} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import EmailDialog from "./EmailDialog";
import OffenderImageWrapper from '../shared/components/OffenderImageWrapper';
import EmailActionDialog from "./EmailActionDialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {appBarSpace} from "../shared/theme/Theme";

const Subscription = require('../stores/Subscription').Subscription;
const validZip = require('../shared/util/validation').validZip;
const validEmail = require('../shared/util/validation').validEmail;


// require does not work ?
// const TBI_PROD = require('../images/TBI_PROD_60.png');
// const TBI_TEST = require('../TBI_TEST_76.png');


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#445c7c',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
      marginLeft: drawerWidth,
    },
  //   // zIndex: theme.zIndex.drawer + 1,
  //   // transition: theme.transitions.create(['width', 'margin'], {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100%)`
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    // position: 'relative',
    // whiteSpace: 'nowrap',
    width: drawerWidth,

    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    // drawerPaperClose: {
  //   overflowX: 'hidden',
  //   // transition: theme.transitions.create('width', {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  //   width: theme.spacing(7),
  //   [theme.breakpoints.up('sm')]: {
  //     width: theme.spacing(9),
  //   },
  // },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingTop: theme.toolbar.height,
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  code: {
    textAlign: 'left',
    backgroundColor: 'white',
    width: '100%',
    whiteSpace: 'pre-wrap',
    wordBreak: "break-all",
  },

}));


// export default function Dashboard() {
const Email = ({sessionStore, pubStore, history}) => {
  const classes = useStyles();
  const theme = useTheme();

    const ZIP_ERROR_MESSAGE = "Zip code must be 5 digits";
    const EMAIL_ERROR_MESSAGE = "Please enter a valid email address";

  const [open, setOpen] = React.useState(false);
    const [errorZip, setErrorZip] = React.useState(validZip(pubStore.subStore.zipcode,true) ? '' : ZIP_ERROR_MESSAGE);
    const [errorEmail, setErrorEmail] = React.useState(validEmail(pubStore.subStore.email,true) ? '' : EMAIL_ERROR_MESSAGE);

  const scrollRef = React.useRef(null);
  const [expanded, setExpanded] = React.useState(sessionStore.expandedEmail);


  const RemoveButton = ({row: row, ...otherProps}) => {
    return (
        <IconButton variant={'contained'} color={'inherit'}
                    onClick={(event) => otherProps.onActionClick(event, row)}
        >
          <DeleteIcon />
        </IconButton>
    );
  }

    const ZipSearchLink = ({row: row, ...otherProps}) => {
        let history = otherProps.history;
        let pubStore = otherProps.pubStore;

        return (
            <Button variant={'contained'} color={'primary'} disabled={pubStore.api.isWorking}
                    endIcon={<SearchIcon />}

                    onClick={(event) => {
                        pubStore.search.clearSearch();
                        pubStore.search.addChip(row.key);
                        pubStore.search.search();
                        history.push('/search');

                    }}
            >
                {row.key}
            </Button>

        );
    }


    const soidHeads = [
    // { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'photo', numeric: false, disablePadding: true, label: 'Photo', component: OffenderImageWrapper  },
    { id: 'offname', numeric: false, disablePadding: true, sortable: true, label: 'Name' },
     { id: 'soid', numeric: false, disablePadding: true, sortable: true, label: 'Offender ID'},
     { id: 'status', numeric: false, disablePadding: true, label: 'Action', component: RemoveButton },
  ];
  const zipHeads = [
    // { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'key', numeric: false, disablePadding: true, sortable: true, label: 'Zip Code', component: ZipSearchLink },
    { id: 'status', numeric: false, disablePadding: false, label: 'Action', component: RemoveButton },
  ];

    const [openEmail, setOpenEmail] = React.useState(false);
    const [lookupError, setLookupError] = React.useState('');
    const [openAction, setOpenAction] = React.useState( false);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [openRemoveAll, setOpenRemoveAll] = React.useState( false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
    <div className={classes.root}  style={{marginTop: appBarSpace(sm, pubStore.hasTBIAlert)}}>
      <CssBaseline />
      <Header history={history} title={'Email Alerts'} />
      <main ref={scrollRef} className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>

          <Accordion key={"EMAIL"} expanded={expanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  sessionStore.setExpandedEmail(!expanded);
                  setExpanded(!expanded);
                }}
            >
              <Typography variant={'h3'} style={{ fontWeight:700}}>Information/Instruction Regarding Email Alerts
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction={'column'} spacing={0}>

                {pubStore &&
                <Box style={{backgroundColor: Config.CONTENT_BACKGROUND}}>
                  <Output data={pubStore.getContent('DISCLAIMER_EMAIL').data} style={theme.output}/>
                </Box>
                }
                {pubStore &&
                <Box style={{backgroundColor: Config.CONTENT_BACKGROUND}}>
                  <Output data={pubStore.getContent('INSTRUCT_EMAIL').data} style={theme.output}/>
                </Box>
                }

                  <Divider style={{
                      backgroundColor: Config.TN_RED,
                      width: '85%',
                      margin: "auto",
                      marginTop: 16,
                      marginBottom: 4,
                      height: '4px'
                  }} variant={"middle"}/>
              </Grid>
            </AccordionDetails>
          </Accordion>
            <Typography style={{marginBottom: 18, marginTop:10}}>
                Enter your email address to manage your subscriptions.
            </Typography>

            <TextField required id="email" label="Your email address"
                   variant={'outlined'}
                   error={errorEmail > ''}
                   helperText={errorEmail}
                     value={pubStore.subStore.email}
                     onChange={(event, value) => {
                         let em = event.target.value;
                         pubStore.subStore.setEmail(em);
                         setIsLoaded(false);
                         if (!validEmail(em)) {
                             setErrorEmail(EMAIL_ERROR_MESSAGE);
                         } else {
                             setErrorEmail('');
                         }



                     }}/>
          {pubStore.api.isWorking && <CircularProgress/>}
          <Button variant={'contained'} color={'primary'} style={{margin: 10}} disabled={pubStore.api.isWorking || errorEmail > '' || pubStore.subStore.email === ''}
                  onClick={(event) => {
                    pubStore.subStore.load()
                        .then(() => {
                            setIsLoaded(true);
                        })
                        .catch(error => {

                        })
                  }}>
            Load
          </Button>
          <Button variant={'contained'} color={'secondary'} disabled={pubStore.api.isWorking || !isLoaded}
                  onClick={(event) => {
                      setOpenRemoveAll(true);
                    // pubStore.subStore.removeAll()
                    //     .then(() => {
                    //       pubStore.subStore.load();
                    //     })
                    //     .catch(error => {
                    //     })
                  }}>
            Remove All
          </Button>
            {openRemoveAll && <EmailActionDialog
                open={openRemoveAll}
                handleClose={() => {
                    setOpenRemoveAll(false);
                }}
                pubStore={pubStore}
                item={undefined}
                icon={<DeleteIcon color={'inherit'} style={{color: 'red', marginRight: 8}} fontSize={'large'}/>}
                action={(event) => {
                    return new Promise((resolve, reject) => {
                        pubStore.subStore.removeAll()
                            .then(() => {
                                pubStore.subStore.load()
                            })
                            .then(() => {
                                resolve('ok');
                            })
                            .catch(error => {
                                reject(error);
                            })

                    })
                }}
                title={'Confirm the removal of all email alerts'}
                prompt={`Are you sure you want to remove all email notifications from ${pubStore.subStore.email}?`}
            />}
          <Grid container direction={'row'} >
            <Grid item container sm={12} md={6} container direction={'column'} style={{padding: 6}}>
              <Grid item>
                <Typography variant={'h3'} style={{marginTop: 18}}>
                  Offender Subscriptions
                </Typography>
                <Paper variant={'outlined'}>
                  <Typography>
                    Look up an offender by ID
                  </Typography>
                  <TextField required id="offender-text" label="Offender ID"
                             error={lookupError > ''}
                             helperText={lookupError}
                             variant={'outlined'}
                             value={pubStore.subStore.soid}
                             onChange={(event, value) => {
                               pubStore.subStore.setSoid(event.target.value);
                               setLookupError('');

                             }}/>
                  <Button variant={'contained'} color={'primary'} style={{margin: 10}} disabled={pubStore.api.isWorking || lookupError > '' || pubStore.subStore.soid === ''}
                          onClick={(event) => {
                              pubStore.search.clearSearch();
                              pubStore.search.addChips(pubStore.subStore.soid);
                              pubStore.search.search()
                                  .then(() => {
                                      if(pubStore.search.searchResults.length === 1) {
                                          console.log('Email add-specific-offender: ', pubStore.search.searchResults[0].offender);
                                          pubStore.setDetailsOffender(pubStore.search.searchResults[0].offender);
                                          setOpenEmail(true);
                                          setLookupError('');
                                      }
                                      else {
                                          setLookupError('Lookup did not match a unique offender');
                                      }
                                  })
                                  .catch(error => {

                                  })
                           }}
                  >
                    Lookup
                  </Button>
                    {openEmail && <EmailDialog offender={pubStore.detailsOffender} pubStore={pubStore}
                                               history={history}
                                               open={openEmail}
                                               handleClose={(event) => {
                                                   console.log('Email-handleClose');
                                                   if (event) {
                                                       console.log('handleClose - stopping propagation');
                                                       event.stopPropagation();
                                                   }
                                                   setOpenEmail(false);
                                                   pubStore.search.clearSearch();
                                                   setLookupError('');
                                                   pubStore.subStore.setSoid('');
                                                   // pubStore.subStore.setSoid('');
                                               }}
                    />
                    }
                    {openAction && <EmailActionDialog
                        open={openAction}
                        handleClose={() => {
                            setOpenAction(false);
                        }}
                        pubStore={pubStore}
                        icon={<DeleteIcon color={'inherit'} style={{color: 'red', marginRight: 8}} fontSize={'large'}/>}
                        item={selectedRow}
                        action={(event) => {
                            return new Promise((resolve, reject) => {
                                selectedRow.remove()
                                    .then(() => {
                                        pubStore.subStore.load()
                                    })
                                    .then(() => {
                                        resolve('ok');
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })

                            })
                        }}
                        title={'Confirm the removal of your email alert'}
                        prompt={'Are you sure you want to remove notifications on'
                        + (selectedRow.mode === 'soid' ?
                            ` offender ${selectedRow.offname} (Offender ID ${selectedRow.key})`
                            :
                            ` zip code ${selectedRow.key}`)
                        + ` from ${pubStore.subStore.email}?`}
                        success={'You have successfully removed your email notification for'
                        + (selectedRow.mode === 'soid' ?
                            ` offender ${selectedRow.offname} (Offender ID ${selectedRow.key}) ?`
                            :
                            ` zip code ${selectedRow.key} ?`)
                        + ' Thank you.'}

                    />}
                  <Button variant={'contained'} color={'primary'} disabled={pubStore.api.isWorking}
                          onClick={(event) => {
                            if(history) {
                              history.push('/search');
                            }
                          }}
                          >
                    Search
                  </Button>

                  {pubStore.api.isWorking && <CircularProgress/>}

                </Paper>
                <EnhancedTable pubStore={pubStore} history={history}
                               title={
                  (<Badge badgeContent={pubStore.subStore.soidsubs.length} color={'primary'}>
                    <Typography variant={'h3'} style={{marginRight: 12}}>
                      Offenders following
                    </Typography>
                  </Badge>)

                } head={soidHeads} data={pubStore.subStore.soiddata}
                               rowsPerPage={10}
                               rowCount={pubStore.subStore.soiddata.length}
                               enableLocalSort
                               onActionClick={(event, row) => {
                                   setSelectedRow(row);
                                   setOpenAction(true);
                               }}
                />
              </Grid>
            </Grid>
            <Grid item container sm={6} md={6} direction={'column'} style={{padding: 6}}>
              <Grid item>
                  <Typography variant={'h3'} style={{marginTop: 18}}>
                    Zip Code Subscriptions
                  </Typography>
                <Paper variant={'outlined'}>
                  <Typography>
                    Add a Zip Code
                  </Typography>
                  <TextField required id="zipcode" label="Zip Code"
                             variant={'outlined'}
                             error={errorZip > ''}
                             helperText={errorZip}
                             value={pubStore.subStore.zipcode}
                             onChange={(event, value) => {

                               pubStore.subStore.setZipcode(event.target.value);
                                 if (!validZip(event.target.value, true)) {
                                     setErrorZip(ZIP_ERROR_MESSAGE);
                                 } else {
                                     setErrorZip('');
                                 }


                             }}/>
                  <Button variant={'contained'} color={'primary'} style={{margin: 10}} disabled={pubStore.api.isWorking || pubStore.subStore.zipcode === '' || errorZip > '' || pubStore.subStore.zipsubs.length >= 5}
                          onClick={(event) => {
                            pubStore.subStore.sub.set({
                              id: 0,
                              email: pubStore.subStore.email,
                              mode: 'zip',
                              key: pubStore.subStore.zipcode,
                              status: 'ACTIVE'
                            });
                            pubStore.subStore.sub.save()
                                .then(() => {
                                  pubStore.subStore.setZipcode('');
                                  pubStore.subStore.setOffender({});
                                  pubStore.subStore.load();
                                })

                          }}
                  >
                    Add
                  </Button>

                  {pubStore.api.isWorking && <CircularProgress/>}

                </Paper>
                <Grid>
                  <EnhancedTable pubStore={pubStore} history={history} title={
                    (<Badge badgeContent={pubStore.subStore.zipsubs.length} color={'primary'}>
                      <Typography variant={'h3'} style={{marginRight: 12}}>
                        Zip Codes following
                      </Typography>
                    </Badge>)

                  } head={zipHeads} data={pubStore.subStore.zipdata}
                                 rowsPerPage={5}
                                 rowCount={pubStore.subStore.zipdata.length}
                                 enableLocalSort
                                 onActionClick={(event, row) => {
                                     setSelectedRow(row);
                                     setOpenAction(true);
                                 }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
            {Config.enableJsonDebug && <div style={{width: '100%'}}>
            <p>subscriptions for email: {pubStore.subStore.email}</p>
            <pre className={classes.code}>
                {JSON.stringify(pubStore.subStore.soidsubs, ' ', 2)}
                </pre>
          </div>
            }
          <Box pt={4}>
            <Footer pubStore={pubStore} history={history}/>
          </Box>

        </Container>
      </main>
    </div>
  )
}
export default inject('sessionStore', 'pubStore')(observer(Email));
