// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
// better reference:
// https://inthetechpit.com/2020/05/11/make-react-app-compatible-with-ie11/
// By default, the generated project supports all modern browsers. Support for Internet Explorer 9, 10, and 11 requires
// polyfills. For a set of polyfills to support older browsers, use react-app-polyfill.
// https://create-react-app.dev/docs/supported-browsers-features/

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/permian-slab-font-face.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto';
import '@fontsource/open-sans';

import {CookiesProvider} from "react-cookie";

ReactDOM.render(
  <React.StrictMode>
      <CookiesProvider>
        <App />
      </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
