import {types} from 'mobx-state-tree';

import {Api} from "../shared/stores/Api";
import Config from "../config/Config";
import axios from "axios";
import moment from "moment";
import {Alert} from "../shared/stores/Alert";
import {Content} from "./Content";
import {TipsStore} from "./TipsStore";
import {SearchStore} from "../shared/stores/SearchStore";

import qs from 'qs';
import {SubStore} from "./SubStore";
import {localizeOffender} from "../shared/components/offenderutil";

const delay = require('../shared/stores/Api').delay;

const debug =  Config.enableConsoleDebug;

export const PubStore = types
    .model('PubStore', {
        id: 0,
        api: types.optional(Api, {}),

        alert: types.optional(Alert, {}),

        top10Loaded: false,
        top10: types.frozen([]),

        contents: types.array(Content, []),
        contentLoaded: false,

        loaded: false,
        // NEW
        allContents: types.frozen([]),

        // top10Contents: types.array(Content, []),
        top10Contents: types.frozen( []),
        top10Offenders: types.frozen([]),  // not used

        // publicContents: types.array(Content, []),
        publicContentsDEPRECATED: types.frozen([]),

        search: types.optional(SearchStore, {}),

        synced: false,
        syncError: false,

        loadingOffender: false,
        detailsOffender: types.frozen({}),
        detailsOffenderError: false,

        tips: types.optional(TipsStore, {}),

        subStore: types.optional(SubStore, {}),

    })
    .views( self => ({
        get hasTBIAlert() {
            if(!self.loaded) {
                return false;
            }
            let tbi_alert_data = self.getContent('TBI_ALERT', 'ALERT').data;
            let hasValue = (Array.isArray(tbi_alert_data.blocks) && tbi_alert_data.blocks.length > 0 ? true : false);
            return hasValue;
        },
    }))
.actions(self => ({
    afterCreate() {
    },
    setId(value) {
        self.id = value;
    },
    isValidSoid(soid) {
        if(soid > '') {
            let match = soid.match(/^[so0-9]{2}[0-9]{6}$/i);
            if(Config.isDev) {
                match = soid.match(/^[so0-9]{2}[a-z0-9]{6}$/i);

            }
            return(match !== null);
        }
        return false;
    },
    findOffender(soid) {
        if(!(soid > '')) {
            return undefined;
        }
        let lcsoid = soid.toLowerCase();
        let re = new RegExp(lcsoid, 'i');
        if(self.detailsOffender.soid && self.detailsOffender.soid.match(re)) {
            debug && console.log('pubStore.findOffender soid: ' + soid + ' match detailsOffender');
            return self.detailsOffender;
        }
        let offender = self.top10.find(_o => _o.soid.toLowerCase() === lcsoid);
        if(offender) {
            return offender;
        }
        offender = self.search.searchResults.find(_o => _o.soid.toLowerCase() === lcsoid);
        if(offender) {
            debug && console.log('pubStore.findOffender soid: ' + soid + ' match searchResults');

            return offender;
        }
        offender = self.subStore.soidsubs.find(_o => _o.soid.toLowerCase() === lcsoid);

        if(offender) {
            return offender;
        }
        return undefined;
    },
    isDetailsOffenderValid() {
        if(self.detailsOffender) {
            if(self.detailsOffender.soid) {
                if(self.detailsOffender.secta) {
                    return true;
                }
            }
        }
        return false;
    },
    findOrLoadOffender(soid) {
        debug && console.log('findOrLoadOffender... start soid: ' + soid);
        self.setLoadingOffender(true);
        self.setDetailsError(false);
        if(!self.isValidSoid(soid)) {
            self.setDetailsError(true);
            self.setLoadingOffender(false);
            return Promise.reject('invalid soid');
        }
        let offender = self.findOffender(soid);
        if(offender) {
            self.setLoadingOffender(false);
            self.setDetailsOffender(offender);
            return Promise.resolve(true);
        }

        return new Promise((resolve, reject) => {

            delay(0)
                .then(() => {
                    debug && console.log('findOrLoadOffender end delay soid: ' + soid);
                })
                .then(() => {
                    return self.loadOffender(soid);
                })
                .then(() => {
                    self.setLoadingOffender(false);
                    resolve(true);
                })
                .catch(error => {
                    self.setDetailsError(true);
                    self.setLoadingOffender(false);
                    reject(error);
                })
        })
    },
    setSynced(value) {
        self.synced = value;
    },
    setSyncError(value) {
        self.syncError = value;
    },
    setDetailsError(value) {
        self.detailsError = value;
    },
    setDetailsOffender(value) {
        self.detailsOffender = value;
    },
    setContentLoaded(value) {
        self.contentLoaded = value;
    },
    setAllContents(value) {
        self.allContents = value;
    },
    setTop10Contents(contents, offenders) {
        if(!Array.isArray(offenders)) {
            offenders = [];
        }
        // let contents = toContents(top10);
        let safeContents = [];
        contents.map(c => {
            // c.name is the soid for offender
            debug && console.log('setTop10Contents working offender for content.name: ' + c.name);

            let offender = offenders.find(o => o.soid === c.name);
            if(offender) {
                localizeOffender(offender && offender.offender, self.allContents);
                c.data = offender;
                debug && console.log('setTop10Contents localizedOffender - set content.data = offender soid: ' + c.data.soid);
                safeContents.push(c);
            }
            else {
                console.log('setTop10Contents - invalid offender: ' + c.name);
            }
        })
        self.top10Contents = safeContents;
        self.top10Offenders = safeContents.map(c => c.data);
    },
    getContent(name, module = 'PUBLIC') {
        if(!self.loaded) {
            return {id: 0, name: name, data: {blocks: [{
                        "type": "paragraph",
                        "data": {
                            "text": `Loading ${name} ...`
                        }
                    }]}};
        }
        if(self.syncError) {
            return {id: 0, name: name, data: {blocks: [{
                        "type": "paragraph",
                        "data": {
                            "text": `⚠️ Error loading application content. Please refresh your browser to try again.`
                        }
                    }]}};
        }

        let contents = self.allContents[module];
        if(contents) {

            let content = contents.find(c => c.name === name);
            if (content) {
                // console.log('getContent getting: ' + name + ' FOUND');
                return content;
            }
        }
        // console.log('getContent getting: ' + name + ' USING DEFAULT');
        return {id: 0, name: name, data: {empty: true, blocks: []}};

     },

    setLoaded(value) {
        debug && console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$ setLoaded: ' + value);
        self.loaded = value;
    },
    loadContents() {
        debug && console.log('load [all] contents');
        self.setLoaded(false);
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        let promises = [];
        return new Promise((resolve, reject) => {
            delay(Config.API_DELAY)
                .then(() => {
                    let url = `${Config.PUBSOR_BASE}/api/contents/1`;
                    debug && console.log('contents url: ', url);
                    return axios.get(url, {headers});
                })
                .then(({data}) => {
                    debug && console.log('pubStore.loadContents. raw results: ', data.searchresults);
                    let searchresults = JSON.parse(data.searchresults);
                    debug && console.log('pubStore.loadContents. results (parseJSON): ', searchresults);
                    if(!searchresults) {
                        searchresults = {};
                    }
                    // must be done first because the localizeOffender logic counts
                    // on allContents
                    self.setAllContents(searchresults);

                    // self.setTop10Offenders(searchresults.offenders);

                    self.setTop10Contents(searchresults.TOP10, searchresults.offenders);
                    // self.setPublicContents(searchresults.PUBLIC);
                    // special case - data of top10 === offender

                    // searchresults.map(r => {
                    //     console.log('result.soid: ' + r.soid);
                    //     localizeOffender(r.offender);
                    // })
                    self.api.success('200', 'contents complete');
                    self.setLoaded(true);
                    resolve(data);
                })
                .catch(error => {
                    debug && console.log('pubStore.contents.error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
    setLoadingOffender(value) {
        self.loadingOffender = value;
    },
    loadOffender(soid) {
        self.setDetailsOffender({soid: soid});
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            let url = `${Config.PUBSOR_BASE}/api/offender/${soid}`;
            debug && console.log('load offender url: ', url);
            delay(Config.API_DELAY)
                .then(() => {
                    return axios
                        .get(url,
                            {
                                headers,
                            })
                })
                .then(({data}) => {
                    let _offender = JSON.parse(data.data);
                    debug && console.log('pubStore.loadOffender. success return data: ' + JSON.stringify(_offender, null, 4));
                    localizeOffender(_offender, self.allContents);
                    // console.log(data);
                    self.setDetailsOffender(_offender);
                    self.api.success('200', 'offender load complete');
                    resolve(_offender);
                })
                .catch(error => {
                    console.log('loadOffender.error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
    sync() {

        self.setSynced(false);
        self.setSyncError(false);
        // self.loadTop10();
        self.loadContents()
            .then(() => {
                self.setSynced(true);
            })
            .catch(error => {
                this.setSyncError(true);
                console.log('sync. error loadContents.', error);
            })
    },
    verify(recaptcha) {
        debug && console.log('verify recaptcha: ', recaptcha);
        self.api.start();
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Basic ${token}`,
            'Access-Control-Allow-Origin': '*',
        };
        return new Promise((resolve, reject) => {
            delay(10)
                .then(() => {
                    let url = `${Config.PUBSOR_BASE}/api/verify`;
                    let body = {
                        recaptcha: recaptcha,
                    }
                    return axios.post(url, body, {headers});
                })
                .then(({data}) => {
                    debug && console.log(`verify results: `, data);
                    self.api.success('200', 'contents complete');
                    resolve(data);
                })
                .catch(error => {
                    console.log('verify .error: ' + error);
                    self.api.error('401', error);
                    self.alert.show('error', error, 'Please try again.');
                    reject(error);
                });
        });
    },
}));

