import React from 'react';

import './App.css';

import { Provider } from 'mobx-react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import Config from './config/Config';
import {SessionStore} from "./shared/stores/SessionStore";
import {PubStore} from "./stores/PubStore";
import Home from './pages/Home';
import Info from "./pages/Info";
import OffenderDetails from "./shared/details/OffenderDetails";
import Search from './shared/search/Search';
import Email from './pages/Email';
import Disclaim from "./pages/Disclaim";
import theme from './shared/theme/Theme';
import { createBrowserHistory } from 'history';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';

const debug = Config.enableConsoleDebug;

const ProtectedRoute = ({ component: Component, ...otherProps }) => {
  let sessionStore = otherProps.sessionStore;
  let pubStore = otherProps.pubStore;

  return (
      <Route {...otherProps} render={(props) => {
            debug && console.log('ProtectedRoute.route param is soid? ' + pubStore.isValidSoid(props.match.params.soid) + ' match: ', props.match);
            if(props.match && props.match.path)
                if(props.match.path.match(/details/) ) {
                    debug && console.log('ProtectedRoute - details. FIND OR LOAD OFFENDER props.match.path: ' + props.match.path);
                    let soid = props.match.params.soid;
                    pubStore.findOrLoadOffender(soid)
                        .then(() => {
                            debug && console.log('ProtectedRoute. Done pubStore.findOrLoadOffender. soid: ' + soid);
                        })
                        .catch(error => {
                            console.error('ProtectedRoute. failed to findOrLoadOffender', error);
                        });
                } else if (props.match.path.match(/search/)) {
                    debug && console.log('ProtectedRoute path is search. searchStore.hasSearched: ' + pubStore.search.hasSearched);
                    if(!pubStore.search.hasSearched) {
                        pubStore.search.searchIfNotStale()
                            .then(() => {
                                debug && console.log('ProtectedRoute. Done pubStore.searchIfNotStale');
                            })
                            .catch(error => {
                                console.error('ProtectedRoute. failed to searchIfNotStale', error);
                            })
                    }

                }

          return (
          true || sessionStore.isDisclaimed()
              ? <Component {...props} />
              : <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }}
              />
            )
        }
      } />
    )
}

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.sessionStore = SessionStore.create({});
    this.pubStore = PubStore.create({});
  }

  componentDidMount() {
    if(!this.pubStore.synched) {
      this.pubStore.sync();
    }

  }

  render() {

    return (
        <ThemeProvider theme={theme}>
          <Provider sessionStore={this.sessionStore} pubStore={this.pubStore}>
            <Router>
              <div className="App">
                {/*<Dashboard/>*/}
              </div>
              <Route exact path={"/"} >
                <Redirect to={'/home'} />
              </Route>
                        <Route path={"/home"} component={Home} sessionStore={this.sessionStore}
                               pubStore={this.pubStore}/>
                        <ProtectedRoute path={"/info"} component={Info} sessionStore={this.sessionStore}
                                        pubStore={this.pubStore}/>
                <Route exact path={"/details"} >
                    <Redirect to={'/home'} />
                </Route>
                        <ProtectedRoute path={"/details/:soid"} component={OffenderDetails}
                                        sessionStore={this.sessionStore} pubStore={this.pubStore}/>
                        <ProtectedRoute path={"/search"} component={Search} sessionStore={this.sessionStore}
                                        pubStore={this.pubStore}/>
                        <ProtectedRoute path={"/email"} component={Email} sessionStore={this.sessionStore}
                                        pubStore={this.pubStore}/>
                        <ProtectedRoute path={"/disclaim"} component={Disclaim} sessionStore={this.sessionStore}
                                        pubStore={this.pubStore}/>

            </Router>
          </Provider>
        </ThemeProvider>
    );
  }
}
