import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {inject, observer} from "mobx-react";
import Config from '../config/Config';
import Footer from './Footer';
import Output from '../shared/editorjs-renderer';
import Header from "./Header";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button,  useTheme} from "@material-ui/core";
// https://www.uuidgenerator.net/dev-corner/javascript
import {v4 as uuidv4} from 'uuid';
import {Link} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {appBarSpace} from "../shared/theme/Theme";
// require does not work ?
// const TBI_PROD = require('../images/TBI_PROD_60.png');
// const TBI_TEST = require('../TBI_TEST_76.png');

const debug = Config.enableConsoleDebug;
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar:
    // paddingRight: 24, // keep right padding when drawer closed
    theme.mixins.toolbar,

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#445c7c',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
      marginLeft: drawerWidth,
    },
  //   // zIndex: theme.zIndex.drawer + 1,
  //   // transition: theme.transitions.create(['width', 'margin'], {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100%)`
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    // position: 'relative',
    // whiteSpace: 'nowrap',
    width: drawerWidth,

    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

    // drawerPaperClose: {
  //   overflowX: 'hidden',
  //   // transition: theme.transitions.create('width', {
  //   //   easing: theme.transitions.easing.sharp,
  //   //   duration: theme.transitions.duration.leavingScreen,
  //   // }),
  //   width: theme.spacing(7),
  //   [theme.breakpoints.up('sm')]: {
  //     width: theme.spacing(9),
  //   },
  // },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingTop: theme.toolbar.height,
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

// this will skip a double-header (header, header). it only recognizes the 'second' header.
// @todo - look at header level as a way to determine what goes together.
const splitFaqs = (blocks) => {
  let faqs = [];
  if(Array.isArray(blocks)) {
    let header = undefined;
    let _blocks = [];
    var i = 0;
    for(i = 0; i< blocks.length; i++) {
      // console.log(`block[${i}]: ` + blocks[i]);
      if(blocks[i].type === 'header') {
        if(_blocks.length > 0) {
          faqs.push({header, blocks: _blocks});
        }
        _blocks = [];
        header = blocks[i];
        header.uuid = uuidv4();
      }
      else {
        _blocks.push(blocks[i]);
      }
      if(i === blocks.length - 1) {
        // always 'eject' at the end.
        faqs.push({header, blocks: _blocks});
      }
    }
  }
  return faqs;
}



// export default function Dashboard() {
const Info = ({sessionStore, pubStore, history}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const scrollRef = React.useRef(null);
  const [expandFaq, setExpandFaq] = React.useState();
  const [expandTermFaq, setExpandTermFaq] = React.useState();
  const [expandSorLaw, setExpandSorLaw] = React.useState();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  let faqs = pubStore.loaded ? splitFaqs(pubStore.getContent('FAQS').data.blocks) : [{header: {data: {text: 'faqs'}}, blocks: []}];
  let term_faqs = pubStore.loaded ? splitFaqs(pubStore.getContent('TERMINATION_FAQS').data.blocks) : [{header: {data: {text: 'term_faqs'}}, blocks: []}];
  let sor_law = pubStore.loaded ? splitFaqs(pubStore.getContent('SOR_LAW').data.blocks) : [{header: {data: {text: 'sor_law'}}, blocks: []}];
  debug && console.log('loaded: ' + pubStore.loaded + ' faqs: ' + faqs.length + ' term_faqs: ' + term_faqs.length + ' sor_law: ' + sor_law.length);


  return (
    <div className={classes.root} style={{marginTop: appBarSpace(sm, pubStore.hasTBIAlert)}}>
      <CssBaseline />
      <Header history={history} title={'Information'} />

      <main ref={scrollRef} className={classes.content}>

        <Container maxWidth="lg" className={classes.container}>
          <Accordion key={"FAQs"}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography variant={'h2'} style={{marginTop:0}}> {/*was h6 chg h2 20210312BV*/}
                FAQs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid direction={'column'}>
              {
                faqs.map(c => {
                  return (<Accordion expanded={expandFaq} key={c.header.uuid}>
                    <AccordionSummary

                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={c.header.data.text}

                    >
                      <Typography variant={'h3'}>
                        {c.header.data.text}
                      </Typography>
                    </AccordionSummary>
                  <AccordionDetails>
                    <Box  id={c.header.data.text} style={{backgroundColor: Config.CONTENT_BACKGROUND}}>
                      <Output data={c} style={theme.output}/>
                    </Box>

                  </AccordionDetails>

                  </Accordion>
                  )
                })
              }

                <Divider style={{
                  backgroundColor: Config.TN_RED,
                  width: '85%',
                  margin: "auto",
                  marginTop: 16,
                  marginBottom: 4,
                  height: '4px'
                }} variant={"middle"}/>
              </Grid>

            </AccordionDetails>
          </Accordion>
          <Accordion key={'term_faqs'}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel1a-header"
            >
              <Typography variant={'h2'}>
                TERMINATION FAQs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid direction={'column'}>
                {
                  term_faqs.map(c => {
                    // console.log('faqs c: ', c);
                    return (<Accordion key={c.header.uuid}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={c.header.data.text}
                              // id={c.header.data.text}
                              // BV 8/2/21 moved this line to line 280
                          >
                            <Typography variant={'h3'}>
                              {c.header.data.text}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box  id={c.header.data.text} style={{backgroundColor: Config.CONTENT_BACKGROUND}}>
                              <Output data={c} style={theme.output}/>
                            </Box>

                          </AccordionDetails>

                        </Accordion>
                    )
                  })
                }
                <Divider style={{
                  backgroundColor: Config.TN_RED,
                  width: '85%',
                  margin: "auto",
                  marginTop: 16,
                  marginBottom: 4,
                  height: '4px'
                }} variant={"middle"}/>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion key={'sor_law'}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel3a-header"
            >
              <Typography variant={'h2'}>
                SOR LAW
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid direction={'column'} style={{marginLeft: 8}}>
                {
                  sor_law.map(c => {
                    // console.log('faqs c: ', c);
                    return (<Accordion key={c.header.uuid}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={c.header.data.text}
                              // id={c.header.data.text}
                              // BV 8/2/21 moved this line to line 328
                          >
                            <Typography variant={'h3'}> {/*BV changed from h5 to h2*/}
                              {c.header.data.text}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box  id={c.header.data.text} style={{backgroundColor: Config.CONTENT_BACKGROUND}}>
                              <Output data={c} style={theme.output}/>
                            </Box>

                          </AccordionDetails>

                        </Accordion>
                    )
                  })
                }
                <Divider style={{
                  backgroundColor: Config.TN_RED,
                  width: '85%',
                  margin: "auto",
                  marginTop: 16,
                  marginBottom: 4,
                  height: '4px'
                }} variant={"middle"}/>
              </Grid>
            </AccordionDetails>
          </Accordion>


          <Box pt={4}>
            <Footer pubStore={pubStore}/>
          </Box>

        </Container>
      </main>
    </div>
  )
}
export default inject('sessionStore', 'pubStore')(observer(Info));
