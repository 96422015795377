/** ListOutput
 *
 * @version 1.0.0
 * @created - 2019.08.20
 * @author - Adombang Munang Mbomndih (Bomdi) <dzedock@gmail.com> (https://bomdisoft.com)
 *
 * Version History
 * ---------------
 * @version 1.0.1 - 2020.02.12 - Covert to React component - Adombang Munang Mbomndih
 * @version 1.0.2 - 2020.07.17 - Add config parameter - Adombang Munang Mbomndih
 * @version 1.1.0 - 2021.04.11 - Add classNames parameter - Adombang Munang Mbomndih
 * @version 1.1.1 - 2021.04.12 - Add validation for config parameter - Adombang Munang Mbomndih
 */

/*
standard list (1-deep)
          {
            "type": "list",
            "data": {
              "style": "unordered",
              "items": [
                "Must have reached the age of 25; and",
                "Must not have been adjudicated delinquent or convicted of a subsequent qualifying offense as set out in T.C.A., § 40-39-202(20), T.C.A., § 40-39-202(28), or T.C.A., § 40-39-202(30) or its equivalent from another jurisdiction."
              ]
            }
          },

nested list
https://github.com/editor-js/nested-list
     {
            "id": "SMdEnI18ne",
            "type": "list",
            "data": {
              "style": "ordered",
              "items": [
                {
                  "content": "asdf",
                  "items": [
                    {
                      "content": "bob",
                      "items": [
                        {
                          "content": "level 3",
                          "items": []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          }

 */
//#region imports
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import listOutputStyle from './style';
//#endregion

const validListStyles = ['ordered', 'unordered'];
const supportedKeys = ['container', 'listItem'];

// https://www.bitovi.com/blog/recursive-react-components
// https://www.w3docs.com/snippets/html/how-to-display-an-ordered-list-with-nested-counters.html

// props.items.
const NestedList = (props) => {

    let items = props.items;
    // console.log('NestedList rendering items', items);
    let listItemStyle = props.listItemStyle;
    let listItemClassName = props.listItemClassName;

    if(!items || !Array.isArray(items) || items.length === 0) {
        return <></>;
    }
    let listType = props.type || 'unordered';
    return (
        <>
            {listType === 'unordered' ?
                (
                    <ul style={props.containerStyle} className={props.containerClassName}>
                        {items.map((item,index) => {
                            return (
                                 <li key={index} style={listItemStyle} className={listItemClassName}> {item.content || ''}
                                    <NestedList items={item.items} type={props.type} listItemStyle={listItemStyle}
                                        listItemClassName={listItemClassName}
                                        containerStyle={props.containerStyle}
                                        containerClassName={props.containerClassName}/>
                                </li>
                        );
                        })
                        }

                </ul>
                )
                :
                (
                    <ol>
                        {items.map((item,index) => {
                            return (
                                <li key={index}>{item.content || ''}
                                    <NestedList items={item.items} type={props.type}/>
                                </li>
                        );
                        })
                        }

                </ol>
                )
            }
             }

                </>
    )
}

const ListOutput = ({data, style, classNames, config}) => {
    if (!data) return '';
    if (!style || typeof style !== 'object') style = {};
    if (!config || typeof config !== 'object') config = {};
    if (!classNames || typeof classNames !== 'object') classNames = {};

    supportedKeys.forEach(key => {
        if (!style[key] || typeof style[key] !== 'object') style[key] = {};
        if (!classNames[key] || typeof classNames[key] !== 'string') classNames[key] = '';
    });

    const containerStyle = config.disableDefaultStyle ? style.container : {...listOutputStyle.container, ...style.container};
    const listItemStyle = config.disableDefaultStyle ? style.listItem : {...listOutputStyle.listItem, ...style.listItem};
    let content = [], listType = 'unordered';

    if (typeof data === 'string') content.push(data);
    else if (typeof data === 'object') {
        // console.log('ListOutput items: ', JSON.stringify(data.items, ' ', 2));
        let isNested = data.items && Array.isArray(data.items) && data.items.length > 0 && (data.items[0].content || data.items[0].items);
        // console.log('ListOutput isNested: ' + isNested);
        if (isNested) {
            return (<div style={{}} >
                <NestedList items={data.items} type={data.style} listItemStyle={listItemStyle} listItemClassName={classNames.listItem}
                containerStyle={containerStyle} containerClassName={classNames.container} />
            </div>
            );
            }
        else if (data.items && Array.isArray(data.items)) {
            // single-list
            content = data.items.map((item, index) => {
                return (
                    <li key={index} style={listItemStyle} className={classNames.listItem}>{ReactHtmlParser(item)}</li>
                );
            })
        }
        if (data.style && validListStyles.includes(data.style)) {
            listType = data.style;
        }
    }

    if (content.length <= 0) return '';
    if (listType === 'ordered') return <ol style={containerStyle} className={classNames.container}>{content}</ol>;

    return <ul style={containerStyle} className={classNames.container}>{content}</ul>;
};

export default ListOutput;
