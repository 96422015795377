import React, {useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {get} from '../util/json-util';
import ActionButtons from "../../pages/ActionButtons";
import Container from "@material-ui/core/Container";
import {
    AddressComponent,
    AliasComponent,
    identityComponent,
    OffenseComponent,
    Employer,
    SchoolComponent,
    ParoleComponent,
    VehicleComponent,
    Demographics,
    smtComponent,
    CriminalHistory,
    ReportDates,
    terminationComponent,
    ClassificationComponent,
    RegisteringAgencyComponent,
    OffensesComponent,
} from "./OffenderComponents";
import SILHOUETTE from "../../images/shilouette_wBG.png";
import Config from "../../config/Config";
import OffenderImage from "../components/OffenderImage";
import {CircularProgress, Popper, Tooltip} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Output from '../editorjs-renderer';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {inject, observer} from "mobx-react";
import IconButton from "@material-ui/core/IconButton";
import GeoPinIcon from "@material-ui/icons/LocationOn";

const debug = Config.enableConsoleDebug;

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 400,
        marginBottom: '12px',
    },
    media: {
        height: 160,
        // paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    code: {
        textAlign: 'left',
        backgroundColor: 'white',
        width: '100%',
        whiteSpace: 'pre-wrap',
        wordBreak: "break-all",
    },
    printOnly: {
        '@media print' : {
            // display: 'block',
            width: '100%'
        },
        '@media only screen': {
            display: 'none',
        }
    },
    printNever: {
        '@media print' : {
            // display: 'block',
            display: 'none',
        },
    },
    printStyle:{
        '@media print' : {
            // margin: "10%",
            // "@page": {
            //     "@bottom-right": {
            //         "content" : "SOR"
            //     }
            //
            // }
            // ".page-break": {
            //     marginTop: 100,
            //     display: 'block',
            //     pageBreakBefore: 'auto',
            // },
            // "@page": {
            //     "@top-left": {
            //         content: "SOR OFFENDER"
            //     }
            // }
        },
        },

 }));


// props sent in -> searchStore, userStore
function OffenderListItem(props) {
    const classes = useStyles();
    const theme = useTheme();
    const componentRef = useRef();
    const [showActionButtons, setShowActionButtons] = React.useState(true);
    let offender = props.offender;

    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    let cols = 2.5;
    cols = sm ? 3.5 : cols;
    cols = md ? 5.5 : cols;
    debug && console.log('props', JSON.stringify(props));

    let sorImage = SILHOUETTE;
    if(props.offender.sorimage && props.offender.sorimage.url) {
        if(!props.offender.sorimage.url.startsWith('http')) {
            sorImage = `${Config.PUBSOR_BASE}${props.offender.sorimage.url}`;
        } else {
            sorImage = props.offender.sorimage.url;
        }
    }

    let isOffenderAgainstChildren = false;
    if(props.offender.secth) {
        if(props.offender.secth.classification > '') {
            if(props.offender.secth.classification.match(/AGAINST CHILDREN/i)) {
                isOffenderAgainstChildren = true;
            }
        }
    }

    if(!props.offender.secta) {
        return (
            <Paper className={classes.root}>
                <Typography variant={'body1'}>
                    Loading...
                </Typography>
            </Paper>
        )
    }
    return (
        <>
            <div className={classes.printStyle}
                ref={componentRef}>
                <Grid container direction={'column'} spacing={1} className={classes.printOnly} style={{position: 'absolute', top: 0}}
                      alignContent={'center'}
                      alignItems={'center'} justify={'center'}>
                    <Grid item>
                        <img src={Config.LOGO} />
                    </Grid>
                    <Grid item>
                    <Typography variant={'h3'} style={{marginBottom: 24}}>
                        Tennessee Sex Offender Registry
                    </Typography>
                    </Grid>
                </Grid>
            <Grid container spacing={2} >
                <Grid item>
                    <OffenderImage detailImage offender={props.offender}/>
                </Grid>
                <Grid item xs>
                    <Typography variant="h2" color="textPrimary" >
                        {props.offender.secta.offname} {props.offender.secta.suffix > '' ? props.offender.secta.suffix : ''}
                    </Typography>
                    {props.offender.isoac && <Typography style={{color: '#ff0000', fontWeight: 'bold'}}>
                         OFFENDER AGAINST CHILDREN
                    </Typography>}
                    <Typography variant="h3" color="textPrimary" >
                        OFFENDER ID: {props.offender.soid}
                    </Typography>
                     {props.offender.istop10 && (
                        <>
                        <Typography variant={'subtitle2'} style={{color: '#ff0000'}}>
                            ABSCONDER
                        </Typography>
                        <Typography variant={'subtitle2'} style={{color: '#ff0000'}}>
                            LAST KNOWN WHEREABOUTS: {props.offender.top10note}
                        </Typography>
                        </>
                    )}
                    {Array.isArray(offender.secta.aliasnames) && offender.secta.aliasnames.length > 0 &&
                    (
                        <>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            ALIASES
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                             {offender.secta.aliasnames.join('; ')}
                        </Typography>
                        </>
                        )}

                </Grid>
                <Grid item >
                    {props.enableActionButtons &&
                        <ActionButtons  offender={props.offender} pubStore={props.pubStore}
                                        history={props.history} userStore={props.userStore}
                                        printRef={componentRef}
                                        showActionButtons={showActionButtons}
                                        onChange={(show) => {
                                            setShowActionButtons(show);
                                        }
                                        }
                        />
                    }

                </Grid>
            </Grid>
            <ClassificationComponent offender={props.offender} pubStore={props.pubStore} theme={theme} />

            <OffensesComponent offender={props.offender} pubStore={props.pubStore} theme={theme} />

            {props.offender.sectb &&
                <>
                    <Grid container direction={'row'} alignItems={'center'}>
                        <Typography variant={'h3'}>
                            ADDRESSES
                        </Typography>
                        {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                        <Tooltip title={'View offender address(es) on a map'}>
                            <IconButton aria-label="offender details"
                                        className={classes.printNever}
                                        style={{padding: 0}}
                                        color={'primary'} onClick={(event) => {
                                // example: https://test.tnmap.tn.gov/sor/beta/#/offender/00152818
                                let url = `${Config.TNMAP_BASE}/offender/${props.offender.soid}`;
                                window.location = url;

                            }}>
                                <GeoPinIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                        {get('sectb.primary', props.offender) &&
                    AddressComponent('primary', props.offender.sectb.primary)
                    }
                    {get('sectb.secondary', props.offender) && props.offender.sectb.secondary.street > '' &&
                    AddressComponent('secondary', props.offender.sectb.secondary)
                    }
                </>
            }
            {get('secte.employers', props.offender) && props.offender.secte.employers.length > 0 &&
                    props.offender.secte.employers.map((employer) => {
                        return Employer(employer);
                    }
            )}

            {get('sectc.vehicles', props.offender) && props.offender.sectc.vehicles.length > 0 && (
                <>
                <Typography variant={'h3'}>
                    VEHICLES
                </Typography>
                    {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                {props.offender.sectc.vehicles.map((vehicle) => {
                    return VehicleComponent(vehicle);
                })}
                    </>
            )
            }

            {get('sectd.schools', props.offender) && (
                <>
                    <Typography variant={'h3'}>
                        SCHOOLS
                    </Typography>
                    {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
                    {props.offender.sectd.schools.map((school) => {
                        return SchoolComponent(school);
                    })}
                </>
            )}


            {CriminalHistory(props.offender)}
            {Demographics(props.offender)}
            {smtComponent(props.offender)}


            <Typography variant={'h3'}>
                OTHER
            </Typography>
                {/*BV UPPER CASE PER JOSH DEVINE 7/29/21*/}
            {ReportDates(props.offender)}
            {RegisteringAgencyComponent(props.offender)}
            {ParoleComponent(props.offender.sectg)}

            <Paper variant={'outlined'} style={{margin: 8}}>
                <Typography variant={'caption'} >
                    *Note: In the field entitled Last Registration/Report Date, the term "Not Available" indicates
                        The Tennessee Bureau of Investigation has not received offender information as directed by
                        T.C.A. 40-39-201 et. seq.
                    The Website is updated daily based on information submitted to the TBI.
                </Typography>
            </Paper>
                {/*<Typography>*/}
                {/*    {'loaded: ' + props.pubStore.loaded}*/}
                {/*</Typography>*/}
                {props.pubStore.loaded && <Box className={classes.printOnly} style={{width: '100%', marginTop: 24}}>
                    <Output data={props.pubStore.getContent('DISCLAIMER_MAIN').data}
                            style={{paragraph: {fontSize: "0.6rem"}, header: {fontWeight: '400', fontSize: "1.0rem"}}}/>
                </Box>
                }

            </div>

            {Config.enableJsonDebug && <div className={classes.printNever} style={{width: '100%'}}>
                <p>Debug</p>
                <pre className={classes.code}>
                {JSON.stringify(props.offender, ' ', 2)}
                </pre>
            </div>
            }
    </>

);

}
export default inject('pubStore')(observer(OffenderListItem));
